import styled from "styled-components";

export const RobotStyled = styled.img`
  position: absolute;
  left: 8%;
  bottom: 0px;
  z-index: 1000;
  height: 38%;
  display: none;
  //todo odkomentovat ked pride tour
`

export const RobotStyled2 = styled.img`
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1000;
  height: 35%;
  cursor: ${props => props.tutorial ? 'default' : 'pointer'};
  font-family: 'Anonymous Pro', monospace;

  @media (max-width: 768px) {
    height: 25%;
  }
`

export const Bubble = styled.img`
  position: absolute;
  left: 6%;
  bottom: 37%;
  z-index: 3000;
  height: 23%;
  cursor: ${props => props.tutorial ? 'default' : 'pointer'};
  display: none;
  //todo odkomentovat ked pride tour
`

export const Bubble2 = styled.img`
  position: absolute;
  left: 0;
  bottom: 35%;
  z-index: 1000;
  height: 140px;
  cursor: ${props => props.tutorial ? 'default' : 'pointer'};

  @media (max-width: 768px) {
    bottom: 25%;
    height: 120px;
  }
`

export const Message = styled.span`
  position: absolute;
  left: 9%;
  bottom: 25%;
  z-index: 3000;
  height: 30%;
  color: white;
  font-size: 22px;
  cursor: ${props => props.tutorial ? 'default' : 'pointer'};
  font-family: 'Anonymous Pro', monospace;
  display: none;
  //todo odkomentovat ked pride tour
`
export const Message1 = styled.span`
  position: absolute;
  left: 9%;
  bottom: 21%;
  z-index: 3000;
  height: 30%;
  color: white;
  font-size: 16px;
  cursor: ${props => props.tutorial ? 'default' : 'pointer'};
  font-family: 'Anonymous Pro', monospace;
  display: none;
  //todo odkomentovat ked pride tour
`
export const Message2 = styled.span`
  position: absolute;
  left: 9%;
  bottom: 19%;
  z-index: 3000;
  height: 30%;
  color: white;
  font-size: 16px;
  cursor: ${props => props.tutorial ? 'default' : 'pointer'};
  font-family: 'Anonymous Pro', monospace;
`

export const Message3 = styled.span`
  position: absolute;
  left: 0px;
  width: 181px;
  text-align: center;
  bottom: 43%;
  z-index: 1000;
  color: white;
  font-size: 20px;
  cursor: ${props => props.tutorial ? 'default' : 'pointer'};
  font-family: 'Anonymous Pro', monospace;

  @media (max-width: 768px) {
    width: 150px;
    bottom: 32%;
    font-size: 16px;
  }
`

export const Message4 = styled.span`
  position: absolute;
  left: 0px;
  width: 181px;
  text-align: center;
  bottom: 41%;
  z-index: 1000;
  color: white;
  font-size: 20px;
  font-family: 'Anonymous Pro', monospace;
  cursor: ${props => props.tutorial ? 'default' : 'pointer'};

  @media (max-width: 768px) {
    bottom: 30%;
    font-size: 16px;
  }
`

export const Message5 = styled.span`
  position: absolute;
  left: 10px;
  bottom: 6%;
  z-index: 1000;
  color: white;
  font-size: 14px;
  font-family: 'Anonymous Pro', monospace;
  cursor: ${props => props.tutorial ? 'default' : 'pointer'};
  transform: rotate(-12deg);
  width: 100px;
  line-height: 1;
  text-align: center;

  @media (max-width: 768px) {
    bottom: 25px;
    left: -10px;
    font-size: 12px;
  }
`