import {IntroBackground, IntroDiv, RobotImage, RobotImage2} from "../Intro/Intro.styles";
import darkScreen from "../../images/bg-win_blank.png";
import robot1 from "../../images/robots/robot-1.svg";
import robot2 from "../../images/robots/robot-2.svg";
import MainButton from "../UI/MainButton";
import React from "react";
import {useTranslation} from "react-i18next";


const IdleScreen = ({handleClose}) => {

    const {t} = useTranslation('common');

    return (
        <IntroDiv>
            <IntroBackground src={darkScreen} alt="dark-screen"/>
            <RobotImage src={robot1}></RobotImage>
            <RobotImage2 src={robot2}></RobotImage2>
            <MainButton label={t('intro-continue').toUpperCase()} size={2} top='80%'
                        handleClick={handleClose}/>
        </IntroDiv>
    )
}

export default IdleScreen;
