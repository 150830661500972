import React from 'react';
import {NavBar, NavItems, LangBtn, Logo, IntroBtn} from './Header.styles';
import img_logo from '../../images/top/logo-interreg.svg';
import {useTranslation} from 'react-i18next';
import {langs} from '../../constants';
import MainButton from '../UI/MainButton';

const Header = ({
					showAdvancedSearch,
					showCountrySearch,
					showEpochSearch,
					showLanguageMenu,
					showIntro,
					curr_lang,
					currentTourStep,
					showMainPopup,
					intro
				}) => {

	const {t} = useTranslation('common');

	const openLangMenu = () => {
		showLanguageMenu()
	}

	const tutorial = () => {
		return currentTourStep !== 0;
	}

	return (
		<>
			<NavBar tutorial={tutorial() ? 1 : 0}></NavBar>
			<NavItems className={currentTourStep === 1 ? 'highlight-element' : null} tutorial={tutorial()}>
				<div className={'btn-nav-container'}>
					<MainButton label={t('main-filter-period').toUpperCase()} size={2} textsize={7}
								handleClick={tutorial() ? undefined : showEpochSearch}/>
					<MainButton label={t('main-filter-country').toUpperCase()} size={2} textsize={7}
								handleClick={tutorial() ? undefined : showCountrySearch}/>
					<MainButton label={t('main-filter-search').toUpperCase()} size={2} textsize={7}
								handleClick={tutorial() ? undefined : showAdvancedSearch}/>
				</div>
			</NavItems>
			<LangBtn className={currentTourStep === 2 || intro ? 'highlight-element' : null} tutorial={tutorial() ? 1 : 0}> 
				<img className={currentTourStep === 2 || intro ? 'highlight-element' : null}
					 src={langs[curr_lang].img} alt='language' onClick={tutorial() ? undefined : () => openLangMenu()}/>
			</LangBtn>
			<IntroBtn onClick={() => { window.location.reload() }}>
				<img src='../images/start-again.svg' alt=""/>
				<span>{t('main-intro-again')}</span>
			</IntroBtn>
			<Logo>
				<img src={img_logo} alt='logo' onClick={showMainPopup}></img>
			</Logo>
		</>
	)
}

export default Header;