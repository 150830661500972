import styled from "styled-components";

export const QuizResultDiv = styled.div`

  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/Roboto/Roboto-Black.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Kaushan Script';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/Kaushan_Script/KaushanScript-Regular.woff') format('woff');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }

  height: 100%;
  width: 100%;
  z-index: 2500;
  position: absolute;
  top: 0;
  left: 0;


  a {
    color: white;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100px;
    text-decoration: none;
  }

  span {
    position: absolute;
  }

  .Typewriter__wrapper {
    color: white;
    font-size: 18px;
    line-height: 20px;
  }

  button {
    left: calc(50% - 100px);
    top: 50%;
    z-index: 2501;
  }
`

export const QuizResultBackground = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2500;
`

export const QuizMedal = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2501;

  .quiz-inner {
    margin: 100px auto 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 800px;

    @media (max-width: 768px) {
      width: 100%;
    }
  }


  .header {
    font-family: 'Kaushan Script', monospace;
    font-size: 5em;
    text-align: center;
    color: white;
  }

  .body {
    width: 450px;
    height: 450px;
    font-family: 'Roboto', monospace;
    font-size: 3em;
    text-align: center;
    text-transform: uppercase;
    font-weight: 900;
    background-color: rgba(0, 0, 0, .5);
    color: rgba(0, 0, 0, .6);
    text-shadow: 2px 2px 3px rgba(255, 255, 255, 0.3);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    position: relative;

    img {
      position: absolute;
      width: 450px;
      height: 450px;
      top: 0;
      left: 0;
    }

    &__top {
      z-index: 1;
      position: relative;
      margin-top: 160px;
    }

    &__bottom {
      font-size: 2.4rem;
      z-index: 1;
      position: relative;
    }
  }

  .score {
    font-family: 'Kaushan Script', monospace;
    font-size: 2em;
    text-align: center;
    color: white;
  }
`

export const RobotImage = styled.img`
  position: absolute;
  left: 85%;
  top: 62%;
  height: 38%;
  z-index: 2501;
`

export const Message = styled.div`
  position: absolute;
  left: 68%;
  top: 50%;
  z-index: 2501;
  height: 200px;
  width: 300px;
  font-family: 'Anonymous Pro', monospace;

  img {
    z-index: 2501;
  }

  .Typewriter {
    position: absolute;
    z-index: 2501;
    width: 250px;
    top: 70px;
    left: 40px;
  }
`

export const ButtonDiv = styled.div`
  position: fixed;
  z-index: 2502;
  left: 33%;
  top: 84%;
  height: 7em;
  width: 33%;
  display: flex;
  justify-content: center;

  .btn-container {
    position: fixed;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-top: 0;
    z-index: 2501;

    .btn {
      margin-right: 2em;
      transform: translateY(20px);
      cursor: pointer;
      text-transform: uppercase;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
`
