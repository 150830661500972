import React from "react";
import {MenuTitleWrapper} from "./MenuTitle.styles";

const MenuTitle = ({title}) => {
	return (
		<MenuTitleWrapper>
			<h4>{title}</h4>
			<hr/>
		</MenuTitleWrapper>
	)
}

export default MenuTitle;