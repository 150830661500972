import React, {useState} from "react";
import {useTranslation} from 'react-i18next';
import {questions} from "../../constants";
import Popup from "../UI/Popup";
import cloneDeep from 'lodash/cloneDeep';
import _ from "lodash";
import 'plyr-react/dist/plyr.css'
import QuizResult from "../QuizResult";

const getRandomNumber = (array) => {
    return Math.floor(Math.random() * array.length);
}

const QuizPopup = ({handleClose, show, title, model, minimize, openCallback, openGallery, showQuizResult, hideQuizResult}) => {
    const [availableQuestions, setAvailableQuestions] = useState(cloneDeep(questions));
    const [question, setQuestion] = useState(availableQuestions[getRandomNumber(availableQuestions)]);
    const [order, setOrder] = useState(1);
    const [showResult, setShowResult] = useState(false)
    const [resultMessage, setResultMessage] = useState(false)
    const [questionResults, setQuestionResults] = useState([]);
    const [correctAnswerCount, setCorrectAnswerCount] = useState(0);
    const [selectedId, setSelectedId] = useState(0);
    const {t} = useTranslation('common');

    const opts = {
        height: '200',
        width: '360',
        playerVars: {
            autoplay: 0
        }
    }

    const minimizeAndClose = () => {
        minimize(handleClose, openCallback, t("quiz-name"));
    }

    const selectAnswer = () => {
        let foundAnswer = question.answers.find(question => question.id.toString() === selectedId);

        if (!resultMessage && order <= 6) {
            setResultMessage(true);
        } else if (order === 6) {
            questionResults.push({question: question, answer: foundAnswer});
            let correctAnswers = questionResults.filter(question => question.answer && question.answer.correct);
            setCorrectAnswerCount(correctAnswers.length);
            setShowResult(true);
            setResultMessage(false);
        } else {
            setOrder(order + 1);
            questionResults.push({question: question, answer: foundAnswer});
            localStorage.setItem('results', JSON.stringify(questionResults));
            _.remove(availableQuestions, question);
            setQuestion(availableQuestions[getRandomNumber(availableQuestions)]);
            setSelectedId(0);
            setResultMessage(false);
        }
    }

    const resetQuiz = () => {
        setOrder(1);
        setShowResult(false);
        setResultMessage(false);
        setCorrectAnswerCount(0);
        setAvailableQuestions(cloneDeep(questions));
        setQuestion(availableQuestions[getRandomNumber(availableQuestions)]);
        setQuestionResults([]);
        setSelectedId(0);
    }

    const handleChange = (e) => {
        setSelectedId(e.target.value);
    }

    return (
        <>
            {showResult ?
                <QuizResult score={correctAnswerCount} setShowResult={setShowResult}
                            resetQuiz={resetQuiz} closePopup={handleClose}/>
                :
                <Popup title={<span>{t('quiz-name')} {order}/6</span>}
                       show={show} handleClose={handleClose} handleMinimize={minimizeAndClose}
                       label={t('quiz-next').toUpperCase()}
                       handleClick={selectAnswer}
                       disabled={!selectedId}
                       body={resultMessage ?
                           <ResultMessage question={question} selectedId={selectedId}/>
                           :
                           <Question question={question} openGallery={openGallery} selectedId={selectedId}
                                     opts={opts} handleChange={handleChange}/>
                       }
                />
            }
        </>
    )
}

const Question = ({question, openGallery, selectedId, opts, handleChange}) => {

    const {t} = useTranslation('common');
    let gallery_class = 'x3';
    if (question.images && question.images.length < 5 && question.images.length !== 3) {
        gallery_class = 'x2';
    }

    return (
        <div>
            {question.images.length !== 0 && (
                <div className="grid_gallery">
                    <div className={`grid_gallery-s ${gallery_class}`}>
                        {question.images.slice(0, 6).map((image, i) => {
                            return <div className={`grid_gallery-item ${image.url ? "video" : "pointer"}`} key={i}
                                        onClick={() => openGallery(question.images, i)}>
                                <img className='grid-item' src={image.thumb} alt='grid-item'/>
                            </div>
                        })}
                    </div>
                </div>
            )}
            <div className="content">
                <div className='question_text'>{t(question.text)}</div>
                <div className='answer-grid'>
                    {question.answers.map((answer, i) => {
                        return <div className={'answer-grid__item'} key={i}><label
                            className={'container'}>{t(answer.text)}<input
                            type="radio" value={answer.id} onChange={handleChange}
                            checked={selectedId === answer.id.toString()}
                            name='answer'/><span className="checkmark"></span></label></div>
                    })}
                </div>
            </div>
        </div>
    )
}

const ResultMessage = ({question, selectedId}) => {
    const {t} = useTranslation('common');
    let foundAnswer = question.answers.find(question => question.id.toString() === selectedId);
    return (
        <div>
            {foundAnswer && foundAnswer.correct ?
                <h2 className={'correct-heading'}><img src="../images/correct-icon.svg" alt=""/>{t('quiz-correct')}</h2>
                :
                <h2 className={'wrong-heading'}><img src="../images/wrong-icon.svg" alt=""/>{t('quiz-wrong')}</h2>
            }
            <div className='funfact_text'>{t(question.resultMessage)}</div>
        </div>
    )
}

export default QuizPopup;
