import styled from "styled-components";

export const MenuTitleWrapper = styled.div`
	
  h4 {
		color: white;
		position: relative;
		top: 0;
		text-align: center;
		z-index: 3001;
	}

	hr {
		color: #aa9963;
		position: relative;
		z-index: 3001;
		top: 10px;
		width: 300px;
		left: 50%;
		transform: translate(-50%, 0);
		height: 3px;
		opacity: 1;
	}
`