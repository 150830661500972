import {
	Bubble,
	Bubble2,
	Message,
	Message1,
	Message2,
	Message3,
	Message4,
	Message5,
	RobotStyled,
	RobotStyled2
} from "./Robot.styles";
import robot5 from '../../images/robots/robot-5.svg';
import robot8 from '../../images/robots/robot-8.svg';
import bubble3 from '../../images/bubbles/bub-3.svg';

import bubble5 from '../../images/bubbles/bub-5.svg';
import {useTranslation} from "react-i18next";

const Robot = ({show, showQuizPopup, startTour, currentTourStep}) => {

	const {t} = useTranslation('common');

	const onClick = () => {
		showQuizPopup();
	}

	const highlight = () => {
		return currentTourStep === 3 ? 'highlight-element' : null;
	}

	const tutorial = () => {
		return currentTourStep !== 0;
	}

	if(show) {
		return (
			<>
				<Bubble src={bubble3} alt='bubble' onClick={startTour}/>
				<Message onClick={startTour}>{t('robot-tour1')}</Message>
				<Message1 onClick={startTour}>{t('robot-tour2')}</Message1>
				<Message2 onClick={startTour}>{t('robot-tour3')}</Message2>
				<RobotStyled src={robot5} alt='robot'/>
			</>

		)
	} else {
		return (
			<>
				<Bubble2 className={highlight()} src={bubble5} alt='bubble' onClick={tutorial() ? undefined : onClick} tutorial={tutorial()}/>
				<Message3 className={highlight()} onClick={tutorial() ? undefined : onClick} tutorial={tutorial()}>{t('quiz-help-take')}</Message3>
				<Message4 className={highlight()} onClick={tutorial() ? undefined : onClick} tutorial={tutorial()}>{t('quiz-help-quiz')}</Message4>
				<RobotStyled2 className={highlight()} onClick={tutorial() ? undefined : startTour} src={robot8} alt='robot' tutorial={tutorial()}/>
				<Message5 className={highlight()} onClick={tutorial() ? undefined : startTour} tutorial={tutorial()}>{t('robot-need-help')}</Message5>
			</>
		)
	}
}

export default Robot;