import React from "react";
import {useTranslation} from 'react-i18next';
import Popup from "../UI/Popup";


const ModelPopup = ({handleClose, show, model, minimize, openCallback, openGallery, show3dModel, setModel3dUrl,
						setModelPopupSource, tutorial=false}) => {
	const {t} = useTranslation('common');

	const minimizeAndClose = () => {
		minimize(handleClose, openCallback, t(model.name));
	}
	const open3dModel = (url) => {
		show3dModel(true);
		setModel3dUrl(url);
	}

	if(!model){
		return null;
	}

	// console.log(model);

	return (
		<Popup title={t(model.name)} show={show} handleClose={handleClose} handleMinimize={minimizeAndClose}
			   setModelPopupSource={setModelPopupSource} tutorial={tutorial}
			   label={t('main-back').toUpperCase()} position='right' handleClick={handleClose}
			   body={
					<div>
						<ModelsGallery model={model} openGallery={openGallery} open3dModel={open3dModel} tutorial={tutorial}/>
						<div className='content'><p>{t(model.description)}</p></div>
					</div>}
		/>
	)
}

const ModelsGallery = ({model, openGallery, open3dModel, tutorial}) => {
	let gallery_class = 'x3';
	if (model.images.length < 5 && model.images.length !== 3) {
		gallery_class = 'x2';
	}
	let gallery_3d_class = 'x3';
	if (model.models_3d && model.models_3d.length < 5 && model.models_3d.length !== 3) {
		gallery_3d_class = 'x2';
	}
	if (model.models_3d || model.images.length) {
		return (
			<>
				{model.models_3d && model.models_3d.length ?
					<div className='grid_gallery'>
						<div className={`grid_gallery-xl ${gallery_3d_class}`}>
							{model.models_3d.map((model_3d, i) => {
								return  <div className={`grid_gallery-item model ${tutorial ? "" : "pointer"}`} key={i}
											 onClick={() => open3dModel(model_3d.model_3d)}>
											<img src={model_3d.model_3d_thumb} alt=""/>
										</div>
							})}
						</div>
					</div>
					: <div></div>
				}
				{model.images.length ?
					<div className='grid_gallery'>
						<div className={`grid_gallery-s ${gallery_class}`}>
							{model.images.slice(0, 6).map((image, i) => {
								return  <div className={`grid_gallery-item ${image.url ? "video" : "pointer"}`} key={i} onClick={() => openGallery(model.images, i)}>
											<img className='grid-item' src={image.thumb} alt='grid-item'/>
										</div>
							})}
						</div>
					</div>
					: <div></div>
				}
			</>
		)
	} else {
		return("")
	}
}


export default ModelPopup;