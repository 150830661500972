import React, {useRef, useEffect, useState} from "react";
import {PopupWrapper, PopupHeader, PopupBody} from "./Popup.styles";
import MainButton from "../MainButton";

const Popup = ({flag, title, show, body, label, position, handleMinimize, handleClose, handleClick, setModelPopupSource, 
				setPopupWidth=null, disabled=false, tutorial=false}) => {
	const ref = useRef(null);
	const [scrollPosition, setScrollPosition] = useState(0);
	
	useEffect(() => {
		const popupWidth = ref.current ? ref.current.offsetWidth : 0;
		if (popupWidth && setPopupWidth) {
			setPopupWidth(popupWidth);
		}
		
		if (ref && ref.current && show && ref.current.scrollTop !== scrollPosition) {
			ref.current.scrollTop = scrollPosition;
		}
		
		if (ref && ref.current && !show && ref.current.scrollTop !== scrollPosition) {
			setScrollPosition(ref.current.scrollTop);
		}
	}, [scrollPosition, setPopupWidth, setScrollPosition, show]);
	
	const mainFn = () => {
		ref.current.scrollTop = 0;
		handleClick();
	}
	
	const closeFn = () => {
		ref.current.scrollTop = 0;
		// setModelPopupSource(null);
		handleClose();
	}
	
	return (
		<PopupWrapper size="lg" show={show} onHide={closeFn} backdrop={false} position={position}>
			<PopupHeader tutorial={tutorial ? 1 : 0}>
				{flag &&
					<img src={flag} className='country_image' alt='flag'/>
				}
				<span className='modal-label'>
					{title}
				</span>

				<div className="modal-header__controls">
					{/* <button className="btn-minimize" onClick={handleMinimize}>
						<img src="../images/window-minimize.svg" alt=""/>
					</button> */}

					<button className="btn-close" onClick={tutorial ? undefined : closeFn}>
						<img src="../images/window-close.svg" alt=""/>
					</button>
				</div>


			</PopupHeader>
			<PopupBody ref={ref} tutorial={tutorial ? 1 : 0}>
				{body}
			</PopupBody>
			{label && !tutorial &&
				<MainButton label={label} size={2} textsize={8} handleClick={tutorial ? undefined : mainFn} disabled={disabled}/>
			}
		</PopupWrapper>
	)
}

export default Popup;