import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import PropTypes from 'prop-types';
import ImageContent from './Image';
import VideoContent from './Video';
import Button from './Button';
import { addClass, removeClass, classToggle } from './utils/classNames';
import './Container.css'
import { withTranslation } from "react-i18next";

const transitionTime = 300;
const transitionDelta = 50;

class Container extends React.Component {
  constructor(props) {
	super(props);
	this.handleLeftClick = this.handleLeftClick.bind(this);
	this.handleRightClick = this.handleRightClick.bind(this);
	this.canMoveToLeft = this.canMoveToLeft.bind(this);
	this.canMoveToRight = this.canMoveToRight.bind(this);
	this.toggleControls = this.toggleControls.bind(this);
	this.handleKeyboard = this.handleKeyboard.bind(this);
	this.getDescriptions = this.getDescriptions.bind(this);
	this.state = {
	  selectedImageIndex: props.selectedImage,
	  direction: 'none',
	  itemsDescriptions: {},
	  itemsReferences: {}
	};
  }

  getDescriptions(){
	let descriptions = this.state.itemsDescriptions;
	this.props.items.forEach((item, index) => {
	  if(!item.description) return;
	  if(item.description.then){ //if promise
		item.description.then((data) => {
			descriptions[index] = data;
		  this.setState({itemsDescriptions: descriptions});
		});
		return;
	  }
	  descriptions[index] = item.description;
	  this.setState({itemsDescriptions: descriptions});
	});
  }
  
  getReferences(){
	let references = this.state.itemsReferences;
	this.props.items.forEach((item, index) => {
	  if(!item.reference) return;
	  if(item.reference.then){ //if promise
		item.reference.then((data) => {
			references[index] = data;
		  this.setState({itemsReferences: references});
		});
		return;
	  }
	  references[index] = item.reference;
	  this.setState({itemsReferences: references});
	});
  }

  componentDidMount() {
	this.getDescriptions();
	this.getReferences();
	document.addEventListener('keydown', this.handleKeyboard);
	const scrollTop = document.body.scrollTop;
	addClass(document.documentElement, 'lightbox-open');
	document.documentElement.style.top = `-${scrollTop}px`;
	document.body.scroll = "no"; // ie only
  }

  componentWillUnmount() {
	document.removeEventListener('keydown', this.handleKeyboard);
	const scrollTop = Math.abs(parseInt(document.documentElement.style.top, 10))
	removeClass(document.documentElement, 'lightbox-open');
	document.documentElement.style.top = null;
	document.body.scrollTop = scrollTop
	document.body.scroll = "yes"; // ie only
  }

  handleKeyboard(ev) {
	const key = ev.keyCode ? ev.keyCode : ev.which;
	if(this.timeLastTransition &&
	  ((new Date()) - this.timeLastTransition) < transitionTime + transitionDelta) {
	  return;
	}
	this.timeLastTransition = new Date();
	switch(key){
	  case 37:
		return this.handleLeftClick();
	  case 39:
		return this.handleRightClick();
	  case 27:
		return this.props.toggleLightbox();
	  default:
		break;
	}
  }

  handleLeftClick(){
	if (this.canMoveToLeft()) {
	  this.setState({
		selectedImageIndex: (this.state.selectedImageIndex - 1),
		direction: 'left'
	  });
	};
  }

  handleRightClick(){
	if (this.canMoveToRight()) {
	  this.setState({
		selectedImageIndex: (this.state.selectedImageIndex + 1),
		direction: 'right'
	  });
	};
  }

  canMoveToLeft() {
	return (this.state.selectedImageIndex > 0)
  }

  canMoveToRight() {
	return (this.state.selectedImageIndex < (this.props.items.length - 1))
  }

  toggleControls() {
	if(this.refs.container) {
		classToggle(this.refs.container, 'hide-controls')
	}
  }

  render() {
	let [props, state] = [this.props, this.state];
	let item = props.items[state.selectedImageIndex];
	if(!item) {
		return null;
	}
	let leftButton, rightButton;
	let description = state.itemsDescriptions[state.selectedImageIndex];
	let reference = state.itemsReferences[state.selectedImageIndex];
	const transitionName = 'lightbox-transition-image-right';
	const { t } = this.props;

	if(this.canMoveToLeft())
	  leftButton = (
		<div className='lightbox-btn-left'>
		  <Button icon="left-arrow" onClick={this.handleLeftClick} size={ 88 } hasRipple={ true } />
		</div>
	  )
	if(this.canMoveToRight())
	  rightButton = (
		<div className='lightbox-btn-right'>
		  <Button icon="right-arrow" onClick={this.handleRightClick} size={ 88 } hasRipple={ true } />
		</div>
	  )
	return (
	  <div className='lightbox-backdrop' ref='container'>
		<div className='lightbox-btn-close'>
		  <Button icon="close" onClick={props.toggleLightbox} size={ 68 } hasRipple={ false } />
		</div>
		<div className='lightbox-title-content'>
		  <div className='lightbox-title'>
			{t(description)}
		  </div>
		  <div className='lightbox-description'>
			{t(reference)}
		  </div>
		</div>
		<TransitionGroup childFactory={child => React.cloneElement(child, { classNames: state.direction === 'left' ? "lightbox-transition-image-left" : "lightbox-transition-image-right", timeout: {enter: transitionTime, exit: transitionTime, appear: transitionTime} })}s>
			<CSSTransition
				key={item.src}
				className={transitionName} 
				timeout={{enter: transitionTime, exit: transitionTime, appear: transitionTime}}>
				{item.src ?
					<ImageContent key={item.src}
								src={item.src}
								showImageModifiers={props.showImageModifiers}
								toggleControls={this.toggleControls} />
					: <VideoContent url={item.url} provider={item.provider} key={item.url}/>
				}
			</CSSTransition>
			
		</TransitionGroup>
		{leftButton}
		{rightButton}
	  </div>
	)
  }
}

Container.defaultProps = {
  selectedImage: 0
}

Container.propTypes = {
  selectedImage: PropTypes.number,
  items: PropTypes.array.isRequired,
  toggleLightbox: PropTypes.func.isRequired,
  showImageModifiers: PropTypes.bool
}

export default withTranslation('common')(Container);
