import styled from "styled-components";
import blankButton from "../../images/top-win.png";


export const MinimizedPopupDiv = styled.div`
  width: 300px;
  height: 40px;
  position: absolute;
  bottom: 0;
  right: 30px;
  z-index: 2000;
  cursor: pointer;
  background: url(${blankButton});
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  span {
    color: white;
  }

  img {
    width: 300px;
  }
`