import {Table} from "react-bootstrap";
import React from "react";
import {useTranslation} from "react-i18next";
import {countries} from "../../constants";
import logo3 from "../../images/3D-icon.svg"
import Popup from "../UI/Popup";
import _ from "lodash";
import {CascadeButton} from "./ModelsPopup.styles";
import listView from '../../images/list-view.svg';
import cascadeView from '../../images/cascade-view.svg';


const ModelsPopup = ({handleClose, show, models, minimize, openCallback, selectModel, setPopupWidth, markers, 
	setSelectedMarkerIndex, setMapFlyPosition, setModelPopupSource}) => {
	const [showModelsAsList, setShowModelsAsList] = React.useState(false);
	const onClickAsList = () => setShowModelsAsList(!showModelsAsList);
	const {t} = useTranslation('common');

	const minimizeAndClose = () => {
		minimize(handleClose, openCallback, t('search-title'));
	}

	return (
		<Popup title={t('search-title')} show={show} handleClose={handleClose} handleMinimize={minimizeAndClose}
				setPopupWidth={setPopupWidth}
				setModelPopupSource={setModelPopupSource}
				position='right'
				body={
					showModelsAsList ?
						<ModelsTable onClick={onClickAsList} models={models} selectModel={selectModel} markers={markers}
						setSelectedMarkerIndex={setSelectedMarkerIndex} setMapFlyPosition={setMapFlyPosition}/>
						: <ModelsGrid onClick={onClickAsList} models={models} selectModel={selectModel} markers={markers}
							setSelectedMarkerIndex={setSelectedMarkerIndex} setMapFlyPosition={setMapFlyPosition}/>
				}
		/>
	)
}

const ModelsTable = ({models, onClick,  selectModel, markers, setSelectedMarkerIndex, setMapFlyPosition}) => {
	const setModel = (model) => {
		const selected_marker = _.find(markers, (marker) =>{
			return marker.props.position.length === model.gps.length 
				&& marker.props.position.every((v,i)=> v === model.gps[i]);
		});
		if (selected_marker) {
			setSelectedMarkerIndex(selected_marker.props.index);
			setMapFlyPosition(selected_marker.props.position)
		}
		selectModel(model, 'models');
	};
	const {t} = useTranslation('common');
	const getCountryFlag = (model) => {
		let country = _.find(countries, {shortcut: model.country});
		if(country)
			return country.flag;
	}
	return (
		<div>
			<div className="models_table_container">
				<Table hover size="sm" className='models_table'>
					<thead>
					<tr>
						<th className={'table--thumb'}>
							<CascadeButton type="button" onClick={onClick}>
								<img src={cascadeView} alt='cascade-btn'/>
								<span>{t('cascade-view')}</span>
							</CascadeButton>
						</th>
						<th>{t('main-name')}</th>
						<th></th>
						<th>{t('main-epoch')}</th>
						<th>{t('main-3d')}</th>
					</tr>
					</thead>
					<tbody>
					{models.map((model, i) => {
						return  <tr onClick={(e) => {setModel(model)}} key={i}>
									<td className={'table--thumb'}>
										<div className={'table-image'}>
											<img src={model.img} alt='model' className={'img--bg'}/>
										</div>
									</td>
									<td className={'table--name'}>{t(model.name)}</td>
									<td>
										<img src={getCountryFlag(model)} className='table--country-img' alt='country'/>
									</td>
									<td className={'table--epoch'}>{t(model.epoch)}</td>
									<td className={'table--3d'}>{model.models_3d ? <img src={logo3} alt='logo'></img> : ""}</td>
								</tr>
					})}
					</tbody>
				</Table>
			</div>
		</div>
	)
}

const ModelsGrid = ({models, onClick, selectModel, markers, setSelectedMarkerIndex, setMapFlyPosition}) => {
	const {t} = useTranslation('common');
	const setModel = (model) => {
		const selected_marker = _.find(markers, (marker) =>{
			return marker.props.position.length === model.gps.length 
				&& marker.props.position.every((v,i)=> v === model.gps[i]);
		});
		if (selected_marker) {
			setSelectedMarkerIndex(selected_marker.props.index);
			setMapFlyPosition(selected_marker.props.position)
		}
		selectModel(model, 'models');
	};
	const getCountryFlag = (model) => {
		let country = _.find(countries, {shortcut: model.country});
		if(country)
			return country.flag;
	}
	return (
		<div className={'models-list'}>
			<div className={'models-list__header'}>
				<CascadeButton type="button" onClick={onClick}>
					<img src={listView} alt='cascade-btn'/>
					<span>{t('list-view')}</span>
				</CascadeButton>
			</div>
			<div className="models-list__body">
				{models.map((model, i) => {
					// console.log(model)
					return  <div key={i} onClick={(e) => {setModel(model)}} className='models-list__item'>
								<div className={'list-image'}>
									<img className={'img--bg'} src={model.img} alt='model'/>
								</div>
								<p className={'list--name'}>{t(model.name)}</p>
								<p className={'list--epoch'}>{t(model.epoch)}</p>
								<span><img src={getCountryFlag(model)} className='grid--country-img' alt='country'/></span>
								<span className={'list--3d'}>{model.models_3d ? <img src={logo3} alt='logo'></img> : ""}</span>
							</div>
				})}
			</div>
		</div>

	)
}

export default ModelsPopup;