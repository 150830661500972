import {IframeSyled, Aton} from './Aton.styles'
import Button from '../Gallery/components/Button';

const AtonComponent = ({show, setShow, url}) => {
	return(
		<Aton show={show}>
			<IframeSyled title="3d model" src={url} />
			<Button icon="close" onClick={()=>setShow(false)} size={ 68 } hasRipple={ false } />
		</Aton>
		
	)
}

export default AtonComponent;