import styled from 'styled-components';

export const IframeSyled = styled.iframe`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 12000;
`

export const Aton = styled.div`
	display: ${props => props.show === true ? 'inherit' : 'none'};
	button {
		position: absolute;
		right: 5px;
		top: 10px;
		z-index: 13000;
	}
`