import {MenuBackgroundWrapper} from "./MenuBackground.styles";
import darkScreen from "../../../images/dark-screen.png";
import darkScreen1 from "../../../images/dark-screen-1.png";
import darkScreen2 from "../../../images/dark-screen-2.png";

const MenuBackground = ({type}) => {
	if(type === 1) {
		return (
			<MenuBackgroundWrapper src={darkScreen1} alt="dark-screen-1"/>
		)
	} else if(type === 2) {
		return (
			<MenuBackgroundWrapper src={darkScreen2} alt="dark-screen-2"/>
		)
	}
	return (
		<MenuBackgroundWrapper src={darkScreen} alt="dark-screen"/>
	)
}

export default MenuBackground;