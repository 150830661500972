import {CSSTransition, TransitionGroup} from "react-transition-group";
import React from "react";
import {Logo} from "./MainPopup.styles";


const SponsorsIndex = ({sponsor}) => {

    return (
        <TransitionGroup className="transitionGroup">
            <CSSTransition
                key={sponsor.id}
                timeout={1000}
                classNames={"fade"}
            >
                <div className={"modal-footer__logos"}>
                    {sponsor.images.map((image, index) => (
                            <div className={sponsor.id === 0 ? "modal-footer__main-logo" : "modal-footer__logo"}
								key={index}>
                                <Logo src={image} alt={"logo" + index}/>
                            </div>
                        )
                    )}
                </div>
            </CSSTransition>
        </TransitionGroup>
    )
}

export default SponsorsIndex;