import {useTranslation} from "react-i18next";
import React, {useEffect, useRef} from "react";
import bub1 from '../../images/bubbles/bub-1.svg';
import bub2 from '../../images/bubbles/bub-2.svg';
import darkScreen from '../../images/bg-win_blank.png';
import robot1 from '../../images/robots/robot-1.svg';
import robot2 from '../../images/robots/robot-2.svg';
import Typewriter from 'typewriter-effect';
import MainButton from "../UI/MainButton";

import {
    IntroBackground,
    IntroDiv,
    Message,
    Message2,
    RobotImage,
    RobotImage2,
} from "./Intro.styles";


const Intro = ({show, closeIntro, currentStep, setCurrentStep}) => {

    const {t} = useTranslation('common');

    const timer = useRef(null);


    useEffect(() => {
        timer.current = (setInterval(updateMessage, 1000));
        return () => {
            clearInterval(timer.current);
        };
    });

    const restartTimer = () => {
        clearInterval(timer.current);
        setCurrentStep(1);
    };

    const cancelTimer = () => {
        clearInterval(timer.current);
        closeIntro();
    }

    const skipToEnd = () => {
        setCurrentStep(77);
        clearInterval(timer.current);
    }

    const updateMessage = () => {
        if (currentStep >= 76) {
            clearInterval(timer.current);
        } else {
            setCurrentStep(currentStep + 1);
        }
    }

    if (!show) {
        return "";
    } else if (currentStep <= 9) {
        return (
            <>
                <IntroDiv>
                    <Message>
                        <img src={bub1} alt=''/>
                        <Typewriter
                            options={{
                                strings: t('intro-robot-message1'),
                                autoStart: true,
                                cursor: "",
                                deleteSpeed: "0",
                                delay: 70
                            }}
                        />
                    </Message>
                    <IntroBackground src={darkScreen} alt="dark-screen"/>
                    <RobotImage src={robot1}></RobotImage>
                    <RobotImage2 src={robot2}></RobotImage2>
                    <MainButton label={t('intro-continue').toUpperCase()} size={2} top='80%'
                                handleClick={skipToEnd}/>
                </IntroDiv>
            </>
        )
    } else if (currentStep > 9 && currentStep <= 19) {
        return (
            <>
                <IntroDiv>
                    <Message2>
                        <img src={bub2} alt=''/>
                        <Typewriter
                            options={{
                                strings: t('intro-robot-message2'),
                                autoStart: true,
                                cursor: "",
                                deleteSpeed: "0",
                                delay: 70
                            }}
                        />
                    </Message2>
                    <IntroBackground src={darkScreen} alt="dark-screen"/>
                    <RobotImage src={robot1}></RobotImage>
                    <RobotImage2 src={robot2}></RobotImage2>


                    <MainButton label={t('intro-continue').toUpperCase()} size={2} top='80%'
                                handleClick={skipToEnd}/>
                </IntroDiv>


            </>
        )
    } else if (currentStep > 19 && currentStep <= 24) {
        return (
            <>
                <IntroDiv>

                    <Message>
                        <img src={bub1} alt=''/>
                        <Typewriter
                            options={{
                                strings: t('intro-robot-message3'),
                                autoStart: true,
                                cursor: "",
                                deleteSpeed: "0",
                                delay: 70
                            }}
                        />
                    </Message>
                    <IntroBackground src={darkScreen} alt="dark-screen"/>
                    <RobotImage src={robot1}></RobotImage>
                    <RobotImage2 src={robot2}></RobotImage2>


                    <MainButton label={t('intro-continue').toUpperCase()} size={2} top='80%'
                                handleClick={skipToEnd}/>
                </IntroDiv>


            </>
        )
    } else if (currentStep > 24 && currentStep <= 29) {
        return (
            <>
                <IntroDiv>

                    <Message2>
                        <img src={bub2} alt=''/>
                        <Typewriter
                            options={{
                                strings: t('intro-robot-message4'),
                                autoStart: true,
                                cursor: "",
                                deleteSpeed: "0",
                                delay: 70
                            }}
                        />
                    </Message2>
                    <IntroBackground src={darkScreen} alt="dark-screen"/>
                    <RobotImage src={robot1}></RobotImage>
                    <RobotImage2 src={robot2}></RobotImage2>


                    <MainButton label={t('intro-continue').toUpperCase()} size={2} top='80%'
                                handleClick={skipToEnd}/>
                </IntroDiv>


            </>
        )
    } else if (currentStep > 29 && currentStep <= 41) {
        return (
            <>
                <IntroDiv>

                    <Message>
                        <img src={bub1} alt=''/>
                        <Typewriter
                            options={{
                                strings: t('intro-robot-message5'),
                                autoStart: true,
                                cursor: "",
                                deleteSpeed: "0",
                                delay: 70
                            }}
                        />
                    </Message>
                    <IntroBackground src={darkScreen} alt="dark-screen"/>
                    <RobotImage src={robot1}></RobotImage>
                    <RobotImage2 src={robot2}></RobotImage2>


                    <MainButton label={t('intro-continue').toUpperCase()} size={2} top='80%'
                                handleClick={skipToEnd}/>
                </IntroDiv>


            </>
        )
    } else if (currentStep > 41 && currentStep < 10000000) {
        return (
            <>
                <IntroDiv>
                    <IntroBackground src={darkScreen} alt="dark-screen"/>
                    <RobotImage src={robot1}></RobotImage>
                    <RobotImage2 src={robot2}></RobotImage2>

                    {/* <ContinueButton2  onClick={cancelTimer}>
						<img src={blankButton} />
						<ButtonText2>Start</ButtonText2>
					</ContinueButton2> */}
                    <MainButton label={t('intro-start').toUpperCase()} size={2} top='52.5%'
                                handleClick={cancelTimer}/>
                </IntroDiv>

            </>
        )
    } else if (currentStep > 10000000) {
        return (
            <>
                <IntroDiv>
                    <IntroBackground src={darkScreen} alt="dark-screen"/>
                    <RobotImage src={robot1}></RobotImage>
                    <RobotImage2 src={robot2}></RobotImage2>
                    <MainButton label={t('intro-start-intro').toUpperCase()} size={2}
                                handleClick={restartTimer}/>
                </IntroDiv>
            </>
        )

    } else {
        return "";
    }

}

export default Intro;
