import styled from "styled-components";
import {Modal} from 'react-bootstrap';
import headerBackground from "../../../images/top-win.png"

export const PopupWrapper = styled(Modal)`
  z-index: 3999;
  overflow-y: hidden;
  width: unset;
  height: unset;
  top: 14vh;
  right: 100px;
  left: unset;
  filter: drop-shadow(3px 3px 15px rgba(0, 0, 0, .4));

  @media (max-width: 768px) {
    right: 10px;
    left: 10px;
  }

  .modal-dialog {
	margin: 0;
  }

  .modal-content {
	height: 70vh;
	border-radius: 0;
	border: none;
	background-color: transparent;
	position: relative;
	padding: 0 0 50px 0;

	&:after {
	  content: '';
	  position: absolute;
	  left: 0;
	  bottom: 70px;
	  width: calc(100% - 19px);
	  height: 60px;
	  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
	}

	&:before {
	  content: '';
	  position: absolute;
	  left: 0;
	  bottom: 50px;
	  width: calc(100% - 19px);
	  height: 20px;
	  background: white;
	  z-index: 1;
	}

	button.btn {
	  position: absolute;
	  left: calc(50% - 100px);
	  bottom: 10px;
	  z-index: 1;

      @media (max-width: 768px) {
        left: calc(50% - 63px);
        bottom: 30px;
      }

	  &:focus {
		box-shadow: none;
	  }

	  &:disabled {
		cursor: not-allowed;
	  }
	}

	.img--bg {
	  position: absolute;
	  top: 0;
	  left: 0;
	  right: 0;
	  bottom: 0;
	  margin: auto;
	  width: 100%;
	  height: 100%;
	  object-fit: cover;
	}
  }

  .modal-body {
	max-height: calc(80vh - 80px);
	overflow: auto;
	padding: 25px;

	h2 {
	  font-size: 20px;
	  text-align: center;
	}
  }

  .modal-dialog {
	width: 600px;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .pointer {
	cursor: pointer;
  }


`

export const PopupHeader = styled(Modal.Header)`

  height: 80px;
  padding: 20px;
  color: white;
  border-bottom: 0 none;
  background-size: 100%;
  background-repeat: round;
  background-image: url(${headerBackground});
  display: flex;
  justify-content: space-between;

  .modal-header__controls {
	position: relative;
	display: flex;

	button {
	  position: unset;
	  padding: 0 !important;
      cursor: ${props => props.tutorial ? 'default' : 'pointer'};

	  img {
		width: 13px;
		height: auto;
        cursor: ${props => props.tutorial ? 'default' : 'pointer'};
	  }

	  &.btn-minimize {
		margin-right: 10px;

	  }

	  &.btn-close {
		padding: unset !important;
		margin: unset !important;
		opacity: 1 !important;
	  }
	}
  }

  .country_image {
	width: 120px;
	padding-right: 15px;
  }
`

export const PopupBody = styled(Modal.Body)`
	padding: 35px;
	background-color: white;
	scrollbar-width: thin;
	scrollbar-color: grey white;
	//-webkit-clip-path: polygon(100% 0, 100% 93%, 95% 100%, 0 100%, 0% 0);
	//clip-path: polygon(100% 0, 100% 93%, 95% 100%, 0 100%, 0% 0);

	.models_table {
		position: relative;

		.table-image {
			height: 50px;
			width: 90px;
			overflow: hidden;
			position: relative;
		}

		.table--country-img {
			width: 40px;
		}

		.table--thumb {
			padding-left: 0;
			padding-right: 0;
		}

		.table--name {
			color: black;
			font-size: 14px;
			line-height: 16px;
			text-transform: uppercase;
		}

		.table--epoch {
			color: #AA9963;
			font-size: 14px;
			line-height: 16px;
		}

		.table--3d {

			img {
			width: 20px;
			}
		}

		thead {
			border-bottom: 2px solid #807770;
			color: #807770;
			font-size: 14px;
			font-weight: 600;
			text-transform: uppercase;

			tr {
				th {
					padding: 3px 10px;
				}
			}
		}

		tbody {
			tr {
				cursor: pointer;
				&:hover {
					background: #f2f2f2 !important;
				}
				td {
					padding: 7px 10px;
				}
			}
		}
	}

	.models-list {
		position: relative;

		.list--name {
			color: black;
			font-size: 13px;
			line-height: 14px;
			text-transform: uppercase;
			margin-bottom: 8px;
		}

		.list--epoch {
			color: #AA9963;
			font-size: 14px;
			line-height: 16px;
			margin-bottom: 0;
		}

		.list--3d {

			img {
			width: 20px;
			}
		}

		&__header {
			border-bottom: 2px solid #807770;
			text-transform: uppercase;
			padding-top: 22px;
			margin-bottom: 10px;
		}

		&__body {
			display: flex;
			flex-wrap: wrap;

			.models-list__item {
				width: calc(33.3% - 10px);
				margin-right: 15px;
				text-align: center;
				cursor: pointer;
				margin-bottom: 10px;
				padding-bottom: 10px;

				&:hover {
					background: #f2f2f2;
				}

				&:nth-of-type(3n) {
					margin-right: 0;
				}

				.list-image {
					width: 100%;
					height: 100px;
					overflow: hidden;
					position: relative;
					margin-bottom: 10px;
				}
			}
		}
		
		.grid--country-img {
			width: 40px;
		}
	}

  .models_table_container {
	height: 100%;
	//mask-image: linear-gradient(to bottom, black 70%, transparent 100%);
	overflow: auto;
	scrollbar-width: thin;
	scrollbar-color: grey white;
	padding-bottom: 50px;

	::-webkit-scrollbar {
	  width: 10px;
	  height: 15px;
	}

	::-webkit-scrollbar-track-piece {
	  background: transparent;
	}

	::-webkit-scrollbar-thumb:vertical {
	  height: 30px;
	  background-color: grey;
	}
  }

  .qu
  estion_text {
	margin-top: 30px;
	font-weight: bold;
	font-size: 14px;
	line-height: 16px;
  }

  .question-image {
	height: 150px;
	position: relative;
  }

  .funfact_text {
	margin-top: 20px;
	font-weight: 400;
  }

  .correct-heading {
	color: #22c55e;
	font-size: 28px !important;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 50px;

	img {
	  height: 36px;
	  margin-right: 15px;
	}
  }

  .wrong-heading {
	color: #ef4444;
	font-size: 28px !important;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 50px;

	img {
	  height: 36px;
	  margin-right: 15px;
	}
  }

  .content {
	height: 100%;
	overflow: auto;
	scrollbar-width: thin;
	scrollbar-color: grey white;
	padding-bottom: 50px;
	//mask-image: linear-gradient(to bottom, black 70%, transparent 110%);

	p {
	  font-size: 14px;
	  line-height: 20px;
	}
  }

  .content::-webkit-scrollbar {
	width: 8px;
	height: 15px;
  }

  .content::-webkit-scrollbar-track-piece {
	background-color: white;
  }

  .content::-webkit-scrollbar-thumb:vertical {
	height: 30px;
	background-color: grey;
  }

  .grid_gallery {
	height: 130px;
	width: 100%;
	margin-bottom: 25px;
	display: flex;

	&-xl {
	  //background: #eee;
	  flex: 3 3 0;
	  margin-right: 5px;
      display: grid;
      grid-template-columns: auto auto auto;
      grid-gap: 5px;


	  .grid_gallery-item {
		flex-grow: 1;
		flex-basis: 0;

		&.model {

		  &:after {
			content: '';
			position: absolute;
			top: calc(50% - 40px);
			left: calc(50% - 40px);
			height: 80px;
			width: 80px;
			background: rgba(0, 0, 0, .5);
			border-radius: 50%;
			margin: auto;
			filter: blur(10px);
		  }

		  &:before {
			content: url("../images/3d-model.svg");
			position: absolute;
			left: calc(50% - 18px);
			top: calc(50% - 18px);
			width: 36px;
			height: 36px;
			/*border-top: 12px solid transparent;
			border-bottom: 12px solid transparent;
			border-left: 18px solid white;*/
			z-index: 1;
		  }
		}
	  }
	}

	&-s {
	  flex: 5 5 0;

	  &.x2 {
		display: grid;
		grid-template-columns: auto auto;
		grid-gap: 5px;
	  }

	  &.x3 {
		display: grid;
		grid-template-columns: auto auto auto;
		grid-gap: 5px;
	  }

	  .grid_gallery-item {
		flex-basis: 0;
		flex-grow: 1;
		height: auto;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;


		&.video {

		  &:after {
			content: '';
			position: absolute;
			top: calc(50% - 40px);
			left: calc(50% - 40px);
			height: 80px;
			width: 80px;
			background: rgba(0, 0, 0, .3);
			border-radius: 50%;
			margin: auto;
			filter: blur(10px);
		  }

		  &:before {
			content: url("../images/video-play.svg");
			position: absolute;
			left: calc(50% - 18px);
			top: calc(50% - 18px);
			width: 36px;
			height: 36px;
			/*border-top: 12px solid transparent;
			border-bottom: 12px solid transparent;
			border-left: 18px solid white;*/
			z-index: 1;
		  }
		}
	  }
	}


	.grid_gallery-item {
	  cursor: ${props => props.tutorial ? 'default' : 'pointer'};
	  position: relative;
	  overflow: hidden;

	  &:last-of-type {
		margin-right: 0;
	  }

	  img {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		width: 100%;
		height: 100%;
		-o-object-fit: cover;
		object-fit: cover;
		z-index: 0;
	  }
	}
  }

  .grid_table {
	display: flex;
  }

  .grid_table img {
	height: 130px;
	width: 22vh;
  }

  td {
	vertical-align: middle
  }

  .react-player {
	position: relative;
	top: 0;
	left: 50%;
	transform: translate(-50%, -50%)
  }
`