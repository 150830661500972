import React from 'react';
import {Table} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import logo3 from "../../images/3D-icon.svg";
import Popup from '../UI/Popup';
import listView from "../../images/list-view.svg";
import {CascadeButton} from "../CountryPopup/CountryPopup.styles";
import cascadeView from "../../images/cascade-view.svg";
import _ from "lodash";

const EpochPopup = ({handleClose, show, epoch, models, selectModel, minimize, openCallback, setPopupWidth, markers,
		setSelectedMarkerIndex, setMapFlyPosition, setModelPopupSource}) => {
	const [showModels, setShowModels] = React.useState(false)
	const [showModelsAsList, setShowModelsAsList] = React.useState(false)
	const onClickAsList = () => setShowModelsAsList(!showModelsAsList)
	const onClick = () => setShowModels(!showModels)
	const {t} = useTranslation('common');

	const minimizeAndClose = () => {
		minimize(handleClose, openCallback, t(epoch.name));
	}

	if (epoch) {
		return (
			<Popup title={t(epoch.name)} show={show} handleClose={handleClose} handleMinimize={minimizeAndClose}
				setPopupWidth={setPopupWidth}
				setModelPopupSource={setModelPopupSource}
				label={!showModels ? t('enter').toUpperCase() : t('main-back').toUpperCase()} position='right' handleClick={onClick}
				body={!showModels ?
					<div>
						<div className="static-gallery">
							<img src={t(epoch.image)} alt="" onClick={onClick}/>
						</div>

						<div className='content'>{t(epoch.content)}</div>
						{/*<ModelsGallery models={models}/>*/}
					</div>
					: showModelsAsList ?
						<ModelsTable onClick={onClickAsList} models={models} selectModel={selectModel} markers={markers}
						setSelectedMarkerIndex={setSelectedMarkerIndex} setMapFlyPosition={setMapFlyPosition}/>
						: <ModelsGrid onClick={onClickAsList} models={models} selectModel={selectModel} markers={markers}
							setSelectedMarkerIndex={setSelectedMarkerIndex} setMapFlyPosition={setMapFlyPosition}/>}
			/>
		)

	} else {
		return "";
	}
}

// const ModelsGallery = ({models}) => {
// 	return (
// 		<div className='grid_gallery'>
// 			{models.slice(0, 5).map((model, i) => {
// 				return <img key={i} className='grid-item' src={model.img} alt='model'/>
// 			})}
// 		</div>
// 	)
// }

const ModelsGrid = ({models, onClick, selectModel, markers, setSelectedMarkerIndex, setMapFlyPosition}) => {
	const setModel = (model) => {
		const selected_marker = _.find(markers, (marker) =>{
			return marker.props.position.length === model.gps.length 
				&& marker.props.position.every((v,i)=> v === model.gps[i]);
		});
		if (selected_marker) {
			setSelectedMarkerIndex(selected_marker.props.index);
			setMapFlyPosition(selected_marker.props.position)
		}
		selectModel(model, 'epoch');
	};
	const {t} = useTranslation('common');
	return (
		<div className={'models-list'}>
			<div className={'models-list__header'}>
				<CascadeButton type="button" onClick={onClick}>
					<img src={listView} alt='list-view'/>
					<span>{t('list-view')}</span>
				</CascadeButton>
			</div>
			<div className="models-list__body">
				{models.map((model, i) => {
					return  <div key={i} onClick={(e) => {setModel(model)}} className='models-list__item'>
								<div className={'list-image'}>
									<img className='img--bg' src={model.img} alt='model'/>
								</div>
								<p className={'list--name'}>{t(model.name)}</p>
								<p className={'list--epoch'}>{t(model.epoch)}</p>
								<span className={'list--3d'}>{model.model_3d ? <img src={logo3} alt='logo'></img> : ""}</span>
							</div>
				})}
			</div>
		</div>

	)
}

const ModelsTable = ({models, onClick, selectModel, markers, setSelectedMarkerIndex, setMapFlyPosition}) => {
	const setModel = (model) => {
		const selected_marker = _.find(markers, (marker) =>{
			return marker.props.position.length === model.gps.length 
				&& marker.props.position.every((v,i)=> v === model.gps[i]);
		});
		if (selected_marker) {
			setSelectedMarkerIndex(selected_marker.props.index);
			setMapFlyPosition(selected_marker.props.position)
		}
		selectModel(model, 'epoch');
	};
	const {t} = useTranslation('common');
	return (
		<div>
			<div className="models_table_container">
				<Table hover size="sm" className='models_table'>
					<thead>
					<tr>
						<th><CascadeButton type="button" onClick={onClick}>
							<img src={cascadeView} alt='cascade-btn'/>
							<span>{t('cascade-view')}</span>
						</CascadeButton></th>
						<th>{t('main-name')}</th>
						<th>{t('main-epoch')}</th>
						<th>{t('main-3d')}</th>
					</tr>
					</thead>
					<tbody>
					{models.map((model, i) => {
						return <tr onClick={(e) => {setModel(model)}} key={i}>
							<td className={'table--thumb'}>
								<div className={'table-image'}>
									<img className={'img--bg'} src={model.img} alt='model'/>
								</div>
							</td>
							<td className={'table--name'}>{t(model.name)}</td>
							<td className={'table--epoch'}>{t(model.epoch)}</td>
							<td className={'table--3d'}>{model.models_3d ? <img src={logo3} alt='logo'></img> : ""}</td>
						</tr>
					})}
					</tbody>
				</Table>

			</div>
		</div>
	)
}

export default EpochPopup;