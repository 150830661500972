import {createGlobalStyle} from "styled-components";

export const GlobalStyle = createGlobalStyle`

  html {
    background-color: black
  }

  .modal.fade .modal-dialog {
    //	right: -30%;
    top: 30%;
    transition: opacity 0.3s linear, right 0.3s linear, top 0.3s linear;
  }

  .modal.show .modal-dialog {
    right: 0;
    top: 0;
  }

  .highlight-element {
    z-index: 4000 !important;
    //    position: relative;
  }

  .hide-element {
    display: none;
  }

  .modal-label {
    font-weight: 500;
    font-size: 20px;

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  .modal-header {
    position: relative;


    img {
      width: 35px;
    }

    button {
      //   background: transparent;
      border: 0;
      float: right;
      padding-right: 20px !important;
      //  color: white;
    }

    .btn-close {
      top: 13px;
      position: relative;
      background: none !important;
    }

    .minimize_button {
      font-size: 28px;
      position: absolute;
      right: 35px;
      top: 14px;
      background: transparent;


      height: 15px;
    }
  }

  .answer-grid {
    //background: red;
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;

    &__item {
      position: relative;
      width: calc(50% - 5px);
      min-height: 40px;
      margin-right: 10px;
      margin-bottom: 10px;

      &:nth-of-type(2n) {
        margin-right: 0;
      }

      &:nth-of-type(1) {

        .container {

          .checkmark {
            -webkit-clip-path: polygon(0 0, 0 100%, 95% 100%, 100% 70%, 100% 0);
            clip-path: polygon(0 0, 0 100%, 95% 100%, 100% 70%, 100% 0);
          }
        }
      }

      &:nth-of-type(2) {

        .container {

          .checkmark {
            -webkit-clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 30%, 95% 0);
            clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 30%, 95% 0);
          }
        }
      }

      &:nth-of-type(4) {

        .container {

          .checkmark {
            -webkit-clip-path: polygon(0 0, 0 70%, 5% 100%, 100% 100%, 100% 0);
            clip-path: polygon(0 0, 0 70%, 5% 100%, 100% 100%, 100% 0);
          }
        }
      }

      &:nth-of-type(3) {

        .container {

          .checkmark {
            -webkit-clip-path: polygon(0 30%, 0 100%, 100% 100%, 100% 0, 5% 0);
            clip-path: polygon(0 30%, 0 100%, 100% 100%, 100% 0, 5% 0);
          }
        }
      }

      .container {
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        font-size: 14px;
        user-select: none;
        height: 100%;
        width: 100%;
        z-index: 1;
        color: black;
      }

      /* Hide the browser's default radio button */

      .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      /* Create a custom radio button */

      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: #eee;
        z-index: -1;
      }

      /* On mouse-over, add a grey background color */

      .container:hover input ~ .checkmark {
        background: #ddd;
      }

      /* When the radio button is checked, add a blue background */

      .container input:checked ~ .checkmark {
        background-color: #aa9963;
        color: white !important;
      }

      /* Show the indicator (dot/circle) when checked */

      .container input:checked ~ .checkmark:after {
        display: block;
      }
    }


  }

  .map-link-cover {
    background: transparent;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20px;
    z-index: 1111;
  }

  .static-gallery {
    margin-bottom: 1rem;

    img:hover {
      cursor: pointer;
    }
  }

`
