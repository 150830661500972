import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import i18next from "i18next";
import {I18nextProvider, initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector"
import common_sk from "./translations/sk/common.json";
import common_en from "./translations/en/common.json";
import common_hu from "./translations/hu/common.json";
import common_hr from "./translations/hr/common.json";
import common_bg from "./translations/bg/common.json";
import common_sl from "./translations/sl/common.json";
import common_cs from "./translations/cs/common.json";
import common_de from "./translations/de/common.json";
import common_ro from "./translations/ro/common.json";
import common_sr from "./translations/rs/common.json";

i18next.use(LanguageDetector).use(initReactI18next).init({
    resources: {
        en: {
            common: common_en
        },
        sk: {
            common: common_sk
        },
        bg: {
            common: common_bg
        },
        hu: {
            common: common_hu
        },
        hr: {
            common: common_hr
        },
        cs: {
            common: common_cs
        },
        de: {
            common: common_de
        },
        ro: {
            common: common_ro
        },
        sr: {
            common: common_sr
        },
        sl: {
            common: common_sl
        },
    },
    fallbackLng: 'en',  //Determines, which language will be chosen if translation is not found
    load: 'languageOnly',
    supportedLngs: ['bg', 'cs', 'en', 'de', 'hr', 'hu', 'ro', 'sk', 'sl', 'sr'],
    nonExplicitSupportedLngs: true,
    interpolation: {escapeValue: false},  // React already does escaping
    returnNull: false,
    returnEmptyString: true,
});

ReactDOM.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18next}>
            <Router>
                <Routes>
                    <Route path="/" element={<App/>}/>
                </Routes>
            </Router>
        </I18nextProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
