import {ButtonDiv, Message, QuizMedal, QuizResultBackground, QuizResultDiv, RobotImage} from "./QuizResult.styles";
import bg_win from "../../images/quiz/bg-win_blank.png"
import bg_lost from "../../images/quiz/bg-no-win_blank.png"
import med_gold from "../../images/quiz/med-gold.png"
import med_silver from "../../images/quiz/med-silver.png"
import med_bronze from "../../images/quiz/med-bronze.png"
import robot from "../../images/quiz/robot-2.svg"
import bubble from "../../images/bubbles/bub-1.svg"
import {useEffect, useState} from "react";
import Typewriter from "typewriter-effect";
import MainButton from "../UI/MainButton";
import {useTranslation} from "react-i18next";

const QuizResult = ({score, setShowResult, resetQuiz, closePopup}) => {
    const {t} = useTranslation('common');
    const [medal, setMedal] = useState("");

    useEffect(() => {
        if (score < 3) {
            setMedal(med_bronze)
        } else if (score > 2 && score < 5) {
            setMedal(med_silver)
        } else {
            setMedal(med_gold)
        }
    }, [score]);

    const handleClose = () => {
        resetQuiz();
        setShowResult(false);
        closePopup();
    };

    const handleRepeat = () => {
        resetQuiz();
        setShowResult(false);
    };

    return (
        <QuizResultDiv>
            <QuizResultBackground src={score === 0 ? bg_lost : bg_win}
                                  alt={bg_win}/>
            {score === 0 ?
                <>
                    <Message>
                        <img src={bubble} alt=''/>
                        <Typewriter
                            options={{
                                strings: t('quiz_lost'),
                                autoStart: true,
                                cursor: "",
                                deleteSpeed: "0",
                                delay: 70
                            }}
                        />
                    </Message>
                    <RobotImage src={robot}/>
                </>
                :
                <>
                    <QuizMedal>
                        <div className="quiz-inner">

                            <div className="header">{t('quiz_congratulations')}</div>
                            <div className="body">
                                <img src={medal} alt=''/>
                                <div className="body__top">{t('quiz_master')}</div>
                                <div className="body__bottom">{t('quiz_archeology')}</div>

                            </div>
                            <div className="score">{t('quiz_score')} {score}/6</div>
                        </div>

                    </QuizMedal>
                </>
            }
            <ButtonDiv>
                <div className="btn-container">
                    <MainButton label={t('quiz-again')} size={2} handleClick={handleRepeat}/>
                    <MainButton label={t('main-close')} size={2} handleClick={handleClose}/>
                </div>
            </ButtonDiv>
        </QuizResultDiv>
    );
}

export default QuizResult;
