import styled from "styled-components";
import {Button} from "react-bootstrap";
import btnOverBg from "../../images/buttons/button-blank_over.svg";


export const Message = styled.div`
  position: absolute;
  left: ${(props) => props?.leftValue}%;
  top: ${(props) => props?.topValue}%;
  z-index: 3001;
  height: 200px;
  width: 300px;
  font-family: 'Anonymous Pro', monospace;

  .Typewriter__wrapper {
    color: black !important;
  }

  .Typewriter {
    position: absolute;
    width: 220px;
    top: 90px;
    left: 60px;
  }

  &.tour-msg {

    .Typewriter {
      position: unset;
      width: unset;
      top: unset;
      left: unset;
    }
    
    .Typewriter__wrapper {
      color: #AA9963 !important;
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 0;
    }
  }
`

export const IntroBackground = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
`

export const RobotImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0 auto;
  height: 50%;
  z-index: 3001;
  display: ${props => props?.show ? 'block' : 'none'};
  transform: translate(-50%, -50%);
`

export const Arrow = styled.img`
  z-index: 3001;
  width: 10%;
  position: absolute;
  top: ${(props) => props?.topValue}%;
  right: ${(props) => props?.rightValue}%;
  display: ${props => props.show ? 'block' : 'none'};
  margin: 0 auto;
`

export const ButtonWrapper = styled(Button)`
  background-image: url(${btnOverBg}) !important;
  width: 200px;
  height: 67px;
  line-height: 1.1;
  text-shadow: 0 0 5px #000000;
  background-size: cover !important;
  font-weight: 500;
`

export const IntroDiv = styled.div`
  height: 100%;
  width: 100%;
  z-index: 3000;
  position: absolute;
  top: 0px;
  left: 0px;

  button {
    z-index: 3001;
    position: relative;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 83%;
  }
`

export const CheckboxButtons = styled.div`
  position: absolute;
  width: 100%;
  top: 94%;
  left: 0;
  z-index: 3001;
  display: flex;
  justify-content: center;
  align-items: center;

  .select-container {
    margin-top: -100px;
  }

  .options-container {
    display: flex;
    position: relative;
    justify-content: center;
    z-index: 1;
    margin-top: 2vh;
    width: 30vw;

    &__item {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 1vw;

      span {
        color: white;
        padding-bottom: 10px;
      }
    }
  }

  @media (max-width: 768px) {
    
    .select-container {
      margin-top: 50px;
    }
  }
`