import React from "react";
import {CheckboxWrapper, RadioWrapper} from "./Checkbox.styles";

const Checkbox = ({type, id, label, name, checked, handleChange}) => {
	if(type === 'checkbox') {
		return (
			<CheckboxWrapper>
				<input type="checkbox" id={id} onChange={handleChange} checked={checked}/>
				<label htmlFor={id}>{label}</label>
			</CheckboxWrapper>
		)
	} else if(type === 'radio') {
		return (
			<RadioWrapper>
				<input type="radio" id={id} onChange={handleChange} name={name} checked={checked}/>
				<label htmlFor={id}>{label}</label>
			</RadioWrapper>
		)
	} else {
		return '';
	}
}

export default Checkbox;