import styled from "styled-components";


export const Message = styled.div`
  position: absolute;
  left: 12%;
  top: 7%;
  z-index: 2501;
  height: 200px;
  width: 300px;
  font-family: 'Anonymous Pro', monospace;

  img {
    z-index: 2501;
  }

  .Typewriter {
    position: absolute;
    z-index: 2501;
    width: 250px;
    top: 70px;
    left: 40px;
  }

  @media (max-width: 1200px) {
    right: 20px;
    top: unset;
    left: unset;
    bottom: 33%;

    .Typewriter {
      width: 220px;
      top: 70px;
      bottom: 0;
      left: 50px;
    }
  }
`

export const Message2 = styled.div`
  position: absolute;
  right: 20%;
  top: 14%;
  z-index: 2501;
  height: 200px;
  width: 300px;
  font-family: 'Anonymous Pro', monospace;

  img {
    z-index: 2501;
  }

  .Typewriter__wrapper {
    color: black !important;
  }

  .Typewriter {
    position: absolute;
    z-index: 2501;
    width: 220px;
    top: 50px;
    left: 30px;
  }

  @media (max-width: 1200px) {
    left: 20px;
    top: unset;
    bottom: 33%;

    .Typewriter {
      width: 220px;
      top: 50px;
      bottom: 0;
      left: 20px;
    }
  }
`

export const IntroBackground = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2500;
`

export const RobotImage = styled.img`
  position: absolute;
  left: 17%;
  top: 40%;
  height: 60%;
  z-index: 2501;

  @media (max-width: 1200px) {
    right: 10px;
    left: unset;
    bottom: 0;
    height: 30%;
    top: unset;
    transform: scale(-1, 1);
  }
`

export const RobotImage2 = styled.img`
  position: absolute;
  left: 60%;
  top: 40%;
  height: 60%;
  z-index: 2501;

  @media (max-width: 1200px) {
    left: 10px;
    bottom: 0;
    height: 30%;
    top: unset;
  }
`

export const RobotImage3 = styled.img`
  position: absolute;
  left: 17%;
  top: 40%;
  height: 60%;
  z-index: 2501;
`

export const RobotImage4 = styled.img`
  position: absolute;
  left: 60%;
  top: 30%;
  height: 70%;
  z-index: 2501;
`

export const ContinueButton = styled.img`
  z-index: 2501;
  position: relative;
  top: 70%;
  display: block;
  margin: 0 auto;
  width: 200px;
  cursor: pointer;
`
export const ContinueButton2 = styled.div`
  z-index: 2501;
  position: relative;
  top: 47%;
  display: block;
  margin: 0 auto;
  width: 240px;
  cursor: pointer;
`
export const ButtonText = styled.span`
  position: relative;
  z-index: 2501;
  top: -55px;
  left: 75px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
`

export const ButtonText2 = styled.span`
  position: relative;
  z-index: 2501;
  top: -55px;
  left: 100px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
`


export const IntroDiv = styled.div`
  height: 100%;
  width: 100%;
  z-index: 2500;
  position: absolute;
  top: 0;
  left: 0;

  a {
    color: white;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100px;
    text-decoration: none;
  }

  div {
    height: 80%;

    @media (max-width: 1200px) {
      height: unset;
    }
  }

  span {
    position: absolute;
  }

  .Typewriter__wrapper {
    color: white;
    font-size: 18px;
    line-height: 20px;

    @media (max-width: 1200px) {
      font-size: 16px;
    }
  }

  button {
    position: absolute;
    left: calc(50% - 100px);
    top: 50%;
    z-index: 2501;

    @media (max-width: 1200px) {
      left: calc(50% - 63px);
      top: 30%;
    }
  }
`