import styled from 'styled-components';
import {Modal} from 'react-bootstrap';
import welcomeBg from "../../images/welcome.png";

export const ModalStyled = styled(Modal)`
  top: 15vh;
  left: calc(50% - 350px);
  z-index: 3001;
  width: unset !important;
  height: unset;
  overflow: hidden;
  
  @media (max-width: 768px) {
    left: 10px;
  }


  .modal-header__controls {
    position: relative;
    display: flex;

    button {
      position: unset;
      padding: 0 !important;

      img {
        width: 13px;
        height: auto;
      }

      &.btn-minimize {
        margin-right: 10px;

      }

      &.btn-close {
        padding: unset !important;
        margin: unset !important;
        opacity: 1 !important;
      }
    }
  }

  .modal-dialog {
    width: 700px;
    background-image: url(${welcomeBg});
    background-size: 700px 60vh;
    background-repeat: no-repeat;
    z-index: 3001;
    margin: 0;

    @media (max-width: 768px) {
      width: calc(100% - 10px);
    }
  }

  .modal-content {
    height: 80vh;
    z-index: 3001;
    border-radius: 0;
    border: none;
    background-color: transparent;
    filter: drop-shadow(5px 5px 7px rgba(50, 50, 0, 0.5));
  }

  .modal-body {
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: grey rgba(102, 101, 96, 1);
    position: relative;


    ::-webkit-scrollbar {
      width: 10px;
      height: 15px;
    }

    ::-webkit-scrollbar-track-piece {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb:vertical {
      height: 30px;
      background-color: grey;
    }
  }

  .modal-footer {
    //height: 240px;
    position: relative;
    background: transparent;
    padding: 0 0 67px 0;
    height: 330px;

    .transitionGroup {
      height: calc(330px - 67px);
      background: white;
      width: 100%;
      margin: 0;
      position: relative;
    }

    .modal-footer__logos {
      margin: 0 !important;
      width: 100%;
      height: 100%;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      padding: 20px 50px 50px 50px;
      position: absolute;
      left: 0;
      top: 0;

      @media (max-width: 768px) {
        padding: 10px 5px 5px 5px;
      }

      .modal-footer__logo {
        width: calc(20% - 0.4rem);
        margin-right: .5rem;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;

        &:nth-of-type(5n) {
          margin-right: 0;
        }

        @media (max-width: 768px) {
          margin-right: .2rem;
          margin-bottom: 0;
          padding: 0;

          &:nth-of-type(5n) {
            margin-right: .2rem;
          }

          &.s {

            img {
              width: 45px;
            }

          }

          img {
            max-width: 80px;
          }
        }
      }


      &.s {

        img {
          width: 75px;
        }

      }

      img {
        max-width: 100px;
      }
    }

    .modal-footer__main-logo {
      height: 100%;


      img {
        width: auto;
        height: 100%;
        max-width: 100%;
      }

      @media (max-width: 768px) {
        width: 100%;
        height: fit-content;

        img {
          max-width: 100% !important;
        }
      }
    }
  }

  button {
    position: absolute;
    left: calc(50% - 100px);
    bottom: 33px;
    

    @media (max-width: 768px) {
      left: calc(50% - 63px);
      bottom: 45px;
    }
  }
`;

export const ModalHeader = styled(Modal.Header)`
  color: white;
  font-size: 18px;
  border-bottom: 0;
  padding-left: 50px;
  padding-top: 20px;
  font-weight: bold;

  @media (max-width: 768px) {
    padding-left: 15px;
    padding-top: 15px;
  }

`

export const ModalBody = styled(Modal.Body)`
  padding-left: 50px;
  padding-right: 50px;
  color: white;

  @media (max-width: 768px) {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 0;
    font-size: 14px;
  }
`

export const ModalFooter = styled(Modal.Footer)`
  background-color: white;
  padding: 0 30px 30px 30px;
  -webkit-clip-path: polygon(100% 0, 100% 85%, 95% 100%, 0 100%, 0% 0);
  clip-path: polygon(100% 0, 100% 85%, 95% 100%, 0 100%, 0% 0);
  z-index: 3001;
  height: 300px;


  //border-radius: 0;
  //border: none;
  //background-color: transparent;
`

export const Logo = styled.img`
  width: 100%;
`

export const StartButton = styled.div`
  z-index: 4001;
  position: absolute;
  top: 68vh;
  width: 200px;
  left: 35%;
  cursor: pointer;


  span {
    position: absolute;
    top: 20px;
    right: 50px;
    color: white;
    font-weight: bold;
    font-size: 18px;
  }

`