import React, {useEffect, useRef, useState} from 'react';
import {ModalStyled, ModalHeader, ModalBody, ModalFooter} from './MainPopup.styles'
import {useTranslation} from 'react-i18next';
import interregLogo from '../../images/logos/interreg-logo.png'
import logo1 from '../../images/logos/logo1.jpg'
import logo2 from '../../images/logos/logo2.png'
import logo3 from '../../images/logos/logo3.jpg'
import logo4 from '../../images/logos/logo4.png'
import logo5 from '../../images/logos/logo5.png'
import logo6 from '../../images/logos/logo6.jpg'
import logo7 from '../../images/logos/logo7.jpg'
import logo8 from '../../images/logos/logo8.png'
import logo9 from '../../images/logos/logo9.png'
import logo10 from '../../images/logos/logo10.png'
import MainButton from '../UI/MainButton';
import SponsorsIndex from "./SponsorsIndex";
import './MainPopup.styles.css';


const sponsors = [
    {
        "id": 0,
        "images": [interregLogo],
    },
    {
        "id": 1,
        "images": [logo1, logo2, logo3, logo4, logo5, logo6, logo7, logo8, logo9, logo10],
    },
]

const MainPopup = ({handleClose, show}) => {
    const {t} = useTranslation('common');
    const [item, setItem] = useState(sponsors[1]);
    const [mainSponsor, setMainSponsor] = useState(0);
    const timer = useRef(null);

    useEffect(() => {
		const nextItem = () => {
			setMainSponsor(mainSponsor === 0 ? 1 : 0);
			setItem(sponsors[mainSponsor]);
		}
        timer.current = (setInterval(nextItem, 10000));
        return () => {
            clearInterval(timer.current);
        };
    }, [mainSponsor]);

    return (
        <>
            <ModalStyled size="lg" show={show} onHide={handleClose} backdrop={false}>
                {/*<ModalHeader closeButton>*/}
                {/*	<Modal.Title></Modal.Title>*/}
                {/*</ModalHeader>*/}
                <ModalHeader>
                    <span className='modal-label'>{t('welcome-heading')}</span>
                    <div className="modal-header__controls">
                        <button className="btn-close" onClick={handleClose}>
                            <img src="../images/window-close.svg" alt=""/>
                        </button>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div className={'modal-body__content'}>
                        {t('welcome-description')}
                    </div>
                </ModalBody>

                <ModalFooter>
                    <SponsorsIndex sponsor={item}/>

                    <MainButton label={t('main-lets-start').toUpperCase()} size={2} textsize={10}
                                handleClick={handleClose}/>
                </ModalFooter>

            </ModalStyled>
        </>
    )
}

export default MainPopup;