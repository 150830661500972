import {Marker, useMap} from 'react-leaflet'
import * as L from "leaflet";
import useWindowDimensions from '../../hooks'
import _ from "lodash";
import {useEffect, useState} from 'react'
import MarkerClusterGroup from "react-leaflet-markercluster/src/react-leaflet-markercluster";
import "./map.styles.css"


const regularMarker = L.divIcon({
	html: "<div><img src='images/street-view-solid.svg' alt='view'></div>",
	className: 'marker-custom',
	iconAnchor: [12, 36]
});

const selectedMarker = L.divIcon({
	html: "<div><img src='images/street-view-solid-active.svg' alt='view'></div>",
	className: 'marker-custom-active',
	iconAnchor: [12, 36]
});

const MapFunc = ({countries, selectModel, selectedIndex, setSelectedIndex, popupWidth, flyToBounds, endFlyToBounds, 
				markers, setMarkers, mapFlyPosition, setMapFlyPosition, mapOffset, minimized}) => {
	const countriesConstant = countries;
	
	const [minimizedZoom, setMinimizedZoom] = useState(-1);
	const map = useMap();

	map.setMaxZoom(12);
	map.setMinZoom(5);

	const { height, width } = useWindowDimensions();
	
	const openModelPopup = (model, event) => {
		setSelectedIndex(event.target.options.index)
		selectModel(model);
		setMapFlyPosition(event.target._latlng);
	}

	useEffect(() => {
		if (minimized && minimizedZoom === -1) {
			setMinimizedZoom(map.getZoom());
		}
		if (!minimized && minimizedZoom !== -1) {
			map.setZoom(minimizedZoom);
			setMinimizedZoom(-1);
		}
	}, [minimizedZoom, minimized, map])
	
	useEffect(() => {
		if(mapFlyPosition) {
			const pixelPosition = map.latLngToLayerPoint(mapFlyPosition);
			const pixelPositionOffset = [pixelPosition.x + 200, pixelPosition.y];
			const realPosition = map.layerPointToLatLng(pixelPositionOffset);

			if (minimizedZoom !== -1 && !minimized) {
				map.flyTo(realPosition, minimizedZoom);
			} else {
				map.flyTo(realPosition);
			}
		}
	}, [map, mapFlyPosition, minimized, minimizedZoom]);
	
	const getMarkerIcon = (index) => {
		if (index === selectedIndex) {
			return selectedMarker;
		}
		return regularMarker;
	}
	const getMarkerIconTxt = (index) => {
		if (index === selectedIndex) {
			return 'greenIcon';
		}
		return 'blueIcon';
	}

	const createClusterCustomIcon = function (cluster) {
		return L.divIcon({
			className: 'marker-cluster-custom',
			html: `<span>${cluster.getChildCount()}</span>`,
    		iconSize: L.point(40, 40, true),
  		});
	};

	const getZIndexOffset = (index) => {
		if (index === selectedIndex) {
			return 1000;
		}
		return 0;
	}
	let index = 0
	let leftBorder = [0, 1000];
	let rightBorder = [0, 0];
	let topBorder = [0, 0];
	let bottomBorder = [1000, 0];
	let markersPom = [];
	for(let i = 0; i < countriesConstant.length; i++) {
		for(let j = 0; j < countriesConstant[i].models.length; j++) {
			if (countriesConstant[i].models[j].gps[0] !== 1 || countriesConstant[i].models[j].gps[1] !== 1) {
				const markerKey = 'marker_' + i + '_model_' + j;
				if (countriesConstant[i].models[j].gps[1] < leftBorder[1]) {
					leftBorder = countriesConstant[i].models[j].gps;
				}
				if (countriesConstant[i].models[j].gps[1] > rightBorder[1]) {
					rightBorder = countriesConstant[i].models[j].gps;
				}
				if (countriesConstant[i].models[j].gps[0] < bottomBorder[0]) {
					bottomBorder = countriesConstant[i].models[j].gps;
				}
				if (countriesConstant[i].models[j].gps[0] > topBorder[0]) {
					topBorder = countriesConstant[i].models[j].gps;
				}
				markersPom.push(
					<Marker
						eventHandlers={{
							click: (e) => {
								openModelPopup(countriesConstant[i].models[j], e);
							},
						}}
						key={markerKey}
						position={countriesConstant[i].models[j].gps}
						index={index}
						zIndexOffset={getZIndexOffset(index)}
						icon={getMarkerIcon(index)}
						iconTxt={getMarkerIconTxt(index)}
						>
					</Marker>
				);
				index++;
			}
		}
	}
	if (markersPom.length === 0) {
		rightBorder = [45.151739, 28.919299];
		leftBorder = [48.0443, 8.4437];
		topBorder = [49.000568, 20.768331];
		bottomBorder = [41.71537628306118, 25.464237130819647];
	}
	let differenceIcon = [];
	if(markers.length === markersPom.length) {
		for(let i = 0; i < markers.length; i++) {
			if(markers[i].props.iconTxt !== markersPom[i].props.iconTxt) {
				differenceIcon.push(markersPom[i]);
			}
		}
	}
	if(markersPom.length !== markers.length || _.differenceWith(markersPom, markers, 
		(arrVal, othVal) => arrVal.key === othVal.key).length > 0) {
		markers = [];
		for(let marker of markersPom) {
			markers.push(marker);
		}
	}
	else if(differenceIcon.length > 0) {
		markers[differenceIcon[0].props.index] = differenceIcon[0];
	}
	if (flyToBounds) {
		const markerWidth = map.latLngToLayerPoint(rightBorder).x - map.latLngToLayerPoint(leftBorder).x;
		const markerHeight = map.latLngToLayerPoint(bottomBorder).y - map.latLngToLayerPoint(topBorder).y;
		const offset = mapOffset ? (popupWidth + 100) : 0
		const trueWidth = width - offset;
		const scale = Math.min((trueWidth / markerWidth), (height / markerHeight));
		let zoom = Math.floor(map.getScaleZoom(scale));
		if(zoom > 12) {
			zoom = 12;
		}
		const trueScale = map.getZoomScale(zoom, map.getZoom());
		const markerRightPoint = map.latLngToLayerPoint(rightBorder);
		const rightOffset = map.layerPointToLatLng([markerRightPoint.x + offset / trueScale, markerRightPoint.y]);
		let newBounds = new L.LatLngBounds([topBorder[0], rightOffset.lng], [bottomBorder[0], leftBorder[1]]);
		map.flyToBounds(newBounds);
		setTimeout(endFlyToBounds, 2000);
	}
	return (
		<MarkerClusterGroup showCoverageOnHover={false}
							spiderfyDistanceMultiplier={2}
							maxClusterRadius={60}
							iconCreateFunction={createClusterCustomIcon}>
			{[...markers]}
		</MarkerClusterGroup>
	)
}

export default MapFunc;
