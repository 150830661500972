import styled from "styled-components";
import { Form } from 'react-bootstrap'

export const LanguageMenuDiv = styled(Form)`
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 3001;
  display: flex;
  justify-content: center;
  align-items: center;

  .select-container {
    margin-top: -100px;
  }

  .options-container {
    display: flex;
    position: relative;
    justify-content: center;
    z-index: 1;
    margin-top: 50px;
    width: 40vw;

    &__item {
      display: flex;
      flex-direction: column;

      span {
        color: white;
        padding-bottom: 10px;
      }

      label {
        color: #aa9963;
        font-weight: bold;
      }
    }
  }
`;

export const  ButtonsWrapper = styled.div`
	position: absolute;
	z-index: 3001;
	top: 80%;
	left: 50%;
	transform: translate(-50%, 0);
	
	a {
		color: white;
		display: block;
		margin-left: auto;
		margin-right: auto;
		text-decoration: none;
		z-index: 3001;
		position: relative;
		top: 25px;
		text-align: center;
		font-size: 13px;
		font-weight: 500;
	}
`;