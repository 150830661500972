import styled from "styled-components";

export const ButtonsWrapper = styled.div`
  position: absolute;
  z-index: 3001;
  top: 80%;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;

  button {

  }

  a {
    color: white;
    display: block;
    text-decoration: none;
    z-index: 3001;
    position: relative;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    margin-top: 10px;

    &.btn--reset {
      color: #f87171;
    }
  }
`;

export const AdvancedSearchDiv = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2001;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    align-items: flex-start;

    .select-container {
      margin-top: 50px !important;
    }
  }

  .select-container {
    margin-top: -100px;
  }

  .options-container {
    display: flex;
    position: relative;
    justify-content: space-between;
    z-index: 1;
    margin-top: 50px;
    width: 40vw;

    @media (max-width: 768px) {
      width: 100vw;
      flex-wrap: wrap;
      padding: 0 20px;
    }

    &.one {
      justify-content: center;
    }

    &__item {
      display: flex;
      flex-direction: column;
      position: relative;

      @media (max-width: 768px) {
        width: 50%;
        flex-shrink: 0;
      }

      &.timeline {
        padding-left: 100px;

        > {

          &:after {
            content: '';
            width: 1px;
            height: 100%;
            background: #aa9963;
            position: absolute;
            left: 7px;
            top: 20px;
          }

          &:last-of-type {

            &:after {
              display: none;
            }
          }

          &:before {
            position: absolute;
            right: calc(100% + 20px);
            top: 20px;
            color: white;
            font-size: 12px;
            font-weight: 600;
            width: 100px;
            text-align: right;
          }

          &:nth-of-type(1) {

            &:before {
              content: '2 000 000 BC';
            }
          }

          &:nth-of-type(2) {

            &:before {
              content: '8 300 BC';
            }
          }

          &:nth-of-type(3) {

            &:before {
              content: '4 500 BC';
            }
          }

          &:nth-of-type(4) {

            &:before {
              content: '3 300 BC';
            }
          }

          &:nth-of-type(5) {

            &:before {
              content: '1 200 BC';
            }
          }

          &:nth-of-type(6) {

            &:before {
              content: '586 BC';
            }
          }

          &:nth-of-type(7) {

            &:before {
              content: '324 AC';
            }
          }
        }
      }


      span {
        color: white;
        padding-bottom: 10px;
      }

      label {
        color: #aa9963;
        font-weight: bold;

        @media (max-width: 768px) {
          font-size: 12px;
        }
      }
    }
  }
`;
