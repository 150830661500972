import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from 'react';
import ar2 from '../../images/arrows/ar-2.svg';
import ar3 from '../../images/arrows/ar-3.svg';
import ar4 from '../../images/arrows/ar-4.svg';
import darkScreen from '../../images/dark-screen.png';
import Typewriter from 'typewriter-effect';
import MainButton from "../UI/MainButton";
import ModelPopup from "../ModelPopup";
import Checkbox from "../UI/Checkbox";
import {Arrow, CheckboxButtons, IntroBackground, IntroDiv, Message,} from "./Tour.styles";

const messagePositions = [
    {top: 50, left: 43},
    {top: 35, left: 35},
    {top: 35, left: 80},
    {top: 58, left: 22},
    {top: 35, left: 32},
];

const arrowPositions = [
    {},
    {top: 10, left: 50},
    {top: 10, left: 3},
    {top: 58, left: 80},
    {top: 35, left: 40},
];

const maxStep = 4;

const steps = [0, 1, 2, 3, 4];

const Tour = ({show, countries, closeTour, currentTourStep, setCurrentStep}) => {

    const {t} = useTranslation('common');
    const [current_message, setCurrentMessage] = useState(t('tour-robot-message1'));
    const [curr_msg_position, setCurrMsgPosition] = useState(messagePositions[currentTourStep]);
    const [curr_arrow_position, setCurrArrowPosition] = useState(arrowPositions[currentTourStep]);

    useEffect(() => {
        setCurrMsgPosition(messagePositions[currentTourStep]);
        setCurrArrowPosition(arrowPositions[currentTourStep]);

        switch (currentTourStep) {
            case 0:
                setCurrentMessage(t('tour-robot-message1'));
                break;
            case 1:
                setCurrentMessage(t('tour-robot-message2'));
                break;
            case 2:
                setCurrentMessage(t('tour-robot-message3'));
                break;
            case 3:
                setCurrentMessage(t('tour-robot-message4'));
                break;
            case 4:
                setCurrentMessage(t('tour-robot-message5'));
                break;
            default:
                break;
        }
    }, [currentTourStep, t]);

    if (show) {
        return (
            <IntroDiv>
                <Message topValue={curr_msg_position?.top} leftValue={curr_msg_position?.left} className={'tour-msg'}>
                    <Typewriter
                        options={{
                            strings: current_message,
                            autoStart: true,
                            cursor: "",
                            deleteSpeed: "0",
                            delay: 70
                        }}/>
                </Message>


                {currentTourStep === 1 && (
                    <Arrow src={ar2} show={currentTourStep > 0}
                           topValue={curr_arrow_position?.top} rightValue={curr_arrow_position?.left}/>
                )
                }

                {currentTourStep === 2 && (
                    <Arrow src={ar2} show={currentTourStep > 0}
                           topValue={curr_arrow_position?.top} rightValue={curr_arrow_position?.left}/>
                )
                }

                {currentTourStep === 3 && (
                    <Arrow src={ar3} show={currentTourStep > 0}
                           topValue={curr_arrow_position?.top} rightValue={curr_arrow_position?.left}/>
                )
                }

                {currentTourStep === 4 && (
                    <Arrow src={ar4} show={currentTourStep > 0}
                           topValue={curr_arrow_position?.top} rightValue={curr_arrow_position?.left}/>
                )
                }

                {currentTourStep === 4 && (
                    <ModelPopup show={true} model={countries[1].models[17]} handleClose={() => {
                    }} tutorial={true}
                                minimize={() => {
                                }} openCallback={() => {
                    }} openGallery={() => {
                    }} setModel3dUrl={() => {
                    }}
                                show3dModel={() => {
                                }} setModelPopupSource={() => {
                    }}
                    />
                )}

                <IntroBackground src={darkScreen} alt="dark-screen"/>
                {/* Button group to change current step*/}
                <CheckboxButtons>
                    <div className='select-container'>
                        <div className="options-container">
                            <div className='options-container__item'>
                                {steps.map(index =>
                                    <Checkbox key={'radio' + index} type='radio' id={index}
                                              checked={index === currentTourStep}
                                              name='country' handleChange={() => {
                                        setCurrentStep(index)
                                    }}/>
                                )}
                            </div>
                        </div>
                    </div>
                </CheckboxButtons>
                <MainButton label={t('intro-continue').toUpperCase()} show={currentTourStep < maxStep} size={2}
                            textsize={10} handleClick={() => setCurrentStep(currentTourStep + 1)}/>
                <MainButton label={t('main-lets-start').toUpperCase()} show={currentTourStep === maxStep} size={2}
                            textsize={10} handleClick={closeTour}/>
            </IntroDiv>
        )
    } else {
        return "";
    }

}

export default Tour;
