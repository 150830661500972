import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {containsClass} from './utils/classNames';
import './Image.css'
import Plyr from 'plyr-react'
import 'plyr-react/dist/plyr.css'

export default class VideoContent extends React.Component {
    constructor(props) {
        super(props);
        // this.handleWindowResize = this.handleWindowResize.bind(this);
        this.handleMove = this.handleMove.bind(this);
        this.handleMoveEnd = this.handleMoveEnd.bind(this);
        this.handleMoveStart = this.handleMoveStart.bind(this);
        this.isInsideImage = this.isInsideImage.bind(this);
        this.state = {
            loader: true,
            ratio: 1,
            positionX: 0,
            positionY: 0,
            rotate: 0,
            width: 0,
            height: 0,
            boxWidth: 0,
            boxHeight: 0
        };
        this.videoSrc = {
            type: "video",
            sources: [
                {
                    src: props.url,
                    provider: props.provider
                },
                // {
                //     src: '/path/to/movie.mp4',
                //     type: 'video/mp4',
                //     size: 720,
                // },
            ]
        };
    }

    componentDidMount() {
        this.startPoints = null;
        document.addEventListener('mousedown', this.handleMoveStart);
        document.addEventListener('mouseup', this.handleMoveEnd);
        document.addEventListener('touchstart', this.handleMoveStart);
        document.addEventListener('touchend', this.handleMoveEnd);
        window.setTimeout(this.props.toggleControls, 500);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleMoveStart);
        document.removeEventListener('mouseup', this.handleMoveEnd);
        document.removeEventListener('touchstart', this.handleMoveStart);
        document.removeEventListener('touchend', this.handleMoveEnd);
    }

    handleMove(ev) {
        ev = this.getEv(ev);
        let state = this.state;
        if (!state.moving)
            return;
        let posX, posY;
        switch (state.rotate) {
            case 90:
                posY = this.startPoints[0] - ev.pageX;
                posX = ev.pageY - this.startPoints[1];
                break;
            case 180:
                posX = this.startPoints[0] - ev.pageX
                posY = this.startPoints[1] - ev.pageY;
                break;
            case 270:
                posY = ev.pageX - this.startPoints[0];
                posX = this.startPoints[1] - ev.pageY;
                break;
            default:
                posX = ev.pageX - this.startPoints[0];
                posY = ev.pageY - this.startPoints[1];
        }
        this.startPoints = [ev.pageX, ev.pageY]

        if (state.positionX + posX >= 0 || state.positionX + posX <= (state.boxWidth - state.width * state.ratio))
            posX = 0;
        if (state.positionY + posY >= 0 || state.positionY + posY <= (state.boxHeight - state.height * state.ratio))
            posY = 0;

        this.setState({
            positionX: state.positionX + posX,
            positionY: state.positionY + posY
        });
    }

    handleMoveEnd(ev) {
        this.setState({
            moving: false
        })
    }

    handleMoveStart(ev) {
        ev = this.getEv(ev);
        if (!this.isInsideImage(ev) || ev.which !== 1)
            return;
        this.startPoints = [ev.pageX, ev.pageY];
        this.setState({
            moving: true
        })
        const _this = this;

        // check if touch was a tap
        window.setTimeout(function () {
            if (!_this.state.moving && _this.startPoints
                && _this.startPoints[0] === ev.pageX
                && _this.startPoints[1] === ev.pageY
                && containsClass(ev.target, ['lightbox-backdrop', 'lightbox-image'])) {
                _this.props.toggleControls();
            }
        }, 200);
    }

    isInsideImage(ev) {
        let rect = ReactDOM.findDOMNode(this.refs.container).getBoundingClientRect();
        if (ev.pageY < rect.top || ev.pageY > rect.bottom || ev.pageX < rect.left || ev.pageX > rect.right)
            return false;
        return true;
    }

    getEv(ev) {
        if (ev.type === 'touchstart' || ev.type === 'touchmove' || ev.type === 'touchend')
            return {pageX: ev.touches[0].pageX, pageY: ev.touches[0].pageY, which: 1, target: ev.target}
        return ev
    }

    render() {
        return (
            <div className='lightbox-content-center'>
                <div className='lightbox-image-container' ref='container'>
                    <Plyr source={this.videoSrc}/>

                </div>
            </div>
        )
    }
}

Image.propTypes = {
    src: PropTypes.string.isRequired,
    showImageModifiers: PropTypes.bool.isRequired
}
