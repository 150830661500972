import React from 'react';
import PropTypes from 'prop-types';
import Container from './Container';
import BodyPortal from './BodyPortal';
import './Gallery.css'

export default class Gallery extends React.Component {
	constructor(props) {
		super(props);
		this.toggleLightbox = this.toggleLightbox.bind(this);
		this.state = {
			showLightbox: props.toggle,
			selectedImage: props.selectedImage
		};
	}

toggleLightbox() {
	this.setState({
		showLightbox: false,
		selectedImage: 0
	});
	this.props.hideGalleryPopup();
}
  
componentDidUpdate(nextProps) {
	if (nextProps.toggle !== this.state.showLightbox) {
		this.setState({ showLightbox: nextProps.toggle });
	}
	if (nextProps.selectedImage !== this.state.selectedImage) {
		this.setState({ selectedImage: nextProps.selectedImage });
	}
}

	render() {
		let container;
		if (this.state.showLightbox)
			container = (
			<BodyPortal>
				<Container
					{...this.props}
					toggleLightbox={this.toggleLightbox}
					selectedImage={this.state.selectedImage}
				/>
			</BodyPortal>
			)

		return (
			<div className='lightbox-container'>
			{container}
			</div>
		)
	}
}

Gallery.propTypes = {
	items: PropTypes.arrayOf(PropTypes.shape({
		src: PropTypes.string,
		url: PropTypes.string,
		title: PropTypes.string,
		description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
		thumbnail: PropTypes.string
	})).isRequired,
	showImageModifiers: PropTypes.bool,
	thumbnailWidth: PropTypes.string,
	thumbnailHeight: PropTypes.string,
	renderImageFunc: PropTypes.func,
	renderDescriptionFunc: PropTypes.func
}