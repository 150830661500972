import React from "react";
import {AdvancedSearchDiv, ButtonsWrapper} from "./AdvancedSearch.styles";
import {useTranslation} from "react-i18next";
import Checkbox from "../UI/Checkbox";
import MenuTitle from "../UI/MenuTitle";
import MainButton from "../UI/MainButton";
import MenuBackground from "../UI/MenuBackground";
import cloneDeep from 'lodash/cloneDeep';

const searchObjectsCountry = [
    {
        id: "countries-austria-name",
        label: "countries-austria-name"
    },
    {
        id: "countries-bulgaria-name",
        label: "countries-bulgaria-name"
    },
    {
        id: "countries-croatia-name",
        label: "countries-croatia-name"
    },
    /*{
        id: "countries-czechrepublic-name",
        label: "countries-czechrepublic-name"
    },*/
    {
        id: "countries-germany-name",
        label: "countries-germany-name"
    },
    {
        id: "countries-hungary-name",
        label: "countries-hungary-name"
    },
    {
        id: "countries-romania-name",
        label: "countries-romania-name"
    },
    {
        id: "countries-serbia-name",
        label: "countries-serbia-name"
    },
    {
        id: "countries-slovakia-name",
        label: "countries-slovakia-name"
    },
    {
        id: "countries-slovenia-name",
        label: "countries-slovenia-name"
    }
]

const searchObjectsAge = [
    {
        id: "period-paleolithic-name",
        label: "period-paleolithic-name"
    },
    {
        id: "period-neolithic-name",
        label: "period-neolithic-name"
    },
    {
        id: "period-copperAge-name",
        label: "period-copperAge-name"
    },
    {
        id: "period-bronzeAge-name",
        label: "period-bronzeAge-name"
    },
    {
        id: "period-ironAge-name",
        label: "period-ironAge-name"
    },
    {
        id: "period-romanPeriod-name",
        label: "period-romanPeriod-name"
    },
    {
        id: "period-lateAntiquity-name",
        label: "period-lateAntiquity-name"
    },
    {
        id: "period-middleAge-name",
        label: "period-middleAge-name"
    }
]

const searchObjects3d = [
    {
        id: "model-3D",
        label: "search-yes"
    },
    {
        id: "model-3D-no",
        label: "search-no"
    }
]

const countriesArr = [
    "countries-austria-name", "countries-slovakia-name", "countries-slovenia-name", "countries-bulgaria-name", "countries-croatia-name",
    "countries-germany-name", "countries-hungary-name", "countries-romania-name", "countries-serbia-name"
]

const periodsArr = [
    "period-paleolithic-name", "period-neolithic-name", "period-copperAge-name", "period-bronzeAge-name", "period-ironAge-name", "period-romanPeriod-name",
    "period-lateAntiquity-name", "period-middleAge-name"
]

const AdvancedSearch = ({closeSearch, showAll, showLocation, showPeriod, applySearch}) => {
    const [filterObjectLocation, setFilterObjectLocation] = React.useState({});
    const [filterObjectPeriod, setFilterObjectPeriod] = React.useState({});
    const [filterObjectAll, setFilterObjectAll] = React.useState({});

    const [filterObjectLocationApply, setFilterObjectLocationApply] = React.useState({});
    const [filterObjectPeriodApply, setFilterObjectPeriodApply] = React.useState({});
    const [filterObjectAllApply, setFilterObjectAllApply] = React.useState({});

    const {t} = useTranslation('common');

    const search = () => {
        if (showAll) {
            setFilterObjectAllApply(cloneDeep(filterObjectAll));
            applySearch(filterObjectAll);
        } else if (showLocation) {
            setFilterObjectLocationApply(cloneDeep(filterObjectLocation));
            applySearch(filterObjectLocation);
        } else if (showPeriod) {
            setFilterObjectPeriodApply(cloneDeep(filterObjectPeriod));
            applySearch(filterObjectPeriod);
        }
    }

    const handleChangeMultiple = (e) => {
        setFilterObjectAll(fo => Object.assign({}, fo, {[e.target.id]: e.target.checked}));
    }

    const handleChange = (e) => {
        const chosenObj = {};
        chosenObj[e.target.id] = e.target.checked;
        if (showLocation) {
            setFilterObjectLocation(chosenObj);
        } else {
            setFilterObjectPeriod(chosenObj);
        }
    }

    const close = () => {
        setFilterObjectAll(cloneDeep(filterObjectAllApply));
        setFilterObjectLocation(cloneDeep(filterObjectLocationApply));
        setFilterObjectPeriod(cloneDeep(filterObjectPeriodApply));
        closeSearch();
    }

    const resetFilter = () => {
        setFilterObjectAllApply([]);
        setFilterObjectLocationApply([]);
        setFilterObjectPeriodApply([]);
        setFilterObjectAll([]);
        setFilterObjectLocation([]);
        setFilterObjectPeriod([]);
        applySearch([]);
    }

    if (showAll) {
        return (
            <AdvancedSearchDiv>
                <MenuBackground type={2}/>
                <div className='select-container'>
                    <MenuTitle title={t('search-title').toUpperCase()}/>
                    <div className="options-container">
                        <div className='options-container__item'>
                            <span>{t('search-location')}</span>
                            {searchObjectsCountry.map((option, i) => {
                                if (option.id === 'empty') {
                                    return <div key={'checkbox' + i}></div>
                                } else {
                                    return <Checkbox key={'checkbox' + i} type='checkbox' id={option.id}
                                                     checked={filterObjectAll[option.id] ? true : false}
                                                     label={t(option.label)} handleChange={handleChangeMultiple}/>
                                }
                            })}
                        </div>
                        <div className='options-container__item'>
                            <span>{t('search-period')}</span>
                            {searchObjectsAge.map((option, i) => {
                                if (option.id === 'empty') {
                                    return <div key={'checkbox' + i}></div>
                                } else {
                                    return <Checkbox key={'checkbox' + i} type='checkbox' id={option.id}
                                                     checked={filterObjectAll[option.id] ? true : false}
                                                     label={t(option.label)} handleChange={handleChangeMultiple}/>
                                }
                            })}
                        </div>
                        <div className='options-container__item'>
                            <span>{t('search-3D')}</span>
                            {searchObjects3d.map((option, i) => {
                                if (option.id === 'empty') {
                                    return <div key={'checkbox' + i}></div>
                                } else {
                                    return <Checkbox key={'checkbox' + i} type='checkbox' id={option.id}
                                                     checked={filterObjectAll[option.id] ? true : false}
                                                     label={t(option.label)} handleChange={handleChangeMultiple}/>
                                }
                            })}
                        </div>
                    </div>
                </div>
                <ButtonsWrapper>
                    <MainButton label={t('search-name').toUpperCase()} size={2} handleClick={search}/>
                    <a className={'btn--reset'} onClick={resetFilter} href='/#'>{t('reset-filter').toUpperCase()}</a>
                    <a href='/#' onClick={close}>{t('main-close').toUpperCase()}</a>
                </ButtonsWrapper>
            </AdvancedSearchDiv>
        )
    } else if (showLocation) {
        return (
            <AdvancedSearchDiv>
                <MenuBackground type={1}/>
                <div className='select-container'>
                    <MenuTitle title={t('main-filter-country').toUpperCase()}/>
                    <div className="options-container one">
                        <div className='options-container__item'>
                            {countriesArr.map((country, i) => {
                                return <Checkbox key={'radio' + i} type='radio' id={country} label={t(country)}
                                                 checked={filterObjectLocation[country] ? true : false}
                                                 name='country' handleChange={handleChange}/>
                            })}
                        </div>
                    </div>
                </div>
                <ButtonsWrapper>
                    <MainButton label={t('search-name').toUpperCase()} size={2} handleClick={search}/>
                    <a className={'btn--reset'} onClick={resetFilter} href='/#'>{t('reset-filter').toUpperCase()}</a>
                    <a href='/#' onClick={close}>{t('main-close').toUpperCase()}</a>
                </ButtonsWrapper>
            </AdvancedSearchDiv>
        )
    } else if (showPeriod) {
        return (
            <AdvancedSearchDiv>
                <MenuBackground type={2}/>
                <div className='select-container'>
                    <MenuTitle title={t('main-filter-period').toUpperCase()}/>
                    <div className="options-container one">
                        <div className='options-container__item timeline'>
                            {periodsArr.map((period, i) => {
                                return <Checkbox key={'radio' + i} type='radio' id={period} label={t(period)}
                                                 name='country'
                                                 checked={filterObjectPeriod[period] ? true : false}
                                                 handleChange={handleChange}/>
                            })}

                        </div>
                    </div>
                </div>
                <ButtonsWrapper>
                    <MainButton label={t('search-name').toUpperCase()} size={2} handleClick={search}/>
                    <a className={'btn--reset'} onClick={resetFilter} href='/#'>{t('reset-filter').toUpperCase()}</a>
                    <a href='/#' onClick={close}>{t('main-close').toUpperCase()}</a>
                </ButtonsWrapper>
            </AdvancedSearchDiv>
        )
    } else {
        return "";
    }
}


export default AdvancedSearch;