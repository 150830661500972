import Map from './components/Map/Map'
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header'
import CountryPopup from './components/CountryPopup';
import React, {useState} from 'react'
import {countries, epochs} from './constants';
import {GlobalStyle} from './GlobalStyle';
import Robot from './components/Robot';
import MainPopup from './components/MainPopup';
import QuizPopup from "./components/QuizPopup";
import AdvancedSearch from "./components/AdvancedSearch";
import LanguageMenu from './components/LanguageMenu';
import ModelsPopup from "./components/ModelsPopup";
import Intro from "./components/Intro";
import Tour from "./components/Tour";
import ModelPopup from "./components/ModelPopup";
import MinimizedPopup from "./components/MinimizedPopup/MinimizedPopup";
import EpochPopup from "./components/EpochPopup";
import Gallery from './components/Gallery';
import cloneDeep from 'lodash/cloneDeep';
import AtonComponent from './components/Aton';
import i18next from "i18next";
import ActivityDetector from 'react-activity-detector';
import IdleScreen from "./components/IdleScreen";
import toast, {Toaster} from 'react-hot-toast';
import {useTranslation} from 'react-i18next';

const App = () => {
    const [country_popup_show, setCountryPopupShow] = useState(false);
    const [epoch_popup_show, setEpochPopupShow] = useState(false);
    const [quiz_popup_show, setQuizPopupShow] = useState(false);
    const [main_popup_show, setMainPopupShow] = useState(false);
    const [advanced_search_show, setAdvancedSearchShow] = useState(false);
    const [lang_menu_show, setLangMenuShow] = useState(false);
    const [country_search_show, setCountrySearchShow] = useState(false);
    const [epoch_search_show, setEpochSearchShow] = useState(false);
    const [current_country, setCurrentCountry] = useState(0);
    const [selected_epoch, setSelectedEpoch] = useState({});
    const [curr_lang, setCurrentLang] = useState(i18next.languages[0]);
    const [model_popup_show, setModelPopupShow] = useState(false);
    const [models_popup_show, setModelsPopupShow] = useState(false);
    const [models, setModels] = useState([]);
    const [model, setModel] = useState({});
    const [minimizedModel, setMinimizedModel] = useState({});
    const [show_intro, setShowIntro] = useState(true);
    const [show_tour, setShowTour] = useState(false);
    const [toggle_gallery, setToggleGallery] = useState(false);
    const [show_minimized_popup, setShowMinimizedPopup] = useState(false);
    const [model_popup_source, setModelPopupSource] = useState(undefined);
    const [minimized_name, setMinimizedName] = useState("");
    const [items, setGalleryItems] = useState([]);
    const [selected_image, setSelectedImage] = useState(0);
    const [currentStep, setCurrentStep] = useState(0);
    const [currentTourStep, setCurrentTourStep] = useState(0);
    const [openMinimizedCallback, setOpenMinimizedCallback] = useState(() => {
    });
    const [selected_countries, setSelectedCountries] = useState(countries);
    const [show_3d_model, setShow3dModel] = useState(false);
    const [model_3d_url, setModel3dUrl] = useState('');
    const [selectedMarkerIndex, setSelectedMarkerIndex] = useState(-1);
    const [minimizedMarkerIndex, setMinimizedMarkerIndex] = useState(-1);
    const [popupWidth, setPopupWidth] = useState(0);
    const [flyToBounds, setFlyToBounds] = useState(false);
    const [markers, setMarkers] = useState([]);
    const [mapFlyPosition, setMapFlyPosition] = useState(undefined);
    const [mapOffset, setMapOffset] = useState(false);
    const [minimizedZoom, setMinimizedZoom] = useState(false);
    const [isIdle, setIsIdle] = useState(false);
    const idleSeconds = 300; //5 minutes
	const {t} = useTranslation('common');

    const onIdle = () => {
        setIsIdle(true);
    }

    const onActive = () => {
        setIsIdle(false);
    }

    const countriesConstant = countries;

    const minimize = (closeActualPopup, openCallback, minimizedName) => {
        setShowMinimizedPopup(true);
        setMinimizedModel(model);
        setMinimizedZoom(true);
        closeActualPopup();
        setOpenMinimizedCallback(() => openCallback);
        setMinimizedName(minimizedName);
        setMinimizedMarkerIndex(selectedMarkerIndex);
    }

    const maximize = () => {
        setModel(minimizedModel);
        setMinimizedZoom(false);
        openMinimizedCallback();
        if (minimizedMarkerIndex !== -1) {
            setSelectedMarkerIndex(minimizedMarkerIndex);
            setMapFlyPosition(markers[minimizedMarkerIndex].props.position);
        }
        //Need to fly to desired site
        setFlyToBounds(true);
        setFlyToBounds(false);
        //Reset all values for minimization
        setMinimizedMarkerIndex(-1);
        setMinimizedModel({});
    }

    const hideMinimizedPopup = () => {
        setShowMinimizedPopup(false);
    }

    const applySearch = (filterObjectOrig) => {
        let selectedCountries = [], selectedModels = [];
        let filterObject = [];
        for (const property in filterObjectOrig) {
            filterObject[property] = filterObjectOrig[property];
        }
        let filter_model_3d = false;
        let filter_model_3d_no = false;
        for (const property in filterObject) {
            if (filterObject[property]) {
                let filtered = countriesConstant.filter(value => {
                    let equals = value.name === property;
                    if (equals) {
                        delete filterObject[property];
                    }
                    return equals;
                });
                selectedCountries.push(...filtered);
                if (property === 'model-3D') {
                    filter_model_3d = true;
                    delete filterObject[property];
                } else if (property === 'model-3D-no') {
                    filter_model_3d_no = true;
                    delete filterObject[property];
                }
            } else {
                delete filterObject[property];
            }
        }

        if (!Object.keys(filterObject).length && !selectedCountries.length && !filter_model_3d && !filter_model_3d_no) {
            setAdvancedSearchShow(false);
            setCountrySearchShow(false);
            setEpochSearchShow(false);
            setSelectedCountries(countries);
            setFlyToBounds(true);
            setMapOffset(false);
            return;
        }

        filterCountries(selectedCountries);
        if (selectedCountries.length === 1 && country_search_show) {
            setCurrentCountry(selectedCountries[0].id);
            showCountryPopup();
            setCountrySearchShow(false);
            setFlyToBounds(true);
            setMapOffset(true);
            return;
        }

        if (selectedCountries.length) {
            // bola vybrana krajina, skontrolujeme ci boli aj dalsie kriteria
            let countriesModels = [];

            if (Object.keys(filterObject).length) {
                let countriesPom = selectedCountries;
                selectedCountries = [];
                for (const property in filterObject) {
                    // eslint-disable-next-line no-loop-func
                    countriesPom.forEach(country => {
                        let filtered = country.models.filter(model => {
                            if (filter_model_3d && filter_model_3d_no) {
                                return model.epoch === property;
                            } else if (filter_model_3d) {
                                return model.epoch === property && model.models_3d;
                            } else if (filter_model_3d_no) {
                                return model.epoch === property && !model.models_3d;
                            }
                            return model.epoch === property;
                        });
                        selectedModels.push(...filtered);
                        const clonedCountry = cloneDeep(country);
                        clonedCountry.models = filtered;
                        selectedCountries.push(clonedCountry);
                    });
                }
                filterCountries(selectedCountries);
                setModels(selectedModels);
            } else if (filter_model_3d || filter_model_3d_no) {
                let countriesPom = selectedCountries;
                selectedCountries = [];
                countriesPom.forEach(country => {
                    let filtered = country.models.filter(model => {
                        if (filter_model_3d && filter_model_3d_no) {
                            return true;
                        } else if (filter_model_3d) {
                            return model.models_3d;
                        }
                        return !model.models_3d;
                    });
                    selectedModels.push(...filtered);
                    const clonedCountry = cloneDeep(country);
                    clonedCountry.models = filtered;
                    selectedCountries.push(clonedCountry);
                });
                filterCountries(selectedCountries);
                setModels(selectedModels);
            } else {
                selectedCountries.forEach(country => {
                    countriesModels.push(...country.models);
                });
                setModels(countriesModels);
            }
        } else {
            if (Object.keys(filterObject).length) {
                for (const property in filterObject) {
                    countriesConstant.forEach(country => {
                        let filtered = country.models.filter(model => {
                            if (filter_model_3d && filter_model_3d_no) {
                                return model.epoch === property;
                            } else if (filter_model_3d) {
                                return model.epoch === property && model.models_3d;
                            } else if (filter_model_3d_no) {
                                return model.epoch === property && !model.models_3d;
                            }
                            return model.epoch === property;
                        });
                        selectedModels.push(...filtered);
                        const clonedCountry = cloneDeep(country);
                        clonedCountry.models = filtered;
                        selectedCountries.push(clonedCountry);
                    });
                }
                if (epoch_search_show) {
                    const selectedEpochName = Object.keys(filterObject)[0];
                    const epoch = epochs.find((item) => {
                        return item.name === selectedEpochName;
                    });
                    setModels(selectedModels);
                    setSelectedEpoch(epoch);
                    setAdvancedSearchShow(false);
                    setCountrySearchShow(false);
                    setEpochSearchShow(false);
                    showEpochPopup();
                    setFlyToBounds(true);
                    setMapOffset(true);
                    return;
                } else {
                    setModels(selectedModels);
                }
            } else if (filter_model_3d || filter_model_3d_no) {
                countriesConstant.forEach(country => {
                    let filtered = country.models.filter(model => {
                        if (filter_model_3d && filter_model_3d_no) {
                            return true;
                        } else if (filter_model_3d) {
                            return model.models_3d;
                        }
                        return !model.models_3d;
                    });
                    selectedModels.push(...filtered);
                    const clonedCountry = cloneDeep(country);
                    clonedCountry.models = filtered;
                    selectedCountries.push(clonedCountry);
                });
                setModels(selectedModels);
            } else {
                setModels(selectedModels);
            }
        }
        setAdvancedSearchShow(false);
        setCountrySearchShow(false);
        setEpochSearchShow(false);
        setFlyToBounds(true);
		if (searchIsEmpty(selectedCountries)) {
			setMapOffset(false);
			showEmptyResultMessage();
		} else {
			showModelsPopup();
        	setMapOffset(true);
		}
    }
	
	const searchIsEmpty = (selectedCountries) => {
		let empty = true;
		for (const country of selectedCountries) {
			if (country.models.length) {
				empty = false;
				break;
			}
		}
		return empty;
	}
	
	const showEmptyResultMessage = () => {
		toast.error(t('search_empty_result'));
	}

    const closeSearch = () => {
        setAdvancedSearchShow(false);
        setCountrySearchShow(false);
        setEpochSearchShow(false);
    }

    const closeIntro = () => {
        setShowIntro(false);
        setMainPopupShow(true);
    }

    const closeTour = () => {
        setShowTour(false);
        setCurrentTourStep(0);
    }

    const refreshIntro = () => {
        hideAllPopups();
        setCurrentStep(0);
        setShowIntro(true);
        setMainPopupShow(false);
    }

    const refreshTour = () => {
        hideAllPopups();
        setCurrentTourStep(0);
        setShowTour(true);
    }

    const showAdvancedSearch = () => {
        hideAllPopups();
        setAdvancedSearchShow(true);
    }

    const showCountrySearch = () => {
        hideAllPopups();
        setCountrySearchShow(true);
    }

    const showEpochSearch = () => {
        hideAllPopups();
        setEpochSearchShow(true);
    }

    const showCountryPopup = () => {
        setModelPopupSource('country');
        setCountryPopupShow(true);
    }
    const showQuizPopup = () => {
        hideAllPopups();
        setQuizPopupShow(true);
    }
    // const showTour = () => {
    // 	setShowTour(true);
    // }
    const showMainPopup = () => {
        hideAllPopups();
        setMainPopupShow(true);
    }
    const showLanguageMenu = () => {
        hideAllPopups();
        setLangMenuShow(true);
    }
    // const changeCountry = index => {
    // 	setCurrentCountry(index)
    // }
    const hideCountryPopup = () => {
        setCountryPopupShow(false);
    }
    const hideEpochPopup = () => {
        setEpochPopupShow(false);
    }
    const showEpochPopup = () => {
        setModelPopupSource('epoch');
        setEpochPopupShow(true);
    }
    const hideMainPopup = () => {
        setMainPopupShow(false);
    }
    const hideQuizPopup = () => {
        setQuizPopupShow(false);
    }
    const hideLanguageMenu = () => {
        setLangMenuShow(false);
    }
    const updateLanguage = (lang) => {
        setCurrentLang(lang);
        localStorage.setItem("lang", lang);
    }
    const hideModelsPopup = () => {
        setModelsPopupShow(false);
    }
    const showModelsPopup = () => {
        setModelPopupSource('models');
        setModelsPopupShow(true);
    }
    const showModelPopup = () => {
        setModelPopupShow(true);
    }
    const openGalleryPopup = (items, index) => {
        setGalleryItems(items);
        setSelectedImage(index);
        setToggleGallery(true);
    }
    const hideGalleryPopup = () => {
        setToggleGallery(false);
    }
    const hideModelPopup = () => {
        if (model_popup_source === 'country') {
            showCountryPopup();
        } else if (model_popup_source === 'epoch') {
            showEpochPopup();
        } else if (model_popup_source === 'models') {
            showModelsPopup();
        }
        setModelPopupSource(null);
        setModelPopupShow(false);
        setSelectedMarkerIndex(-1);
    }
    const selectModel = (model, source) => {
        hideAllPopups();
        setModel(model);
        setModelPopupShow(true);
        if (source) {
            setModelPopupSource(source);
        }
    }
    const filterCountries = (countries) => {
        setSelectedCountries(countries)
    }

    const endFlyToBounds = () => {
        setFlyToBounds(false);
    }

    const hideAllPopups = () => {
        hideCountryPopup();
        hideEpochPopup();
        hideModelsPopup();
        hideQuizPopup();
        hideGalleryPopup();
        hideMainPopup();
        setModelPopupShow(false);
        //Breaks marker selection
        //setSelectedMarkerIndex(-1);
    }

    return (
        <div className="App">
			<Toaster/>
            <ActivityDetector enabled={true} timeout={idleSeconds * 1000} onIdle={onIdle}/>
            <GlobalStyle/>
            {
                isIdle ?
                    <IdleScreen handleClose={onActive}/>
                    :
                    <>
                        <AtonComponent show={show_3d_model} setShow={setShow3dModel} url={model_3d_url}/>
                        {
                            toggle_gallery && (
                                <Gallery items={items} toggle={toggle_gallery} showImageModifiers={false}
                                         hideGalleryPopup={hideGalleryPopup}
                                         selectedImage={selected_image}/>
                            )
                        }

                        <Header showAdvancedSearch={showAdvancedSearch} showCountrySearch={showCountrySearch}
                                showEpochSearch={showEpochSearch}
                                showLanguageMenu={showLanguageMenu} showIntro={refreshIntro} curr_lang={curr_lang}
                                currentTourStep={currentTourStep}
                                showMainPopup={showMainPopup} intro={show_intro}/>
                        <CountryPopup show={country_popup_show} title={countries[current_country].name}
                                      content={countries[current_country].content}
                                      country={countries[current_country]}
                                      handleClose={hideCountryPopup}
                                      selectModel={selectModel}
                                      minimize={minimize}
                                      openCallback={showCountryPopup}
                                      setPopupWidth={setPopupWidth}
                                      markers={markers}
                                      setSelectedMarkerIndex={setSelectedMarkerIndex}
                                      setMapFlyPosition={setMapFlyPosition}
                                      setModelPopupSource={setModelPopupSource}/>
                        <EpochPopup show={epoch_popup_show}
                                    epoch={selected_epoch}
                                    handleClose={hideEpochPopup}
                                    selectModel={selectModel}
                                    models={models}
                                    minimize={minimize}
                                    openCallback={showEpochPopup}
                                    setPopupWidth={setPopupWidth}
                                    markers={markers}
                                    setSelectedMarkerIndex={setSelectedMarkerIndex}
                                    setMapFlyPosition={setMapFlyPosition}
                                    setModelPopupSource={setModelPopupSource}/>
                        <MainPopup show={main_popup_show} handleClose={hideMainPopup}/>
                        <Map countries={selected_countries} selectModel={selectModel}
                             selectedIndex={selectedMarkerIndex}
                             setSelectedIndex={setSelectedMarkerIndex} popupWidth={popupWidth} flyToBounds={flyToBounds}
                             endFlyToBounds={endFlyToBounds} markers={markers} setMarkers={setMarkers}
                             mapFlyPosition={mapFlyPosition}
                             setMapFlyPosition={setMapFlyPosition} mapOffset={mapOffset} minimizedZoom={minimizedZoom}
                             minimize={minimize} setMinimizedZoom={setMinimizedZoom}/>
                        <QuizPopup show={quiz_popup_show} handleClose={hideQuizPopup} model={model} minimize={minimize}
                                   openCallback={showQuizPopup} openGallery={openGalleryPopup}
                                   setModelPopupSource={setModelPopupSource}/>
                        <Robot show={main_popup_show} showQuizPopup={showQuizPopup} startTour={refreshTour}
                               currentTourStep={currentTourStep}/>
                        <AdvancedSearch showAll={advanced_search_show} showLocation={country_search_show}
                                        showPeriod={epoch_search_show}
                                        applySearch={applySearch} closeSearch={closeSearch}/>
                        <ModelsPopup show={models_popup_show}
                                     handleClose={hideModelsPopup}
                                     models={models} minimize={minimize}
                                     openCallback={showModelsPopup}
                                     selectModel={selectModel}
                                     setPopupWidth={setPopupWidth}
                                     markers={markers}
                                     setSelectedMarkerIndex={setSelectedMarkerIndex}
                                     setMapFlyPosition={setMapFlyPosition}
                                     setModelPopupSource={setModelPopupSource}/>
                        <ModelPopup show={model_popup_show} handleClose={hideModelPopup} model={model}
                                    minimize={minimize}
                                    openCallback={showModelPopup} openGallery={openGalleryPopup}
                                    show3dModel={setShow3dModel}
                                    setModel3dUrl={setModel3dUrl} setModelPopupSource={setModelPopupSource}/>
                        <LanguageMenu show={lang_menu_show} updateLanguage={updateLanguage}
                                      handleClose={hideLanguageMenu} curr_lang={curr_lang}/>
                        <Intro show={show_intro} closeIntro={closeIntro} currentStep={currentStep}
                               setCurrentStep={setCurrentStep}/>
                        <Tour show={show_tour} countries={countries} currentTourStep={currentTourStep}
                              closeTour={closeTour} setCurrentStep={setCurrentTourStep}/>
                        <MinimizedPopup show={show_minimized_popup} maximizeCallback={maximize}
                                        minimizedName={minimized_name}
                                        handleClose={hideMinimizedPopup}></MinimizedPopup>
                    </>
            }
        </div>
    );
}

export default App;
