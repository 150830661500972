import {MinimizedPopupDiv} from "./MinimizedPopup.styles";

const MinimizedPopup = ({show, maximizeCallback, handleClose, minimizedName}) => {

	const maximize = () => {
		maximizeCallback();
		handleClose();
	}

	if(show) {
		return (

			<MinimizedPopupDiv onClick={maximize}>
				{/*<img src={blankButton}/>*/}
				<span>{minimizedName}</span>
			</MinimizedPopupDiv>
		)
	} else {
		return "";
	}
}


export default MinimizedPopup;
