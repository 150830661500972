import flag_sk from '../images/flags/SK.svg'
import flag_en from '../images/flags/EN.svg'
import flag_hr from '../images/flags/HR.svg'
import flag_at from '../images/flags/AT.svg'
import flag_cs from '../images/flags/CS.svg'
import flag_de from '../images/flags/DE.svg'
import flag_ro from '../images/flags/RO.svg'
import flag_sl from '../images/flags/SL.svg'
import flag_bg from '../images/flags/BG.svg'
import flag_hu from '../images/flags/HU.svg'
import flag_sr from '../images/flags/SR.svg'

export const epochs = [
    {
        name: 'period-paleolithic-name',
        content: 'period-paleolithic-description',
        image: "../images/epochs/paleolithic.jpg",

    },
    {
        name: 'period-neolithic-name',
        content: 'period-neolithic-description',
        image: "../images/epochs/neolithic.jpg",
    },
    {
        name: 'period-copperAge-name',
        content: 'period-copperAge-description',
        image: "../images/epochs/copperAge.jpg",
    },
    {
        name: 'period-bronzeAge-name',
        content: 'period-bronzeAge-description',
        image: "../images/epochs/bronzeAge.jpg",
    },
    {
        name: 'period-ironAge-name',
        content: 'period-ironAge-description',
        image: "../images/epochs/ironAge.jpg"
    },
    {
        name: 'period-romanPeriod-name',
        content: 'period-romanPeriod-description',
        image: "../images/epochs/roman.jpg",
    },
    {
        name: 'period-lateAntiquity-name',
        content: 'period-lateAntiquity-description',
        image: "../images/epochs/lateAntiquity.jpg",
    },
    {
        name: 'period-middleAge-name',
        content: 'period-middleAge-description',
        image: "../images/epochs/middleAge.jpg",
    }

]
export const countries = [
    {
        id: 0,
        name: 'countries-slovakia-name',
        shortcut: 'sk',
        flag: flag_sk,
        content: 'slovakia_description',
        image: "../images/countries/slovakia.jpg",
        models: [
            {
                img: "../images/sites/slovakia/thumbs/slovakia_site1_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovakia/slovakia_site1_p1.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site1_p1.jpg",
                        reference: "slovakia_site1_img1_reference",
                        description: "slovakia_site1_img1_description"
                    },

                ],
                country: 'sk',
                name: 'slovakia_site1_name',
                epoch: 'period-paleolithic-name',
                description: 'slovakia_site1_description',
                keywords: 'slovakia_site1_keywords',
                gps: [48.549042, 21.258823]
            },
            {
                img: "../images/sites/slovakia/thumbs/slovakia_site2_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovakia/slovakia_site2_p1.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site2_p1.jpg",
                        reference: "slovakia_site2_img1_reference",
                        description: "slovakia_site2_img1_description"
                    }
                ],
                url: "/",
                country: 'sk',
                name: 'slovakia_site2_name',
                epoch: 'period-paleolithic-name',
                description: 'slovakia_site2_description',
                keywords: 'slovakia_site2_keywords',
                gps: [48.481525, 21.765503]
            },
            {
                img: "../images/sites/slovakia/thumbs/slovakia_site3_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovakia/slovakia_site3_p1.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site3_p1.jpg",
                        reference: "slovakia_site3_img1_reference",
                        description: "slovakia_site3_img1_description"
                    }
                ],
                url: "/",
                country: 'sk',
                name: 'slovakia_site3_name',
                epoch: 'period-paleolithic-name',
                description: 'slovakia_site3_description',
                keywords: 'slovakia_site3_keywords',
                gps: [48.684237, 21.259038]
            },
            {
                img: "../images/sites/slovakia/thumbs/slovakia_site4_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovakia/slovakia_site4_p1.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site4_p1.jpg",
                        reference: "slovakia_site4_img1_reference",
                        description: "slovakia_site4_img1_description"
                    }
                ],
                url: "/",
                country: 'sk',
                name: 'slovakia_site4_name',
                epoch: 'period-neolithic-name',
                description: 'slovakia_site4_description',
                keywords: 'slovakia_site4_keywords',
                gps: [48.692651, 21.236289]
            },
            {
                img: "../images/sites/slovakia/thumbs/slovakia_site5_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovakia/slovakia_site5_p1.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site5_p1.jpg",
                        reference: "slovakia_site5_img1_reference",
                        description: "slovakia_site5_img1_description"
                    }
                ],
                url: "/",
                country: 'sk',
                name: 'slovakia_site5_name',
                epoch: 'period-neolithic-name',
                description: 'slovakia_site5_description',
                keywords: 'slovakia_site5_keywords',
                gps: [48.537355, 21.880198]
            },
            {
                img: "../images/sites/slovakia/thumbs/slovakia_site6_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovakia/slovakia_site6_p1.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site6_p1.jpg",
                        reference: "slovakia_site6_img1_reference",
                        description: "slovakia_site6_img1_description"
                    },
                    {
                        src: "../images/sites/slovakia/slovakia_site6_p2.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site6_p2.jpg",
                        reference: "slovakia_site6_img2_reference",
                        description: "slovakia_site6_img2_description"
                    }
                ],
                url: "/",
                country: 'sk',
                name: 'slovakia_site6_name',
                epoch: 'period-neolithic-name',
                description: 'slovakia_site6_description',
                keywords: 'slovakia_site6_keywords',
                gps: [48.477982, 20.470503]
            },
            {
                img: "../images/sites/slovakia/thumbs/slovakia_site7_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovakia/slovakia_site7_p1.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site7_p1.jpg",
                        reference: "slovakia_site7_img1_reference",
                        description: "slovakia_site7_img1_description"
                    },
                    {
                        src: "../images/sites/slovakia/slovakia_site7_p2.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site7_p2.jpg",
                        reference: "slovakia_site7_img2_reference",
                        description: "slovakia_site7_img2_description"
                    }
                ],
                url: "/",
                country: 'sk',
                name: 'slovakia_site7_name',
                epoch: 'period-copperAge-name',
                description: 'slovakia_site7_description',
                keywords: 'slovakia_site7_keywords',
                gps: [48.728044, 22.214093]
            },
            {
                img: "../images/sites/slovakia/thumbs/slovakia_site8_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovakia/slovakia_site8_p1.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site8_p1.jpg",
                        reference: "slovakia_site8_img1_reference",
                        description: "slovakia_site8_img1_description"
                    },
                    {
                        src: "../images/sites/slovakia/slovakia_site8_p2.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site8_p2.jpg",
                        reference: "slovakia_site8_img2_reference",
                        description: "slovakia_site8_img2_description"
                    }
                ],
                url: "/",
                country: 'sk',
                name: 'slovakia_site8_name',
                epoch: 'period-copperAge-name',
                description: 'slovakia_site8_description',
                keywords: 'slovakia_site8_keywords',
                gps: [48.571084, 21.910811]
            },
            {
                img: "../images/sites/site-placeholder.png",
                images: [],
                url: "/",
                country: 'sk',
                name: 'slovakia_site9_name',
                epoch: 'period-copperAge-name',
                description: 'slovakia_site9_description',
                keywords: 'slovakia_site9_keywords',
                gps: [48.765030, 22.009602]
            },
            {
                img: "../images/sites/slovakia/thumbs/slovakia_site10_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovakia/slovakia_site10_p1.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site10_p1.jpg",
                        reference: "slovakia_site10_img1_reference",
                        description: "slovakia_site10_img1_description"
                    },
                    {
                        src: "../images/sites/slovakia/slovakia_site10_p2.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site10_p2.jpg",
                        reference: "slovakia_site10_img2_reference",
                        description: "slovakia_site10_img2_description"
                    },
                    {
                        src: "../images/sites/slovakia/slovakia_site10_p3.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site10_p3.jpg",
                        reference: "slovakia_site10_img3_reference",
                        description: "slovakia_site10_img3_description"
                    },
                    {
                        src: "../images/sites/slovakia/slovakia_site10_p4.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site10_p4.jpg",
                        reference: "slovakia_site10_img4_reference",
                        description: "slovakia_site10_img4_description"
                    },
                    {
                        src: "../images/sites/slovakia/slovakia_site10_p5.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site10_p5.jpg",
                        reference: "slovakia_site10_img5_reference",
                        description: "slovakia_site10_img5_description"
                    },
                    {
                        src: "../images/sites/slovakia/slovakia_site10_p6.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site10_p6.jpg",
                        reference: "slovakia_site10_img6_reference",
                        description: "slovakia_site10_img6_description"
                    }
                ],
                url: "/",
                country: 'sk',
                name: 'slovakia_site10_name',
                epoch: 'period-bronzeAge-name',
                description: 'slovakia_site10_description',
                keywords: 'slovakia_site10_keywords',
                gps: [48.617170, 21.366686]
            },
            {
                img: "../images/sites/slovakia/thumbs/slovakia_site11_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovakia/slovakia_site11_p1.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site11_p1.jpg",
                        reference: "slovakia_site11_img1_reference",
                        description: "slovakia_site11_img1_description"
                    },
                    {
                        src: "../images/sites/slovakia/slovakia_site11_p2.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site11_p2.jpg",
                        reference: "slovakia_site11_img2_reference",
                        description: "slovakia_site11_img2_description"
                    }
                ],
                url: "/",
                country: 'sk',
                name: 'slovakia_site11_name',
                epoch: 'period-bronzeAge-name',
                description: 'slovakia_site11_description',
                keywords: 'slovakia_site11_keywords',
                gps: [48.408090, 21.803724]
            },
            {
                img: "../images/sites/slovakia/thumbs/slovakia_site12_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovakia/slovakia_site12_p1.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site12_p1.jpg",
                        reference: "slovakia_site12_img1_reference",
                        description: "slovakia_site12_img1_description"
                    }
                ],
                url: "/",
                country: 'sk',
                name: 'slovakia_site12_name',
                epoch: 'period-bronzeAge-name',
                description: 'slovakia_site12_description',
                keywords: 'slovakia_site12_keywords',
                gps: [48.604292, 20.832229]
            },
            {
                img: "../images/sites/slovakia/thumbs/slovakia_site13_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovakia/slovakia_site13_p1.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site13_p1.jpg",
                        reference: "slovakia_site13_img1_reference",
                        description: "slovakia_site13_img1_description"
                    }
                ],
                url: "/",
                country: 'sk',
                name: 'slovakia_site13_name',
                epoch: 'period-ironAge-name',
                description: 'slovakia_site13_description',
                keywords: 'slovakia_site13_keywords',
                gps: [48.766005, 21.898923]
            },
            {
                img: "../images/sites/slovakia/thumbs/slovakia_site14_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovakia/slovakia_site14_p1.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site14_p1.jpg",
                        reference: "slovakia_site14_img1_reference",
                        description: "slovakia_site14_img1_description"
                    },
                    {
                        src: "../images/sites/slovakia/slovakia_site14_p2.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site14_p2.jpg",
                        reference: "slovakia_site14_img2_reference",
                        description: "slovakia_site14_img2_description"
                    },
                    {
                        src: "../images/sites/slovakia/slovakia_site14_p3.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site14_p3.jpg",
                        reference: "slovakia_site14_img3_reference",
                        description: "slovakia_site14_img3_description"
                    }
                ],
                url: "/",
                country: 'sk',
                name: 'slovakia_site14_name',
                epoch: 'period-ironAge-name',
                description: 'slovakia_site14_description',
                keywords: 'slovakia_site14_keywords',
                gps: [48.546203, 21.957138]
            },
            {
                img: "../images/sites/slovakia/thumbs/slovakia_site15_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovakia/slovakia_site15_p1.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site15_p1.jpg",
                        reference: "slovakia_site15_img1_reference",
                        description: "slovakia_site15_img1_description"
                    },
                    {
                        src: "../images/sites/slovakia/slovakia_site15_p2.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site15_p2.jpg",
                        reference: "slovakia_site15_img2_reference",
                        description: "slovakia_site15_img2_description"
                    },
                    {
                        src: "../images/sites/slovakia/slovakia_site15_p3.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site15_p3.jpg",
                        reference: "slovakia_site15_img3_reference",
                        description: "slovakia_site15_img3_description"
                    }
                ],
                url: "/",
                country: 'sk',
                name: 'slovakia_site15_name',
                epoch: 'period-ironAge-name',
                description: 'slovakia_site15_description',
                keywords: 'slovakia_site15_keywords',
                gps: [48.441024, 21.803506]
            },
            {
                img: "../images/sites/slovakia/thumbs/slovakia_site16_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovakia/slovakia_site16_p1.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site16_p1.jpg",
                        reference: "slovakia_site16_img1_reference",
                        description: "slovakia_site16_img1_description"
                    },
                    {
                        src: "../images/sites/slovakia/slovakia_site16_p2.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site16_p2.jpg",
                        reference: "slovakia_site16_img2_reference",
                        description: "slovakia_site16_img2_description"
                    },
                    {
                        src: "../images/sites/slovakia/slovakia_site16_p3.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site16_p3.jpg",
                        reference: "slovakia_site16_img3_reference",
                        description: "slovakia_site16_img3_description"
                    }
                ],
                url: "/",
                country: 'sk',
                name: 'slovakia_site16_name',
                epoch: 'period-romanPeriod-name',
                description: 'slovakia_site16_description',
                keywords: 'slovakia_site16_keywords',
                gps: [48.664329, 21.415644]
            },
            {
                img: "../images/sites/slovakia/thumbs/slovakia_site17_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovakia/slovakia_site17_p1.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site17_p1.jpg",
                        reference: "slovakia_site17_img1_reference",
                        description: "slovakia_site17_img1_description"
                    },
                    {
                        src: "../images/sites/slovakia/slovakia_site17_p2.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site17_p2.jpg",
                        reference: "slovakia_site17_img2_reference",
                        description: "slovakia_site17_img2_description"
                    },
                    {
                        src: "../images/sites/slovakia/slovakia_site17_p3.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site17_p3.jpg",
                        reference: "slovakia_site17_img3_reference",
                        description: "slovakia_site17_img3_description"
                    }
                ],
                url: "/",
                country: 'sk',
                name: 'slovakia_site17_name',
                epoch: 'period-romanPeriod-name',
                description: 'slovakia_site17_description',
                keywords: 'slovakia_site17_keywords',
                gps: [48.705778, 21.874872]
            },
            {
                img: "../images/sites/slovakia/thumbs/slovakia_site18_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovakia/slovakia_site18_p1.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site18_p1.jpg",
                        reference: "slovakia_site18_img1_reference",
                        description: "slovakia_site18_img1_description"
                    },
                    {
                        src: "../images/sites/slovakia/slovakia_site18_p2.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site18_p2.jpg",
                        reference: "slovakia_site18_img2_reference",
                        description: "slovakia_site18_img2_description"
                    },
                    {
                        src: "../images/sites/slovakia/slovakia_site18_p3.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site18_p3.jpg",
                        reference: "slovakia_site18_img3_reference",
                        description: "slovakia_site18_img3_description"
                    }
                ],
                url: "/",
                country: 'sk',
                name: 'slovakia_site18_name',
                epoch: 'period-romanPeriod-name',
                description: 'slovakia_site18_description',
                keywords: 'slovakia_site18_keywords',
                gps: [48.464943, 21.763704]
            },
            {
                img: "../images/sites/slovakia/thumbs/slovakia_site19_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovakia/slovakia_site19_p1.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site19_p1.jpg",
                        reference: "slovakia_site19_img1_reference",
                        description: "slovakia_site19_img1_description"
                    },
                    {
                        src: "../images/sites/slovakia/slovakia_site19_p2.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site19_p2.jpg",
                        reference: "slovakia_site19_img2_reference",
                        description: "slovakia_site19_img2_description"
                    },
                    {
                        src: "../images/sites/slovakia/slovakia_site19_p3.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site19_p3.jpg",
                        reference: "slovakia_site19_img3_reference",
                        description: "slovakia_site19_img3_description"
                    }
                ],
                url: "/",
                country: 'sk',
                name: 'slovakia_site19_name',
                epoch: 'period-lateAntiquity-name',
                description: 'slovakia_site19_description',
                keywords: 'slovakia_site19_keywords',
                gps: [48.655456, 21.277491]
            },
            {
                img: "../images/sites/slovakia/thumbs/slovakia_site20_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovakia/slovakia_site20_p1.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site20_p1.jpg",
                        reference: "slovakia_site20_img1_reference",
                        description: "slovakia_site20_img1_description"
                    },
                    {
                        src: "../images/sites/slovakia/slovakia_site20_p2.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site20_p2.jpg",
                        reference: "slovakia_site20_img2_reference",
                        description: "slovakia_site20_img2_description"
                    },
                    {
                        src: "../images/sites/slovakia/slovakia_site20_p3.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site20_p3.jpg",
                        reference: "slovakia_site20_img3_reference",
                        description: "slovakia_site20_img3_description"
                    }
                ],
                url: "/",
                country: 'sk',
                name: 'slovakia_site20_name',
                epoch: 'period-lateAntiquity-name',
                description: 'slovakia_site20_description',
                keywords: 'slovakia_site20_keywords',
                gps: [48.633180, 21.297951]
            },
            {
                img: "../images/sites/slovakia/thumbs/slovakia_site21_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovakia/slovakia_site21_p1.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site21_p1.jpg",
                        reference: "slovakia_site21_img1_reference",
                        description: "slovakia_site21_img1_description"
                    },
                    {
                        src: "../images/sites/slovakia/slovakia_site21_p2.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site21_p2.jpg",
                        reference: "slovakia_site21_img2_reference",
                        description: "slovakia_site21_img2_description"
                    }
                ],
                url: "/",
                country: 'sk',
                name: 'slovakia_site21_name',
                epoch: 'period-lateAntiquity-name',
                description: 'slovakia_site21_description',
                keywords: 'slovakia_site21_keywords',
                gps: [48.551279, 21.274764]
            },
            {
                img: "../images/sites/slovakia/thumbs/slovakia_site22_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovakia/slovakia_site22_p1.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site22_p1.jpg",
                        reference: "slovakia_site22_img1_reference",
                        description: "slovakia_site22_img1_description"
                    },
                    {
                        src: "../images/sites/slovakia/slovakia_site22_p2.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site22_p2.jpg",
                        reference: "slovakia_site22_img2_reference",
                        description: "slovakia_site22_img2_description"
                    }
                ],
                url: "/",
                country: 'sk',
                name: 'slovakia_site22_name',
                epoch: 'period-middleAge-name',
                description: 'slovakia_site22_description',
                keywords: 'slovakia_site22_keywords',
                gps: [48.439897, 21.952376]
            },
            {
                img: "../images/sites/slovakia/thumbs/slovakia_site23_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovakia/slovakia_site23_p1.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site23_p1.jpg",
                        reference: "slovakia_site23_img1_reference",
                        description: "slovakia_site23_img1_description"
                    },
                    {
                        src: "../images/sites/slovakia/slovakia_site23_p2.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site23_p2.jpg",
                        reference: "slovakia_site23_img2_reference",
                        description: "slovakia_site23_img2_description"
                    },
                    {
                        src: "../images/sites/slovakia/slovakia_site23_p3.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site23_p3.jpg",
                        reference: "slovakia_site23_img3_reference",
                        description: "slovakia_site23_img3_description"
                    }
                ],
                url: "/",
                country: 'sk',
                name: 'slovakia_site23_name',
                epoch: 'period-middleAge-name',
                description: 'slovakia_site23_description',
                keywords: 'slovakia_site23_keywords',
                gps: [48.441542, 21.813470]
            },
            {
                img: "../images/sites/slovakia/thumbs/slovakia_site24_p1.jpg",
                images: [

                    /*{
                        url: "oUFJJNQGwhk",
                        thumb: "../images/sites/austria/thumbs/austria_site11_p1.jpg",
                        reference: "slovakia_site1_img1_reference",
                        description: "slovakia_site1_img1_description",
                        provider: "youtube"
                    },*/
                    /*{
                        url: "90509568",
                        thumb: "../images/sites/austria/thumbs/austria_site11_p1.jpg",
                        reference: "slovakia_site1_img1_reference",
                        description: "slovakia_site1_img1_description",
                        provider: "vimeo"
                    },*/
                    {
                        src: "../images/sites/slovakia/slovakia_site24_p1.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site24_p1.jpg",
                        reference: "slovakia_site24_img1_reference",
                        description: "slovakia_site24_img1_description"
                    },
                    {
                        src: "../images/sites/slovakia/slovakia_site24_p2.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site24_p2.jpg",
                        reference: "slovakia_site24_img2_reference",
                        description: "slovakia_site24_img2_description"
                    },
                    {
                        src: "../images/sites/slovakia/slovakia_site24_p3.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site24_p3.jpg",
                        reference: "slovakia_site24_img3_reference",
                        description: "slovakia_site24_img3_description"
                    }
                ],
                url: "/",
                country: 'sk',
                name: 'slovakia_site24_name',
                epoch: 'period-romanPeriod-name',
                description: 'slovakia_site24_description',
                keywords: 'slovakia_site24_keywords',
                gps: [49.000568, 20.768331]
            },
            {
                img: "../images/sites/slovakia/thumbs/slovakia_site25_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovakia/slovakia_site25_p1.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site25_p1.jpg",
                        reference: "slovakia_site25_img1_reference",
                        description: "slovakia_site25_img1_description"
                    },
                    {
                        src: "../images/sites/slovakia/slovakia_site25_p2.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site25_p2.jpg",
                        reference: "slovakia_site25_img2_reference",
                        description: "slovakia_site25_img2_description"
                    },
                    {
                        src: "../images/sites/slovakia/slovakia_site25_p3.png",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site25_p3.png",
                        reference: "slovakia_site25_img3_reference",
                        description: "slovakia_site25_img3_description"
                    },
                    {
                        src: "../images/sites/slovakia/slovakia_site25_p4.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site25_p4.jpg",
                        reference: "slovakia_site25_img4_reference",
                        description: "slovakia_site25_img4_description"
                    },
                    {
                        src: "../images/sites/slovakia/slovakia_site25_p5.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site25_p5.jpg",
                        reference: "slovakia_site25_img5_reference",
                        description: "slovakia_site25_img5_description"
                    },
                    {
                        src: "../images/sites/slovakia/slovakia_site25_p6.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site25_p6.jpg",
                        reference: "slovakia_site25_img6_reference",
                        description: "slovakia_site25_img6_description"
                    },
                    {
                        src: "../images/sites/slovakia/slovakia_site25_p7.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site25_p7.jpg",
                        reference: "slovakia_site25_img7_reference",
                        description: "slovakia_site25_img7_description"
                    },
                    {
                        src: "../images/sites/slovakia/slovakia_site25_p8.jpg",
                        thumb: "../images/sites/slovakia/thumbs/slovakia_site25_p8.jpg",
                        reference: "slovakia_site25_img8_reference",
                        description: "slovakia_site25_img8_description"
                    }
                ],
                url: "/",
                country: 'sk',
                name: 'slovakia_site25_name',
                epoch: 'period-middleAge-name',
                description: 'slovakia_site25_description',
                keywords: 'slovakia_site25_keywords',
                gps: [48.81531676726848, 21.47853614062044]
            },

        ]
    },

    {
        id: 1,
        name: 'countries-hungary-name',
        shortcut: 'hu',
        flag: flag_hu,
        url: '/unity_example',
        content: 'hungary_description',
        image: "../images/countries/hungary.jpg",
        models: [
            {
                img: "../images/sites/hungary/thumbs/hungary_site1_p1.jpg",
                images: [
                    {
                        src: "../images/sites/hungary/hungary_site1_p1.jpg",
                        thumb: "../images/sites/hungary/thumbs/hungary_site1_p1.jpg",
                        reference: "hungary_site1_img1_reference",
                        description: "hungary_site1_img1_description"
                    },
                ],
                url: "/",
                country: 'hu',
                name: 'hungary_site1_name',
                epoch: 'period-paleolithic-name',
                description: 'hungary_site1_description',
                keywords: 'hungary_site1_keywords',
                gps: [1, 1]
            },
            {
                img: "../images/sites/hungary/thumbs/hungary_site2_p1.jpg",
                images: [
                    {
                        src: "../images/sites/hungary/hungary_site2_p1.jpg",
                        thumb: "../images/sites/hungary/thumbs/hungary_site2_p1.jpg",
                        reference: "hungary_site2_img1_reference",
                        description: "hungary_site2_img1_description"
                    },
                    {
                        src: "../images/sites/hungary/hungary_site2_p2.jpg",
                        thumb: "../images/sites/hungary/thumbs/hungary_site2_p2.jpg",
                        reference: "hungary_site3_img1_reference",
                        description: "hungary_site3_img1_description"
                    }
                ],
                url: "/",
                country: 'hu',
                name: 'hungary_site2_name',
                epoch: 'period-paleolithic-name',
                description: 'hungary_site2_description',
                keywords: 'hungary_site2_keywords',
                gps: [1, 1]
            },
            {
                img: "../images/sites/hungary/thumbs/hungary_site3_p1.jpg",
                images: [
                    {
                        src: "../images/sites/hungary/hungary_site3_p1.jpg",
                        thumb: "../images/sites/hungary/thumbs/hungary_site3_p1.jpg",
                        reference: "hungary_site3_img1_reference",
                        description: "hungary_site3_img1_description"
                    },
                    {
                        src: "../images/sites/hungary/hungary_site3_p2.jpg",
                        thumb: "../images/sites/hungary/thumbs/hungary_site3_p2.jpg",
                        reference: "hungary_site3_img2_description",
                        description: "hungary_site3_img2_name"
                    },
                    {
                        src: "../images/sites/hungary/hungary_site3_p3.jpg",
                        thumb: "../images/sites/hungary/thumbs/hungary_site3_p3.jpg",
                        reference: "hungary_site3_img3_reference",
                        description: "hungary_site3_img3_description"
                    }
                    ,
                    {
                        src: "../images/sites/hungary/hungary_site3_p4.jpg",
                        thumb: "../images/sites/hungary/thumbs/hungary_site3_p4.jpg",
                        reference: "hungary_site3_img4_reference",
                        description: "hungary_site3_img4_description"
                    },
                    {
                        src: "../images/sites/hungary/hungary_site3_p5.jpg",
                        thumb: "../images/sites/hungary/thumbs/hungary_site3_p5.jpg",
                        reference: "hungary_site3_img5_reference",
                        description: "hungary_site3_img5_description"
                    }
                ],
                url: "/",
                country: 'hu',
                name: 'hungary_site3_name',
                epoch: 'period-paleolithic-name',
                description: 'hungary_site3_description',
                keywords: 'hungary_site3_keywords',
                gps: [47.62422115577922, 18.383942248864294]
            },
            {
                img: "../images/sites/hungary/thumbs/hungary_site4_p1.jpg",
                images: [
                    {
                        src: "../images/sites/hungary/hungary_site4_p1.jpg",
                        thumb: "../images/sites/hungary/thumbs/hungary_site4_p1.jpg",
                        reference: "hungary_site4_img1_reference",
                        description: "hungary_site4_img1_description"
                    }
                ],
                url: "/",
                country: 'hu',
                name: 'hungary_site4_name',
                epoch: 'period-neolithic-name',
                description: 'hungary_site4_description',
                keywords: 'hungary_site4_keywords',
                gps: [1, 1]
            },
            /*{
                img: "../images/sites/site-placeholder.png",
                images: [],
                url: "/",
                country: 'hu',
                name: 'hungary_site5_name',
                epoch: 'period-neolithic-name',
                description: 'hungary_site5_description',
                keywords: 'hungary_site5_keywords',
                gps: [1, 1]
            },*/
            {
                img: "../images/sites/hungary/thumbs/hungary_site6_p1.jpg",
                images: [
                    {
                        src: "../images/sites/hungary/hungary_site6_p1.jpg",
                        thumb: "../images/sites/hungary/thumbs/hungary_site6_p1.jpg",
                        reference: "hungary_site6_img1_reference",
                        description: "hungary_site6_img1_description"
                    }
                ],
                url: "/",
                country: 'hu',
                name: 'hungary_site6_name',
                epoch: 'period-neolithic-name',
                description: 'hungary_site6_description',
                keywords: 'hungary_site6_keywords',
                gps: [1, 1]
            },
            /*{
                img: "../images/sites/site-placeholder.png",
                images: [],
                url: "/",
                country: 'hu',
                name: 'hungary_site7_name',
                epoch: 'period-copperAge-name',
                description: 'hungary_site7_description',
                keywords: 'hungary_site7_keywords',
                gps: [47.56302752008563, 19.04851105516857]
            },*/
            {
                img: "../images/sites/hungary/thumbs/hungary_site8_p1.jpg",
                images: [
                    {
                        src: "../images/sites/hungary/hungary_site8_p1.jpg",
                        thumb: "../images/sites/hungary/thumbs/hungary_site8_p1.jpg",
                        reference: "hungary_site8_img1_reference",
                        description: "hungary_site8_img1_description"
                    }
                ],
                url: "/",
                country: 'hu',
                name: 'hungary_site8_name',
                epoch: 'period-copperAge-name',
                description: 'hungary_site8_description',
                keywords: 'hungary_site8_keywords',
                gps: [48.157985538633525, 19.618009259727543]
            },
            {
                img: "../images/sites/hungary/thumbs/hungary_site9_p1.jpg",
                images: [
                    {
                        src: "../images/sites/hungary/hungary_site9_p1.jpg",
                        thumb: "../images/sites/hungary/thumbs/hungary_site9_p1.jpg",
                        reference: "hungary_site9_img1_reference",
                        description: "hungary_site9_img1_description"
                    },
                    {
                        src: "../images/sites/hungary/hungary_site9_p2.jpg",
                        thumb: "../images/sites/hungary/thumbs/hungary_site9_p2.jpg",
                        reference: "hungary_site9_img2_reference",
                        description: "hungary_site9_img2_description"
                    },
                    {
                        src: "../images/sites/hungary/hungary_site9_p3.jpg",
                        thumb: "../images/sites/hungary/thumbs/hungary_site9_p3.jpg",
                        reference: "hungary_site9_img3_reference",
                        description: "hungary_site9_img3_description"
                    },
                    {
                        src: "../images/sites/hungary/hungary_site9_p4.jpg",
                        thumb: "../images/sites/hungary/thumbs/hungary_site9_p4.jpg",
                        reference: "hungary_site9_img4_reference",
                        description: "hungary_site9_img4_description"
                    }
                ],
                url: "/",
                country: 'hu',
                name: 'hungary_site9_name',
                epoch: 'period-copperAge-name',
                description: 'hungary_site9_description',
                keywords: 'hungary_site9_keywords',
                gps: [46.941043270980664, 21.20922984756597]
            },
            {
                img: "../images/sites/hungary/thumbs/hungary_site10_p1.jpg",
                images: [
                    {
                        src: "../images/sites/hungary/hungary_site10_p1.jpg",
                        thumb: "../images/sites/hungary/thumbs/hungary_site10_p1.jpg",
                        reference: "hungary_site10_img1_reference",
                        description: "hungary_site10_img1_description"
                    },
                    {
                        src: "../images/sites/hungary/hungary_site10_p2.jpg",
                        thumb: "../images/sites/hungary/thumbs/hungary_site10_p2.jpg",
                        reference: "hungary_site10_img2_reference",
                        description: "hungary_site10_img2_description"
                    },
                    {
                        src: "../images/sites/hungary/hungary_site10_p3.jpg",
                        thumb: "../images/sites/hungary/thumbs/hungary_site10_p3.jpg",
                        reference: "hungary_site10_img3_reference",
                        description: "hungary_site10_img3_description"
                    },
                    {
                        src: "../images/sites/hungary/hungary_site10_p4.jpg",
                        thumb: "../images/sites/hungary/thumbs/hungary_site10_p4.jpg",
                        reference: "hungary_site10_img4_reference",
                        description: "hungary_site10_img4_description"
                    }
                ],
                url: "/",
                country: 'hu',
                name: 'hungary_site10_name',
                epoch: 'period-bronzeAge-name',
                description: 'hungary_site10_description',
                keywords: 'hungary_site10_keywords',
                gps: [48.280, 20.300]
            },
            {
                img: "../images/sites/hungary/thumbs/hungary_site11_p1.jpg",
                images: [
                    {
                        src: "../images/sites/hungary/hungary_site11_p1.jpg",
                        thumb: "../images/sites/hungary/thumbs/hungary_site11_p1.jpg",
                        reference: "hungary_site11_img1_reference",
                        description: "hungary_site11_img1_description"
                    },
                    {
                        src: "../images/sites/hungary/hungary_site11_p2.jpg",
                        thumb: "../images/sites/hungary/thumbs/hungary_site11_p2.jpg",
                        reference: "hungary_site11_img2_reference",
                        description: "hungary_site11_img2_description"
                    },
                    {
                        src: "../images/sites/hungary/hungary_site11_p3.jpg",
                        thumb: "../images/sites/hungary/thumbs/hungary_site11_p3.jpg",
                        reference: "hungary_site11_img3_reference",
                        description: "hungary_site11_img3_description"
                    }
                ],
                url: "/",
                country: 'hu',
                name: 'hungary_site11_name',
                epoch: 'period-bronzeAge-name',
                description: 'hungary_site11_description',
                keywords: 'hungary_site11_keywords',
                gps: [47.510222, 21.214481]
            },
            {
                img: "../images/sites/hungary/thumbs/hungary_site12_p1.jpg",
                images: [
                    {
                        src: "../images/sites/hungary/hungary_site12_p1.jpg",
                        thumb: "../images/sites/hungary/thumbs/hungary_site12_p1.jpg",
                        reference: "hungary_site12_img1_reference",
                        description: "hungary_site12_img1_description"
                    }
                ],
                url: "/",
                country: 'hu',
                name: 'hungary_site12_name',
                epoch: 'period-bronzeAge-name',
                description: 'hungary_site12_description',
                keywords: 'hungary_site12_keywords',
                gps: [47.202507, 18.571994]
            },
            {
                img: "../images/sites/hungary/thumbs/hungary_site13_p1.jpg",
                images: [
                    {
                        src: "../images/sites/hungary/hungary_site13_p1.jpg",
                        thumb: "../images/sites/hungary/thumbs/hungary_site13_p1.jpg",
                        reference: "hungary_site13_img1_reference",
                        description: "hungary_site13_img1_description"
                    }
                ],
                url: "/",
                country: 'hu',
                name: 'hungary_site13_name',
                epoch: 'period-ironAge-name',
                description: 'hungary_site13_description',
                keywords: 'hungary_site13_keywords',
                gps: [47.400197, 16.320194]
            },
            {
                img: "../images/sites/hungary/thumbs/hungary_site14_p1.jpg",
                images: [
                    {
                        src: "../images/sites/hungary/hungary_site14_p1.jpg",
                        thumb: "../images/sites/hungary/thumbs/hungary_site14_p1.jpg",
                        reference: "hungary_site14_img1_reference",
                        description: "hungary_site14_img1_description"
                    }
                ],
                url: "/",
                country: 'hu',
                name: 'hungary_site14_name',
                epoch: 'period-ironAge-name',
                description: 'hungary_site14_description',
                keywords: 'hungary_site14_keywords',
                gps: [47.475449, 18.441335]
            },
            {
                img: "../images/sites/hungary/thumbs/hungary_site15_p1.jpg",
                images: [
                    {
                        src: "../images/sites/hungary/hungary_site15_p1.jpg",
                        thumb: "../images/sites/hungary/thumbs/hungary_site15_p1.jpg",
                        reference: "hungary_site15_img1_reference",
                        description: "hungary_site15_img1_description"
                    },
                    {
                        src: "../images/sites/hungary/hungary_site15_p2.jpg",
                        thumb: "../images/sites/hungary/thumbs/hungary_site15_p2.jpg",
                        reference: "hungary_site15_img2_reference",
                        description: "hungary_site15_img2_description"
                    }
                ],
                url: "/",
                country: 'hu',
                name: 'hungary_site15_name',
                epoch: 'period-ironAge-name',
                description: 'hungary_site15_description',
                keywords: 'hungary_site15_keywords',
                gps: [47.202507, 18.571994]
            },
            {
                img: "../images/sites/hungary/thumbs/hungary_site16_p1.jpg",
                images: [
                    {
                        src: "../images/sites/hungary/hungary_site16_p1.jpg",
                        thumb: "../images/sites/hungary/thumbs/hungary_site16_p1.jpg",
                        reference: "hungary_site16_img1_reference",
                        description: "hungary_site16_img1_description"
                    },
                    {
                        src: "../images/sites/hungary/hungary_site16_p2.jpg",
                        thumb: "../images/sites/hungary/thumbs/hungary_site16_p2.jpg",
                        reference: "hungary_site16_img2_reference",
                        description: "hungary_site16_img2_description"
                    }
                ],
                url: "/",
                country: 'hu',
                name: 'hungary_site16_name',
                epoch: 'period-romanPeriod-name',
                description: 'hungary_site16_description',
                keywords: 'hungary_site16_keywords',
                gps: [47.090006788989555, 18.42069906571094]
            },
            {
                img: "../images/sites/hungary/thumbs/hungary_site17_p1.jpg",
                images: [
                    {
                        src: "../images/sites/hungary/hungary_site17_p1.jpg",
                        thumb: "../images/sites/hungary/thumbs/hungary_site17_p1.jpg",
                        reference: "hungary_site17_img1_reference",
                        description: "hungary_site17_img1_description"
                    },
                    {
                        src: "../images/sites/hungary/hungary_site17_p2.jpg",
                        thumb: "../images/sites/hungary/thumbs/hungary_site17_p2.jpg",
                        reference: "hungary_site17_img2_reference",
                        description: "hungary_site17_img2_description"
                    },
                    {
                        src: "../images/sites/hungary/hungary_site17_p3.jpg",
                        thumb: "../images/sites/hungary/thumbs/hungary_site17_p3.jpg",
                        reference: "hungary_site17_img3_reference",
                        description: "hungary_site17_img3_description"
                    },
                    {
                        src: "../images/sites/hungary/hungary_site17_p4.jpg",
                        thumb: "../images/sites/hungary/thumbs/hungary_site17_p4.jpg",
                        reference: "hungary_site17_img4_reference",
                        description: "hungary_site17_img4_description"
                    },
                    {
                        src: "../images/sites/hungary/hungary_site17_p5.jpg",
                        thumb: "../images/sites/hungary/thumbs/hungary_site17_p5.jpg",
                        reference: "hungary_site17_img5_reference",
                        description: "hungary_site17_img5_description"
                    }
                ],
                url: "/",
                country: 'hu',
                name: 'hungary_site17_name',
                epoch: 'period-romanPeriod-name',
                description: 'hungary_site17_description',
                keywords: 'hungary_site17_keywords',
                gps: [47.56359694287705, 19.049664815470322]
            },
            {
                img: "../images/sites/hungary/thumbs/hungary_site18_p1.jpg",
                images: [
                    {
                        src: "../images/sites/hungary/hungary_site18_p1.jpg",
                        thumb: "../images/sites/hungary/thumbs/hungary_site18_p1.jpg",
                        reference: "hungary_site18_img1_reference",
                        description: "hungary_site18_img1_description"
                    },
                    {
                        src: "../images/sites/hungary/hungary_site18_p2.jpg",
                        thumb: "../images/sites/hungary/thumbs/hungary_site18_p2.jpg",
                        reference: "hungary_site18_img2_description",
                        description: "hungary_site18_img2_name"
                    },
                    {
                        src: "../images/sites/hungary/hungary_site18_p3.jpg",
                        thumb: "../images/sites/hungary/thumbs/hungary_site18_p3.jpg",
                        reference: "hungary_site18_img3_reference",
                        description: "hungary_site18_img3_description"
                    }
                    ,
                    {
                        src: "../images/sites/hungary/hungary_site18_p4.jpg",
                        thumb: "../images/sites/hungary/thumbs/hungary_site18_p4.jpg",
                        reference: "hungary_site18_img4_reference",
                        description: "hungary_site18_img4_description"
                    }
                ],
                models_3d: [
                    {
                        model_3d: '/examples/hu_balaca_1/index.html',
                        model_3d_thumb: '/examples/hu_balaca_1/cover.jpg',
                    },
                    {
                        model_3d: '/examples/hu_balaca_2/index.html',
                        model_3d_thumb: '/examples/hu_balaca_2/cover.jpg',
                    },
                    {
                        model_3d: '/examples/hu_balaca_3/index.html',
                        model_3d_thumb: '/examples/hu_balaca_3/cover.jpg',
                    },
                    {
                        model_3d: '/examples/hu_balaca_4/index.html',
                        model_3d_thumb: '/examples/hu_balaca_4/cover.jpg',
                    },
                    {
                        model_3d: '/examples/hu_balaca_5/index.html',
                        model_3d_thumb: '/examples/hu_balaca_5/cover.jpg',
                    },
                    {
                        model_3d: '/examples/hu_balaca_6/index.html',
                        model_3d_thumb: '/examples/hu_balaca_6/cover.jpg',
                    },
                ],
                url: "/",
                country: 'hu',
                name: 'hungary_site18_name',
                epoch: 'period-romanPeriod-name',
                description: 'hungary_site18_description',
                keywords: 'hungary_site18_keywords',
                gps: [47.04468424546735, 17.887876460212848]
            },
            {
                img: "../images/sites/hungary/thumbs/hungary_site19_p1.jpg",
                images: [
                    {
                        src: "../images/sites/hungary/hungary_site19_p1.jpg",
                        thumb: "../images/sites/hungary/thumbs/hungary_site19_p1.jpg",
                        reference: "hungary_site19_img1_reference",
                        description: "hungary_site19_img1_description"
                    },
                    {
                        src: "../images/sites/hungary/hungary_site19_p2.jpg",
                        thumb: "../images/sites/hungary/thumbs/hungary_site19_p2.jpg",
                        reference: "hungary_site19_img2_reference",
                        description: "hungary_site19_img2_description"
                    }
                ],
                url: "/",
                country: 'hu',
                name: 'hungary_site19_name',
                epoch: 'period-lateAntiquity-name',
                description: 'hungary_site19_description',
                keywords: 'hungary_site19_keywords',
                gps: [46.71010863108334, 17.242590359538728]
            },
            {
                img: "../images/sites/hungary/thumbs/hungary_site20_p1.jpg",
                images: [
                    {
                        src: "../images/sites/hungary/hungary_site20_p1.jpg",
                        thumb: "../images/sites/hungary/thumbs/hungary_site20_p1.jpg",
                        reference: "hungary_site20_img1_reference",
                        description: "hungary_site20_img1_description"
                    }
                ],
                url: "/",
                country: 'hu',
                name: 'hungary_site20_name',
                epoch: 'period-lateAntiquity-name',
                description: 'hungary_site20_description',
                keywords: 'hungary_site20_keywords',
                gps: [46.66094189091088, 17.138282]
            },
            {
                img: "../images/sites/hungary/thumbs/hungary_site21_p1.jpg",
                images: [
                    {
                        src: "../images/sites/hungary/hungary_site21_p1.jpg",
                        thumb: "../images/sites/hungary/thumbs/hungary_site21_p1.jpg",
                        reference: "hungary_site21_img1_reference",
                        description: "hungary_site21_img1_description"
                    },
                    {
                        src: "../images/sites/hungary/hungary_site21_p2.jpg",
                        thumb: "../images/sites/hungary/thumbs/hungary_site21_p2.jpg",
                        reference: "hungary_site21_img2_reference",
                        description: "hungary_site21_img2_description"
                    }
                ],
                url: "/",
                country: 'hu',
                name: 'hungary_site21_name',
                epoch: 'period-lateAntiquity-name',
                description: 'hungary_site21_description',
                keywords: 'hungary_site21_keywords',
                gps: [47.52826980095758, 21.721368316107267]
            },


        ]
    },

    {
        id: 2,
        name: 'countries-czech-name',
        url: 'https://constructarcade.com/game/barista-express/game/',
        content: 'cz',
        image: "",
        shortcut: 'cz',
        models: []
    },

    {
        id: 3,
        name: 'countries-croatia-name',
        shortcut: 'hr',
        flag: flag_hr,
        url: 'https://daydev.itch.io/xrpunch',
        content: "croatia_description",
        image: "../images/countries/croatia.jpg",
        models: [
            {
                img: "../images/sites/croatia/thumbs/croatia_site1_p1.jpg",
                images: [
                    {
                        src: "../images/sites/croatia/croatia_site1_p1.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site1_p1.jpg",
                        reference: "croatia_site1_img1_reference",
                        description: "croatia_site1_img1_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site1_p2.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site1_p2.jpg",
                        reference: "croatia_site1_img2_description",
                        description: "croatia_site1_img2_name"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site1_p3.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site1_p3.jpg",
                        reference: "croatia_site1_img3_reference",
                        description: "croatia_site1_img3_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site1_p4.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site1_p4.jpg",
                        reference: "croatia_site1_img4_reference",
                        description: "croatia_site1_img4_description"
                    }
                ],
                url: "/",
                country: 'hr',
                name: 'croatia_site1_name',
                epoch: 'period-paleolithic-name',
                description: 'croatia_site1_description',
                keywords: 'croatia_site1_keywords',
                gps: [46.16486373075935, 15.863488869457047]
            },

            {
                img: "../images/sites/croatia/thumbs/croatia_site2_p1.jpg",
                images: [
                    {
                        src: "../images/sites/croatia/croatia_site2_p1.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site2_p1.jpg",
                        reference: "croatia_site2_img1_reference",
                        description: "croatia_site2_img1_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site2_p2.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site2_p2.jpg",
                        reference: "croatia_site2_img2_reference",
                        description: "croatia_site2_img2_description"
                    }
                ],
                url: "/",
                country: 'hr',
                name: 'croatia_site2_name',
                epoch: 'period-paleolithic-name',
                description: 'croatia_site2_description',
                keywords: 'croatia_site2_keywords',
                gps: [45.84139584458237, 15.87402903431228]
            },

            {
                img: "../images/sites/croatia/thumbs/croatia_site3_p1.jpg",
                images: [
                    {
                        src: "../images/sites/croatia/croatia_site3_p1.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site3_p1.jpg",
                        reference: "croatia_site3_img1_reference",
                        description: "croatia_site3_img1_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site3_p2.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site3_p2.jpg",
                        reference: "croatia_site3_img1_reference",
                        description: "croatia_site3_img2_description",
                    },
                    {
                        src: "../images/sites/croatia/croatia_site3_p3.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site3_p3.jpg",
                        reference: "croatia_site3_img3_reference",
                        description: "croatia_site3_img3_description"
                    }
                ],
                url: "/",
                country: 'hr',
                name: 'croatia_site3_name',
                epoch: 'period-paleolithic-name',
                description: 'croatia_site3_description',
                keywords: 'croatia_site3_keywords',
                gps: [46.284884468555795, 16.032376642476056]
            },

            {
                img: "../images/sites/croatia/thumbs/croatia_site4_p1.jpg",
                images: [
                    {
                        src: "../images/sites/croatia/croatia_site4_p1.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site4_p1.jpg",
                        reference: "croatia_site4_img1_reference",
                        description: "croatia_site4_img1_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site4_p2.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site4_p2.jpg",
                        reference: "croatia_site4_img2_reference",
                        description: "croatia_site4_img2_description",
                    },
                    {
                        src: "../images/sites/croatia/croatia_site4_p3.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site4_p3.jpg",
                        reference: "croatia_site4_img3_reference",
                        description: "croatia_site4_img3_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site4_p4.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site4_p4.jpg",
                        reference: "croatia_site4_img4_reference",
                        description: "croatia_site4_img4_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site4_p5.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site4_p5.jpg",
                        reference: "croatia_site4_img5_reference",
                        description: "croatia_site4_img5_description",
                    },
                    {
                        src: "../images/sites/croatia/croatia_site4_p6.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site4_p6.jpg",
                        reference: "croatia_site4_img6_reference",
                        description: "croatia_site4_img6_description"
                    }
                ],
                url: "/",
                country: 'hr',
                name: 'croatia_site4_name',
                epoch: 'period-paleolithic-name',
                description: 'croatia_site4_description',
                keywords: 'croatia_site4_keywords',
                gps: [46.30426179773131, 16.080786718606884]
            },

            {
                img: "../images/sites/croatia/thumbs/croatia_site5_p1.jpg",
                images: [
                    {
                        src: "../images/sites/croatia/croatia_site5_p1.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site5_p1.jpg",
                        reference: "croatia_site5_img1_reference",
                        description: "croatia_site5_img1_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site5_p2.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site5_p2.jpg",
                        reference: "croatia_site5_img2_reference",
                        description: "croatia_site5_img2_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site5_p3.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site5_p3.jpg",
                        reference: "croatia_site5_img3_reference",
                        description: "croatia_site5_img3_description"
                    }
                ],
                url: "/",
                country: 'hr',
                name: 'croatia_site5_name',
                epoch: 'period-neolithic-name',
                description: 'croatia_site5_description',
                keywords: 'croatia_site5_keywords',
                gps: [45.27208980562762, 17.375017601920337]
            },

            {
                img: "../images/sites/croatia/thumbs/croatia_site6_p1.jpg",
                images: [
                    {
                        src: "../images/sites/croatia/croatia_site6_p1.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site6_p1.jpg",
                        reference: "croatia_site6_img1_reference",
                        description: "croatia_site6_img1_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site6_p2.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site6_p2.jpg",
                        reference: "croatia_site6_img2_reference",
                        description: "croatia_site6_img2_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site6_p3.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site6_p3.jpg",
                        reference: "croatia_site6_img3_reference",
                        description: "croatia_site6_img3_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site6_p4.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site6_p4.jpg",
                        reference: "croatia_site6_img4_reference",
                        description: "croatia_site6_img4_description"
                    }
                ],
                url: "/",
                country: 'hr',
                name: 'croatia_site6_name',
                epoch: 'period-neolithic-name',
                description: 'croatia_site6_description',
                keywords: 'croatia_site6_keywords',
                gps: [45.2645981968789, 18.768174884803773]
            },

            {
                img: "../images/sites/croatia/thumbs/croatia_site7_p1.jpg",
                images: [
                    {
                        src: "../images/sites/croatia/croatia_site7_p1.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site7_p1.jpg",
                        reference: "croatia_site7_img1_reference",
                        description: "croatia_site7_img1_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site7_p2.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site7_p2.jpg",
                        reference: "croatia_site7_img2_reference",
                        description: "croatia_site7_img2_description"
                    }
                ],
                url: "/",
                country: 'hr',
                name: 'croatia_site7_name',
                epoch: 'period-neolithic-name',
                description: 'croatia_site7_description',
                keywords: 'croatia_site7_keywords',
                gps: [45.3933123701932, 18.375399786275914]
            },

            {
                img: "../images/sites/croatia/thumbs/croatia_site8_p1.jpg",
                images: [
                    {
                        src: "../images/sites/croatia/croatia_site8_p1.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site8_p1.jpg",
                        reference: "croatia_site8_img1_reference",
                        description: "croatia_site8_img1_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site8_p2.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site8_p2.jpg",
                        reference: "croatia_site8_img2_reference",
                        description: "croatia_site8_img2_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site8_p3.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site8_p3.jpg",
                        reference: "croatia_site8_img3_reference",
                        description: "croatia_site8_img3_description"
                    }
                ],
                url: "/",
                country: 'hr',
                name: 'croatia_site8_name',
                epoch: 'period-neolithic-name',
                description: 'croatia_site8_description',
                keywords: 'croatia_site8_keywords',
                gps: [42.969830471622, 16.718415328894878]
            },
            {
                img: "../images/sites/croatia/thumbs/croatia_site9_p1.jpg",
                images: [
                    {
                        src: "../images/sites/croatia/croatia_site9_p1.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site9_p1.jpg",
                        reference: "croatia_site9_img1_reference",
                        description: "croatia_site9_img1_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site9_p2.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site9_p2.jpg",
                        reference: "croatia_site9_img2_reference",
                        description: "croatia_site9_img2_description"
                    }
                ],
                url: "/",
                country: 'hr',
                name: 'croatia_site9_name',
                epoch: 'period-copperAge-name',
                description: 'croatia_site9_description',
                keywords: 'croatia_site9_keywords',
                gps: [45.522194542847416, 15.871622232252339]
            },
            {
                img: "../images/sites/croatia/thumbs/croatia_site10_p1.jpg",
                images: [
                    {
                        src: "../images/sites/croatia/croatia_site10_p1.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site10_p1.jpg",
                        reference: "croatia_site10_img1_reference",
                        description: "croatia_site10_img1_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site10_p2.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site10_p2.jpg",
                        reference: "croatia_site10_img2_reference",
                        description: "croatia_site10_img2_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site10_p3.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site10_p3.jpg",
                        reference: "croatia_site10_img3_reference",
                        description: "croatia_site10_img3_description"
                    }
                ],
                url: "/",
                country: 'hr',
                name: 'croatia_site10_name',
                epoch: 'period-copperAge-name',
                description: 'croatia_site10_description',
                keywords: 'croatia_site10_keywords',
                gps: [45.33622921110932, 19.0596886481542]
            },
            {
                img: "../images/sites/croatia/thumbs/croatia_site11_p1.jpg",
                images: [
                    {
                        src: "../images/sites/croatia/croatia_site11_p1.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site11_p1.jpg",
                        reference: "croatia_site11_img1_reference",
                        description: "croatia_site11_img1_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site11_p2.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site11_p2.jpg",
                        reference: "croatia_site11_img2_reference",
                        description: "croatia_site11_img2_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site11_p3.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site11_p3.jpg",
                        reference: "croatia_site11_img3_reference",
                        description: "croatia_site11_img3_description"
                    }
                ],
                url: "/",
                country: 'hr',
                name: 'croatia_site11_name',
                epoch: 'period-copperAge-name',
                description: 'croatia_site11_description',
                keywords: 'croatia_site11_keywords',
                gps: [45.53602238033354, 18.83677643937417]
            },
            {
                img: "../images/sites/croatia/thumbs/croatia_site12_p1.jpg",
                images: [
                    {
                        src: "../images/sites/croatia/croatia_site12_p1.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site12_p1.jpg",
                        reference: "croatia_site12_img1_reference",
                        description: "croatia_site12_img1_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site12_p2.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site12_p2.jpg",
                        reference: "croatia_site12_img2_reference",
                        description: "croatia_site12_img2_description"
                    }
                ],
                url: "/",
                country: 'hr',
                name: 'croatia_site12_name',
                epoch: 'period-bronzeAge-name',
                description: 'croatia_site12_description',
                keywords: 'croatia_site12_keywords',
                gps: [45.22841475425569, 19.172375931797713]
            },
            {
                img: "../images/sites/croatia/thumbs/croatia_site13_p1.jpg",
                images: [
                    {
                        src: "../images/sites/croatia/croatia_site13_p1.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site13_p1.jpg",
                        reference: "croatia_site13_img1_reference",
                        description: "croatia_site13_img1_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site13_p2.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site13_p2.jpg",
                        reference: "croatia_site13_img2_reference",
                        description: "croatia_site13_img2_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site13_p3.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site13_p3.jpg",
                        reference: "croatia_site13_img3_reference",
                        description: "croatia_site13_img3_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site13_p4.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site13_p4.jpg",
                        reference: "croatia_site13_img4_reference",
                        description: "croatia_site13_img4_description"
                    }
                ],
                url: "/",
                country: 'hr',
                name: 'croatia_site13_name',
                epoch: 'period-bronzeAge-name',
                description: 'croatia_site13_description',
                keywords: 'croatia_site13_keywords',
                gps: [45.402949826658684, 17.711465253733795]
            },
            {
                img: "../images/sites/croatia/thumbs/croatia_site14_p1.jpg",
                images: [
                    {
                        src: "../images/sites/croatia/croatia_site14_p1.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site14_p1.jpg",
                        reference: "croatia_site14_img1_reference",
                        description: "croatia_site14_img1_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site14_p2.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site14_p2.jpg",
                        reference: "croatia_site14_img2_reference",
                        description: "croatia_site14_img2_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site14_p3.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site14_p3.jpg",
                        reference: "croatia_site14_img3_reference",
                        description: "croatia_site14_img3_description"
                    }
                ],
                url: "/",
                country: 'hr',
                name: 'croatia_site14_name',
                epoch: 'period-bronzeAge-name',
                description: 'croatia_site14_description',
                keywords: 'croatia_site14_keywords',
                gps: [46.13258272608497, 16.436457345332116]
            },
            {
                img: "../images/sites/croatia/thumbs/croatia_site15_p1.jpg",
                images: [
                    {
                        src: "../images/sites/croatia/croatia_site15_p1.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site15_p1.jpg",
                        reference: "croatia_site15_img1_reference",
                        description: "croatia_site15_img1_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site15_p2.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site15_p2.jpg",
                        reference: "croatia_site15_img2_reference",
                        description: "croatia_site15_img2_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site15_p3.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site15_p3.jpg",
                        reference: "croatia_site15_img3_reference",
                        description: "croatia_site15_img3_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site15_p4.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site15_p4.jpg",
                        reference: "croatia_site15_img4_reference",
                        description: "croatia_site15_img4_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site15_p5.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site15_p5.jpg",
                        reference: "croatia_site15_img5_reference",
                        description: "croatia_site15_img5_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site15_p6.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site15_p6.jpg",
                        reference: "croatia_site15_img6_reference",
                        description: "croatia_site15_img6_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site15_p7.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site15_p7.jpg",
                        reference: "croatia_site15_img7_reference",
                        description: "croatia_site15_img7_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site15_p8.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site15_p8.jpg",
                        reference: "croatia_site15_img8_reference",
                        description: "croatia_site15_img8_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site15_p9.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site15_p9.jpg",
                        reference: "croatia_site15_img9_reference",
                        description: "croatia_site15_img9_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site15_p10.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site15_p10.jpg",
                        reference: "croatia_site15_img10_reference",
                        description: "croatia_site15_img10_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site15_p11.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site15_p11.jpg",
                        reference: "croatia_site15_img11_reference",
                        description: "croatia_site15_img11_description"
                    }
                ],
                url: "/",
                country: 'hr',
                name: 'croatia_site15_name',
                epoch: 'period-bronzeAge-name',
                description: 'croatia_site15_description',
                keywords: 'croatia_site15_keywords',
                gps: [45.31857873834763, 18.475665314585918]
            },
            {
                img: "../images/sites/croatia/thumbs/croatia_site16_p1.jpg",
                images: [
                    {
                        src: "../images/sites/croatia/croatia_site16_p1.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site16_p1.jpg",
                        reference: "croatia_site16_img1_reference",
                        description: "croatia_site16_img1_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site16_p2.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site16_p2.jpg",
                        reference: "croatia_site16_img2_reference",
                        description: "croatia_site16_img2_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site16_p3.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site16_p3.jpg",
                        reference: "croatia_site16_img3_reference",
                        description: "croatia_site16_img3_description"
                    }
                ],
                url: "/",
                country: 'hr',
                name: 'croatia_site16_name',
                epoch: 'period-ironAge-name',
                description: 'croatia_site16_description',
                keywords: 'croatia_site16_keywords',
                gps: [46.26055004009533, 16.489781027943472]
            },
            {
                img: "../images/sites/croatia/thumbs/croatia_site17_p1.jpg",
                images: [
                    {
                        src: "../images/sites/croatia/croatia_site17_p1.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site17_p1.jpg",
                        reference: "croatia_site17_img1_reference",
                        description: "croatia_site17_img1_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site17_p2.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site17_p2.jpg",
                        reference: "croatia_site17_img2_reference",
                        description: "croatia_site17_img2_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site17_p3.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site17_p3.jpg",
                        reference: "croatia_site17_img3_reference",
                        description: "croatia_site17_img3_description"
                    }
                ],
                url: "/",
                country: 'hr',
                name: 'croatia_site17_name',
                epoch: 'period-ironAge-name',
                description: 'croatia_site17_description',
                keywords: 'croatia_site17_keywords',
                gps: [45.85242606517111, 18.848032024360606]
            },
            {
                img: "../images/sites/croatia/thumbs/croatia_site18_p1.jpg",
                images: [
                    {
                        src: "../images/sites/croatia/croatia_site18_p1.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site18_p1.jpg",
                        reference: "croatia_site18_img1_reference",
                        description: "croatia_site18_img1_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site18_p2.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site18_p2.jpg",
                        reference: "croatia_site18_img2_reference",
                        description: "croatia_site18_img2_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site18_p3.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site18_p3.jpg",
                        reference: "croatia_site18_img3_reference",
                        description: "croatia_site18_img3_description"
                    }
                ],
                url: "/",
                country: 'hr',
                name: 'croatia_site18_name',
                epoch: 'period-ironAge-name',
                description: 'croatia_site18_description',
                keywords: 'croatia_site18_keywords',
                gps: [45.78935025817392, 15.497440151048963]
            },
            {
                img: "../images/sites/croatia/thumbs/croatia_site19_p1.jpg",
                images: [
                    {
                        url: "jIQIv_RbHec",
                        thumb: "../images/sites/croatia/thumbs/croatia_site19_v1.webp",
                        reference: "croatia_site19_video1_reference",
                        description: "croatia_site19_video1_description",
                        provider: "youtube"
                    },

                    {
                        src: "../images/sites/croatia/croatia_site19_p1.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site19_p1.jpg",
                        reference: "croatia_site19_img1_reference",
                        description: "croatia_site19_img1   _description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site19_p2.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site19_p2.jpg",
                        reference: "croatia_site19_img2_reference",
                        description: "croatia_site19_img2_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site19_p3.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site19_p3.jpg",
                        reference: "croatia_site19_img3_reference",
                        description: "croatia_site19_img3_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site19_p4.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site19_p4.jpg",
                        reference: "croatia_site19_img4_reference",
                        description: "croatia_site19_img4_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site19_p5.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site19_p5.jpg",
                        reference: "croatia_site19_img5_reference",
                        description: "croatia_site19_img5_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site19_p6.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site19_p6.jpg",
                        reference: "croatia_site19_img6_reference",
                        description: "croatia_site19_img6_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site19_p7.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site19_p7.jpg",
                        reference: "croatia_site19_img7_reference",
                        description: "croatia_site19_img7_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site19_p8.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site19_p8.jpg",
                        reference: "croatia_site19_img8_reference",
                        description: "croatia_site19_img8_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site19_p9.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site19_p9.jpg",
                        reference: "croatia_site19_img9_reference",
                        description: "croatia_site19_img9_description"
                    },
                ],
                url: "/",
                country: 'hr',
                name: 'croatia_site19_name',
                epoch: 'period-ironAge-name',
                description: 'croatia_site19_description',
                keywords: 'croatia_site19_keywords',
                gps: [45.461276223195355, 17.730202380634232]
            },
            {
                img: "../images/sites/croatia/thumbs/croatia_site20_p1.jpg",
                images: [

                    {
                        src: "../images/sites/croatia/croatia_site20_p1.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site20_p1.jpg",
                        reference: "croatia_site20_img1_reference",
                        description: "croatia_site20_img1_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site20_p2.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site20_p2.jpg",
                        reference: "croatia_site20_img2_reference",
                        description: "croatia_site20_img2_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site20_p3.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site20_p3.jpg",
                        reference: "croatia_site20_img3_reference",
                        description: "croatia_site20_img3_description"
                    }
                ],
                url: "/",
                country: 'hr',
                name: 'croatia_site20_name',
                epoch: 'period-romanPeriod-name',
                description: 'croatia_site20_description',
                keywords: 'croatia_site20_keywords',
                gps: [45.489632988111666, 16.37167339085924]
            },
            {
                img: "../images/sites/croatia/thumbs/croatia_site21_p1.jpg",
                images: [
                    {
                        src: "../images/sites/croatia/croatia_site21_p1.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site21_p1.jpg",
                        reference: "croatia_site21_img1_reference",
                        description: "croatia_site21_img1_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site21_p2.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site21_p2.jpg",
                        reference: "croatia_site21_img2_reference",
                        description: "croatia_site21_img2_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site21_p3.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site21_p3.jpg",
                        reference: "croatia_site21_img3_reference",
                        description: "croatia_site21_img3_description"
                    }
                ],
                url: "/",
                country: 'hr',
                name: 'croatia_site21_name',
                epoch: 'period-romanPeriod-name',
                description: 'croatia_site21_description',
                keywords: 'croatia_site21_keywords',
                gps: [45.77365689855963, 16.117528832061158]
            },
            {
                img: "../images/sites/croatia/thumbs/croatia_site22_p1.jpg",
                images: [
                    {
                        src: "../images/sites/croatia/croatia_site22_p1.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site22_p1.jpg",
                        reference: "croatia_site22_img1_reference",
                        description: "croatia_site22_img1_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site22_p2.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site22_p2.jpg",
                        reference: "croatia_site22_img2_reference",
                        description: "croatia_site22_img2_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site22_p3.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site22_p3.jpg",
                        reference: "croatia_site22_img3_reference",
                        description: "croatia_site22_img3_description"
                    }
                ],
                url: "/",
                country: 'hr',
                name: 'croatia_site22_name',
                epoch: 'period-romanPeriod-name',
                description: 'croatia_site22_description',
                keywords: 'croatia_site22_keywords',
                gps: [46.21086083211518, 16.42338344838476]
            },
            {
                img: "../images/sites/croatia/thumbs/croatia_site23_p1.jpg",
                images: [
                    {
                        src: "../images/sites/croatia/croatia_site23_p1.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site23_p1.jpg",
                        reference: "croatia_site23_img1_reference",
                        description: "croatia_site23_img1_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site23_p2.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site23_p2.jpg",
                        reference: "croatia_site23_img2_reference",
                        description: "croatia_site23_img2_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site23_p3.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site23_p3.jpg",
                        reference: "croatia_site23_img3_reference",
                        description: "croatia_site23_img3_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site23_p4.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site23_p4.jpg",
                        reference: "croatia_site23_img4_reference",
                        description: "croatia_site23_img4_description"
                    }
                ],
                url: "/",
                country: 'hr',
                name: 'croatia_site23_name',
                epoch: 'period-romanPeriod-name',
                description: 'croatia_site23_description',
                keywords: 'croatia_site23_keywords',
                gps: [44.87338832075654, 13.850069328736211]
            },
            {
                img: "../images/sites/croatia/thumbs/croatia_site24_p1.jpg",
                images: [
                    {
                        src: "../images/sites/croatia/croatia_site24_p1.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site24_p1.jpg",
                        reference: "croatia_site24_img1_reference",
                        description: "croatia_site24_img1_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site24_p2.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site24_p2.jpg",
                        reference: "croatia_site24_img2_reference",
                        description: "croatia_site24_img2_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site24_p3.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site24_p3.jpg",
                        reference: "croatia_site24_img3_reference",
                        description: "croatia_site24_img3_description"
                    }
                ],
                url: "/",
                country: 'hr',
                name: 'croatia_site24_name',
                epoch: 'period-lateAntiquity-name',
                description: 'croatia_site24_description',
                keywords: 'croatia_site24_keywords',
                gps: [45.22863145388377, 13.593369596211682]
            },
            {
                img: "../images/sites/croatia/thumbs/croatia_site25_p1.jpg",
                images: [
                    {
                        src: "../images/sites/croatia/croatia_site25_p1.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site25_p1.jpg",
                        reference: "croatia_site25_img1_reference",
                        description: "croatia_site25_img1_description"
                    }
                ],
                url: "/",
                country: 'hr',
                name: 'croatia_site25_name',
                epoch: 'period-lateAntiquity-name',
                description: 'croatia_site25_description',
                keywords: 'croatia_site25_keywords',
                gps: [43.508393046849214, 16.440205918154554]
            },
            {
                img: "../images/sites/croatia/thumbs/croatia_site26_p1.jpg",
                images: [
                    {
                        src: "../images/sites/croatia/croatia_site26_p1.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site26_p1.jpg",
                        reference: "croatia_site26_img1_reference",
                        description: "croatia_site26_img1_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site26_p2.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site26_p2.jpg",
                        reference: "croatia_site26_img2_reference",
                        description: "croatia_site26_img2_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site26_p3.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site26_p3.jpg",
                        reference: "croatia_site26_img3_reference",
                        description: "croatia_site26_img3_description"
                    }
                ],
                url: "/",
                country: 'hr',
                name: 'croatia_site26_name',
                epoch: 'period-lateAntiquity-name',
                description: 'croatia_site26_description',
                keywords: 'croatia_site26_keywords',
                gps: [45.28719713423253, 18.83327516216487]
            },
            {
                img: "../images/sites/croatia/thumbs/croatia_site27_p1.jpg",
                images: [
                    {
                        src: "../images/sites/croatia/croatia_site27_p1.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site27_p1.jpg",
                        reference: "croatia_site27_img1_reference",
                        description: "croatia_site27_img1_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site27_p2.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site26_p2.jpg",
                        reference: "croatia_site27_img2_reference",
                        description: "croatia_site27_img2_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site27_p3.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site26_p3.jpg",
                        reference: "croatia_site27_img3_reference",
                        description: "croatia_site27_img3_description"
                    }
                ],
                url: "/",
                country: 'hr',
                name: 'croatia_site27_name',
                epoch: 'period-lateAntiquity-name',
                description: 'croatia_site27_description',
                keywords: 'croatia_site27_keywords',
                gps: [45.297118492809965, 18.4175615043902]
            },
            {
                img: "../images/sites/croatia/thumbs/croatia_site28_p1.jpg",
                images: [
                    {
                        src: "../images/sites/croatia/croatia_site28_p1.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site28_p1.jpg",
                        reference: "croatia_site28_img1_reference",
                        description: "croatia_site28_img1_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site28_p2.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site28_p2.jpg",
                        reference: "croatia_site28_img2_reference",
                        description: "croatia_site28_img2_description"
                    }
                ],
                url: "/",
                country: 'hr',
                name: 'croatia_site28_name',
                epoch: 'period-middleAge-name',
                description: 'croatia_site28_description',
                keywords: 'croatia_site28_keywords',
                gps: [43.515880541657836, 16.927645329030828]
            },
            {
                img: "../images/sites/croatia/thumbs/croatia_site29_p1.jpg",
                images: [
                    {
                        src: "../images/sites/croatia/croatia_site29_p1.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site29_p1.jpg",
                        reference: "croatia_site29_img1_reference",
                        description: "croatia_site29_img1_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site29_p2.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site29_p2.jpg",
                        reference: "croatia_site29_img2_reference",
                        description: "croatia_site29_img2_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site29_p3.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site29_p3.jpg",
                        reference: "croatia_site29_img3_reference",
                        description: "croatia_site29_img3_description"
                    }
                ],
                url: "/",
                country: 'hr',
                name: 'croatia_site29_name',
                epoch: 'period-middleAge-name',
                description: 'croatia_site29_description',
                keywords: 'croatia_site29_keywords',
                gps: [45.3895702734069, 17.4551343580839]
            },
            {
                img: "../images/sites/croatia/thumbs/croatia_site30_p1.jpg",
                images: [
                    {
                        src: "../images/sites/croatia/croatia_site30_p1.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site30_p1.jpg",
                        reference: "croatia_site30_img1_reference",
                        description: "croatia_site30_img1_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site30_p2.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site30_p2.jpg",
                        reference: "croatia_site30_img2_reference",
                        description: "croatia_site39_img2_description"
                    }
                ],
                url: "/",
                country: 'hr',
                name: 'croatia_site30_name',
                epoch: 'period-middleAge-name',
                description: 'croatia_site30_description',
                keywords: 'croatia_site30_keywords',
                gps: [46.15471911947574, 15.651450740620868]
            },
            {
                img: "../images/sites/croatia/thumbs/croatia_site31_p1.jpg",
                images: [
                    {
                        src: "../images/sites/croatia/croatia_site31_p1.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site31_p1.jpg",
                        reference: "croatia_site31_img1_reference",
                        description: "croatia_site31_img1_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site31_p2.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site31_p2.jpg",
                        reference: "croatia_site31_img2_reference",
                        description: "croatia_site31_img2_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site31_p3.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site31_p3.jpg",
                        reference: "croatia_site31_img3_reference",
                        description: "croatia_site31_img3_description"
                    }
                ],
                url: "/",
                country: 'hr',
                name: 'croatia_site31_name',
                epoch: 'period-middleAge-name',
                description: 'croatia_site31_description',
                keywords: 'croatia_site31_keywords',
                gps: [45.559150362265164, 18.697942036183612]
            },
            {
                img: "../images/sites/croatia/thumbs/croatia_site32_p1.jpg",
                images: [
                    {
                        src: "../images/sites/croatia/croatia_site32_p1.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site32_p1.jpg",
                        reference: "croatia_site32_img1_reference",
                        description: "croatia_site32_img1_description"
                    },
                    {
                        src: "../images/sites/croatia/croatia_site32_p2.jpg",
                        thumb: "../images/sites/croatia/thumbs/croatia_site32_p2.jpg",
                        reference: "croatia_site32_img2_reference",
                        description: "croatia_site32_img2_description"
                    }
                ],
                url: "/",
                country: 'hr',
                name: 'croatia_site32_name',
                epoch: 'period-middleAge-name',
                description: 'croatia_site32_description',
                keywords: 'croatia_site32_keywords',
                gps: [43.97322458287814, 16.42709611587527]
            }


        ]
    },

    {
        id: 4,
        name: 'countries-romania-name',
        shortcut: 'ro',
        flag: flag_ro,
        url: 'https://kodub.itch.io/krossa',
        content: 'romania_description',
        image: "../images/countries/romania.jpg",
        models: [
            {
                img: "../images/sites/romania/thumbs/romania_site1_p1.jpg",
                images: [
                    {
                        src: "../images/sites/romania/romania_site1_p1.jpg",
                        thumb: "../images/sites/romania/thumbs/romania_site1_p1.jpg",
                        reference: "romania_site1_img1_reference",
                        description: "romania_site1_img1_description"
                    }
                ],
                url: "/",
                country: 'ro',
                name: 'romania_site1_name',
                epoch: 'period-paleolithic-name',
                description: 'romania_site1_description',
                keywords: 'romania_site1_keywords',
                gps: [44.628187, 22.603015]
            },
            {
                img: "../images/sites/romania/thumbs/romania_site2_p1.jpg",
                images: [
                    {
                        src: "../images/sites/romania/romania_site2_p1.jpg",
                        thumb: "../images/sites/romania/thumbs/romania_site2_p1.jpg",
                        reference: "romania_site2_img1_reference",
                        description: "romania_site2_img1_description"
                    }
                ],
                url: "/",
                country: 'ro',
                name: 'romania_site2_name',
                epoch: 'period-paleolithic-name',
                description: 'romania_site2_description',
                keywords: 'romania_site2_keywords',
                gps: [44.595403, 22.255698]
            },
            {
                img: "../images/sites/romania/thumbs/romania_site3_p1.jpg",
                images: [
                    {
                        src: "../images/sites/romania/romania_site3_p1.jpg",
                        thumb: "../images/sites/romania/thumbs/romania_site3_p1.jpg",
                        reference: "romania_site3_img1_reference",
                        description: "romania_site3_img1_description"
                    }
                ],
                url: "/",
                country: 'ro',
                name: 'romania_site3_name',
                epoch: 'period-neolithic-name',
                description: 'romania_site3_description',
                keywords: 'romania_site3_keywords',
                gps: [43.867360, 24.368675]
            },
            {
                img: "../images/sites/romania/thumbs/romania_site4_p1.jpg",
                images: [
                    {
                        src: "../images/sites/romania/romania_site4_p1.jpg",
                        thumb: "../images/sites/romania/thumbs/romania_site4_p1.jpg",
                        reference: "romania_site4_img1_reference",
                        description: "romania_site4_img1_description"
                    },
                    {
                        src: "../images/sites/romania/romania_site4_p2.jpg",
                        thumb: "../images/sites/romania/thumbs/romania_site4_p2.jpg",
                        reference: "romania_site4_img2_reference",
                        description: "romania_site4_img2_description"
                    },
                    {
                        src: "../images/sites/romania/romania_site4_p3.jpg",
                        thumb: "../images/sites/romania/thumbs/romania_site4_p3.jpg",
                        reference: "romania_site4_img3_reference",
                        description: "romania_site4_img3_description"
                    }
                ],
                url: "/",
                country: 'ro',
                name: 'romania_site4_name',
                epoch: 'period-neolithic-name',
                description: 'romania_site4_description',
                keywords: 'romania_site4_keywords',
                gps: [44.351540, 28.032030]
            },
            {
                img: "../images/sites/romania/thumbs/romania_site5_p1.jpg",
                images: [
                    {
                        src: "../images/sites/romania/romania_site5_p1.jpg",
                        thumb: "../images/sites/romania/thumbs/romania_site5_p1.jpg",
                        reference: "romania_site5_img1_reference",
                        description: "romania_site5_img1_description"
                    }
                ],
                url: "/",
                country: 'ro',
                name: 'romania_site5_name',
                epoch: 'period-neolithic-name',
                description: 'romania_site5_description',
                keywords: 'romania_site5_keywords',
                gps: [43.860265, 23.279709]
            },
            {
                img: "../images/sites/romania/thumbs/romania_site6_p1.jpg",
                images: [
                    {
                        src: "../images/sites/romania/romania_site6_p1.jpg",
                        thumb: "../images/sites/romania/thumbs/romania_site6_p1.jpg",
                        reference: "romania_site6_img1_reference",
                        description: "romania_site6_img1_description"
                    }
                ],
                url: "/",
                country: 'ro',
                name: 'romania_site6_name',
                epoch: 'period-copperAge-name',
                description: 'romania_site6_description',
                keywords: 'romania_site6_keywords',
                gps: [43.998226, 25.425139]
            },
            {
                img: "../images/sites/romania/thumbs/romania_site7_p1.jpg",
                images: [
                    {
                        src: "../images/sites/romania/romania_site7_p1.jpg",
                        thumb: "../images/sites/romania/thumbs/romania_site7_p1.jpg",
                        reference: "romania_site7_img1_reference",
                        description: "romania_site7_img1_description"
                    }
                ],
                url: "/",
                country: 'ro',
                name: 'romania_site7_name',
                epoch: 'period-copperAge-name',
                description: 'romania_site7_description',
                keywords: 'romania_site7_keywords',
                gps: [44.116374, 26.667713]
            },
            {
                img: "../images/sites/romania/thumbs/romania_site8_p1.jpg",
                images: [
                    {
                        src: "../images/sites/romania/romania_site8_p1.jpg",
                        thumb: "../images/sites/romania/thumbs/romania_site8_p1.jpg",
                        reference: "romania_site8_img1_reference",
                        description: "romania_site8_img1_description"
                    }
                ],
                url: "/",
                country: 'ro',
                name: 'romania_site8_name',
                epoch: 'period-copperAge-name',
                description: 'romania_site8_description',
                keywords: 'romania_site8_keywords',
                gps: [44.261356, 26.869209]
            },
            {
                img: "../images/sites/romania/thumbs/romania_site9_p1.jpg",
                images: [
                    {
                        src: "../images/sites/romania/romania_site9_p1.jpg",
                        thumb: "../images/sites/romania/thumbs/romania_site9_p1.jpg",
                        reference: "romania_site9_img1_reference",
                        description: "romania_site9_img1_description"
                    }
                ],
                url: "/",
                country: 'ro',
                name: 'romania_site9_name',
                epoch: 'period-ironAge-name',
                description: 'romania_site9_description',
                keywords: 'romania_site9_keywords',
                gps: [44.034661, 23.043121]
            },
            {
                img: "../images/sites/romania/thumbs/romania_site10_p1.jpg",
                images: [
                    {
                        src: "../images/sites/romania/romania_site10_p1.jpg",
                        thumb: "../images/sites/romania/thumbs/romania_site10_p1.jpg",
                        reference: "romania_site10_img1_reference",
                        description: "romania_site10_img1_description"
                    },
                    {
                        src: "../images/sites/romania/romania_site10_p2.jpg",
                        thumb: "../images/sites/romania/thumbs/romania_site10_p2.jpg",
                        reference: "romania_site10_img2_reference",
                        description: "romania_site10_img2_description"
                    }
                ],
                url: "/",
                country: 'ro',
                name: 'romania_site10_name',
                epoch: 'period-ironAge-name',
                description: 'romania_site10_description',
                keywords: 'romania_site10_keywords',
                gps: [43.720338, 25.548100]
            },
            {
                img: "../images/sites/romania/thumbs/romania_site11_p1.jpg",
                images: [
                    {
                        src: "../images/sites/romania/romania_site11_p1.jpg",
                        thumb: "../images/sites/romania/thumbs/romania_site11_p1.jpg",
                        reference: "romania_site11_img1_reference",
                        description: "romania_site11_img1_description"
                    }
                ],
                url: "/",
                country: 'ro',
                name: 'romania_site11_name',
                epoch: 'period-ironAge-name',
                description: 'romania_site11_description',
                keywords: 'romania_site11_keywords',
                gps: [44.241274, 28.308244]
            },
            {
                img: "../images/sites/romania/thumbs/romania_site12_p1.jpg",
                images: [
                    {
                        src: "../images/sites/romania/romania_site12_p1.jpg",
                        thumb: "../images/sites/romania/thumbs/romania_site12_p1.jpg",
                        reference: "romania_site12_img1_reference",
                        description: "romania_site12_img1_description"
                    }
                ],
                url: "/",
                country: 'ro',
                name: 'romania_site12_name',
                epoch: 'period-romanPeriod-name',
                description: 'romania_site12_description',
                keywords: 'romania_site12_keywords',
                gps: [44.046744, 25.131837]
            },
            {
                img: "../images/sites/romania/thumbs/romania_site13_p1.jpg",
                images: [
                    {
                        src: "../images/sites/romania/romania_site13_p1.jpg",
                        thumb: "../images/sites/romania/thumbs/romania_site13_p1.jpg",
                        reference: "romania_site13_img1_reference",
                        description: "romania_site13_img1_description"
                    }
                ],
                url: "/",
                country: 'ro',
                name: 'romania_site13_name',
                epoch: 'period-romanPeriod-name',
                description: 'romania_site13_description',
                keywords: 'romania_site13_keywords',
                gps: [45.028596, 28.904441]
            },
            {
                img: "../images/sites/romania/thumbs/romania_site14_p1.jpg",
                images: [
                    {
                        src: "../images/sites/romania/romania_site14_p1.jpg",
                        thumb: "../images/sites/romania/thumbs/romania_site14_p1.jpg",
                        reference: "romania_site14_img1_reference",
                        description: "romania_site14_img1_description"
                    },
                    {
                        src: "../images/sites/romania/romania_site14_p2.jpg",
                        thumb: "../images/sites/romania/thumbs/romania_site14_p2.jpg",
                        reference: "romania_site14_img2_reference",
                        description: "romania_site14_img2_description"
                    },
                    {
                        src: "../images/sites/romania/romania_site14_p3.jpg",
                        thumb: "../images/sites/romania/thumbs/romania_site14_p3.jpg",
                        reference: "romania_site14_img3_reference",
                        description: "romania_site14_img3_description"
                    }
                ],
                url: "/",
                country: 'ro',
                name: 'romania_site14_name',
                epoch: 'period-romanPeriod-name',
                description: 'romania_site14_description',
                keywords: 'romania_site14_keywords',
                gps: [44.547401, 28.774540]
            },
            {
                img: "../images/sites/romania/thumbs/romania_site15_p1.jpg",
                images: [
                    {
                        src: "../images/sites/romania/romania_site15_p1.jpg",
                        thumb: "../images/sites/romania/thumbs/romania_site15_p1.jpg",
                        reference: "romania_site15_img1_reference",
                        description: "romania_site15_img1_description"
                    },
                    {
                        src: "../images/sites/romania/romania_site15_p2.jpg",
                        thumb: "../images/sites/romania/thumbs/romania_site15_p2.jpg",
                        reference: "romania_site15_img2_reference",
                        description: "romania_site15_img2_description"
                    }
                ],
                url: "/",
                country: 'ro',
                name: 'romania_site15_name',
                epoch: 'period-romanPeriod-name',
                description: 'romania_site15_description',
                keywords: 'romania_site15_keywords',
                gps: [45.143358, 28.195076]
            },
            {
                img: "../images/sites/romania/thumbs/romania_site16_p1.jpg",
                images: [
                    {
                        src: "../images/sites/romania/romania_site16_p1.jpg",
                        thumb: "../images/sites/romania/thumbs/romania_site16_p1.jpg",
                        reference: "romania_site16_img1_reference",
                        description: "romania_site16_img1_description"
                    }
                ],
                url: "/",
                country: 'ro',
                name: 'romania_site16_name',
                epoch: 'period-romanPeriod-name',
                description: 'romania_site16_description',
                keywords: 'romania_site16_keywords',
                gps: [44.111079, 27.305805]
            },
            {
                img: "../images/sites/romania/thumbs/romania_site17_p1.jpg",
                images: [
                    {
                        src: "../images/sites/romania/romania_site17_p1.jpg",
                        thumb: "../images/sites/romania/thumbs/romania_site17_p1.jpg",
                        reference: "romania_site17_img1_reference",
                        description: "romania_site17_img1_description"
                    },
                    {
                        src: "../images/sites/romania/romania_site17_p2.jpg",
                        thumb: "../images/sites/romania/thumbs/romania_site17_p2.jpg",
                        reference: "romania_site17_img2_reference",
                        description: "romania_site17_img2_description"
                    },
                    {
                        src: "../images/sites/romania/romania_site17_p3.jpg",
                        thumb: "../images/sites/romania/thumbs/romania_site17_p3.jpg",
                        reference: "romania_site17_img3_reference",
                        description: "romania_site17_img3_description"
                    },
                    {
                        src: "../images/sites/romania/romania_site17_p4.jpg",
                        thumb: "../images/sites/romania/thumbs/romania_site17_p4.jpg",
                        reference: "romania_site17_img4_reference",
                        description: "romania_site17_img4_description"
                    }
                ],
                url: "/",
                country: 'ro',
                name: 'romania_site17_name',
                epoch: 'period-lateAntiquity-name',
                description: 'romania_site17_description',
                keywords: 'romania_site17_keywords',
                gps: [44.493831, 28.090339]
            },
            {
                img: "../images/sites/romania/thumbs/romania_site18_p1.jpg",
                images: [
                    {
                        src: "../images/sites/romania/romania_site18_p1.jpg",
                        thumb: "../images/sites/romania/thumbs/romania_site18_p1.jpg",
                        reference: "romania_site18_img1_reference",
                        description: "romania_site18_img1_description"
                    },
                    {
                        src: "../images/sites/romania/romania_site18_p2.jpg",
                        thumb: "../images/sites/romania/thumbs/romania_site18_p2.jpg",
                        reference: "romania_site18_img2_reference",
                        description: "romania_site18_img2_description"
                    }
                ],
                url: "/",
                country: 'ro',
                name: 'romania_site18_name',
                epoch: 'period-lateAntiquity-name',
                description: 'romania_site18_description',
                keywords: 'romania_site18_keywords',
                gps: [44.681586, 27.952128]
            },
            {
                img: "../images/sites/romania/thumbs/romania_site19_p1.jpg",
                images: [
                    {
                        src: "../images/sites/romania/romania_site19_p1.jpg",
                        thumb: "../images/sites/romania/thumbs/romania_site19_p1.jpg",
                        reference: "romania_site19_img1_reference",
                        description: "romania_site19_img1_description"
                    },
                    {
                        src: "../images/sites/romania/romania_site19_p2.jpg",
                        thumb: "../images/sites/romania/thumbs/romania_site19_p2.jpg",
                        reference: "romania_site19_img2_reference",
                        description: "romania_site19_img2_description"
                    }
                ],
                url: "/",
                country: 'ro',
                name: 'romania_site19_name',
                epoch: 'period-lateAntiquity-name',
                description: 'romania_site19_description',
                keywords: 'romania_site19_keywords',
                gps: [45.378991, 28.138783]
            },
            {
                img: "../images/sites/romania/thumbs/romania_site20_p1.jpg",
                images: [
                    {
                        src: "../images/sites/romania/romania_site20_p1.jpg",
                        thumb: "../images/sites/romania/thumbs/romania_site20_p1.jpg",
                        reference: "romania_site20_img1_reference",
                        description: "romania_site20_img1_description"
                    }
                ],
                url: "/",
                country: 'ro',
                name: 'romania_site20_name',
                epoch: 'period-middleAge-name',
                description: 'romania_site20_description',
                keywords: 'romania_site20_keywords',
                gps: [44.131161, 27.470879]
            },
            {
                img: "../images/sites/romania/thumbs/romania_site21_p1.jpg",
                images: [
                    {
                        src: "../images/sites/romania/romania_site21_p1.jpg",
                        thumb: "../images/sites/romania/thumbs/romania_site21_p1.jpg",
                        reference: "romania_site21_img1_reference",
                        description: "romania_site21_img1_description"
                    }
                ],
                url: "/",
                country: 'ro',
                name: 'romania_site21_name',
                epoch: 'period-middleAge-name',
                description: 'romania_site21_description',
                keywords: 'romania_site21_keywords',
                gps: [44.165369, 28.405199]
            },
            {
                img: "../images/sites/romania/thumbs/romania_site22_p1.jpg",
                images: [
                    {
                        src: "../images/sites/romania/romania_site22_p1.jpg",
                        thumb: "../images/sites/romania/thumbs/romania_site22_p1.jpg",
                        reference: "romania_site22_img1_reference",
                        description: "romania_site22_img1_description"
                    },
                    {
                        src: "../images/sites/romania/romania_site22_p1.jpg",
                        thumb: "../images/sites/romania/thumbs/romania_site22_p2.jpg",
                        reference: "romania_site22_img2_reference",
                        description: "romania_site22_img2_description"
                    }
                ],
                url: "/",
                country: 'ro',
                name: 'romania_site22_name',
                epoch: 'period-middleAge-name',
                description: 'romania_site22_description',
                keywords: 'romania_site22_keywords',
                gps: [45.151739, 28.919299]
            },

        ]
    },

    {
        id: 5,
        name: 'countries-slovenia-name',
        shortcut: 'si',
        flag: flag_sl,
        url: 'https://constructarcade.com/game/archery-training/',
        content: 'slovenia_description',
        image: "../images/countries/slovenia.jpg",
        models: [
            {
                img: "../images/sites/slovenia/thumbs/slovenia_site1_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovenia/slovenia_site1_p1.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site1_p1.jpg",
                        reference: "slovenia_site1_img1_reference",
                        description: "slovenia_site1_img1_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site1_p2.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site1_p2.jpg",
                        reference: "slovenia_site1_img2_reference",
                        description: "slovenia_site1_img2_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site1_p3.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site1_p3.jpg",
                        reference: "slovenia_site1_img3_reference",
                        description: "slovenia_site1_img3_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site1_p4.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site1_p4.jpg",
                        reference: "slovenia_site1_img4_reference",
                        description: "slovenia_site1_img4_description"
                    }
                ],
                url: "/",
                country: 'si',
                name: 'slovenia_site1_name',
                epoch: 'period-paleolithic-name',
                description: 'slovenia_site1_description',
                keywords: 'slovenia_site1_keywords',
                gps: [45.792506, 14.187588]
            },
            {
                img: "../images/sites/slovenia/thumbs/slovenia_site2_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovenia/slovenia_site2_p1.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site2_p1.jpg",
                        reference: "slovenia_site2_img1_reference",
                        description: "slovenia_site2_img1_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site2_p2.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site2_p2.jpg",
                        reference: "slovenia_site2_img2_reference",
                        description: "slovenia_site2_img2_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site2_p3.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site2_p3.jpg",
                        reference: "slovenia_site2_img3_reference",
                        description: "slovenia_site2_img3_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site2_p4.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site2_p4.jpg",
                        reference: "slovenia_site2_img4_reference",
                        description: "slovenia_site2_img4_description"
                    }
                ],
                url: "/",
                country: 'si',
                name: 'slovenia_site2_name',
                epoch: 'period-paleolithic-name',
                description: 'slovenia_site2_description',
                keywords: 'slovenia_site2_keywords',
                gps: [46.112234, 13.915457]
            },
            {
                img: "../images/sites/slovenia/thumbs/slovenia_site3_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovenia/slovenia_site3_p1.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site3_p1.jpg",
                        reference: "slovenia_site3_img1_reference",
                        description: "slovenia_site3_img1_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site3_p2.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site3_p2.jpg",
                        reference: "slovenia_site3_img2_reference",
                        description: "slovenia_site3_img2_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site3_p3.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site3_p3.jpg",
                        reference: "slovenia_site3_img3_reference",
                        description: "slovenia_site3_img3_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site3_p4.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site3_p4.jpg",
                        reference: "slovenia_site3_img4_reference",
                        description: "slovenia_site3_img4_description"
                    }
                ],
                url: "/",
                country: 'si',
                name: 'slovenia_site3_name',
                epoch: 'period-paleolithic-name',
                description: 'slovenia_site3_description',
                keywords: 'slovenia_site3_keywords',
                gps: [46.449333, 14.669046]
            },
            {
                img: "../images/sites/slovenia/slovenia_site4_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovenia/slovenia_site4_p1.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site4_p1.jpg",
                        reference: "slovenia_site4_img4_reference",
                        description: "slovenia_site4_img4_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site4_p2.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site4_p2.jpg",
                        reference: "slovenia_site4_img2_reference",
                        description: "slovenia_site4_img2_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site4_p3.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site4_p3.jpg",
                        reference: "slovenia_site4_img3_reference",
                        description: "slovenia_site4_img3_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site4_p3_1.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site4_p3_1.jpg",
                        reference: "slovenia_site4_img3_1_reference",
                        description: "slovenia_site4_img3_1_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site4_p4.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site4_p4.jpg",
                        reference: "slovenia_site4_img4_reference",
                        description: "slovenia_site4_img4_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site4_p4_1.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site4_p4_1.jpg",
                        reference: "slovenia_site4_img4_1_reference",
                        description: "slovenia_site4_img4_1_description"
                    }
                ],
                url: "/",
                country: 'si',
                name: 'slovenia_site4_name',
                epoch: 'period-paleolithic-name',
                description: 'slovenia_site4_description',
                keywords: 'slovenia_site4_keywords',
                gps: [45.960347, 14.309617]
            },
            {
                img: "../images/sites/site-placeholder.png",
                images: [],
                url: "/",
                country: 'si',
                name: 'slovenia_site5_name',
                epoch: 'period-paleolithic-name',
                description: 'slovenia_site5_description',
                keywords: 'slovenia_site5_keywords',
                gps: [45.853781, 13.943886]
            },
            {
                img: "../images/sites/slovenia/slovenia_site6_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovenia/slovenia_site6_p1.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site6_p1.jpg",
                        reference: "slovenia_site6_img1_reference",
                        description: "slovenia_site6_img1_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site6_p2.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site6_p2.jpg",
                        reference: "slovenia_site6_img2_reference",
                        description: "slovenia_site6_img2_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site6_p3.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site6_p3.jpg",
                        reference: "slovenia_site6_img3_reference",
                        description: "slovenia_site6_img3_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site6_p4.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site6_p4.jpg",
                        reference: "slovenia_site6_img4_reference",
                        description: "slovenia_site6_img4_description"
                    }
                ],
                url: "/",
                country: 'si',
                name: 'slovenia_site6_name',
                epoch: 'period-neolithic-name',
                description: 'slovenia_site6_description',
                keywords: 'slovenia_site6_keywords',
                gps: [45.88955, 15.609694]
            },
            {
                img: "../images/sites/slovenia/thumbs/slovenia_site7_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovenia/slovenia_site7_p1.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site7_p1.jpg",
                        reference: "slovenia_site7_img1_reference",
                        description: "slovenia_site7_img1_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site7_p2.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site7_p2.jpg",
                        reference: "slovenia_site7_img2_reference",
                        description: "slovenia_site7_img2_description"
                    }
                ],
                url: "/",
                country: 'si',
                name: 'slovenia_site7_name',
                epoch: 'period-neolithic-name',
                description: 'slovenia_site7_description',
                keywords: 'slovenia_site7_keywords',
                gps: [45.987818, 15.449234]
            },
            {
                img: "../images/sites/slovenia/thumbs/slovenia_site8_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovenia/slovenia_site8_p1.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site8_p1.jpg",
                        reference: "slovenia_site8_img1_reference",
                        description: "slovenia_site8_img1_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site8_p2.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site8_p2.jpg",
                        reference: "slovenia_site8_img2_reference",
                        description: "slovenia_site8_img2_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site8_p3.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site8_p3.jpg",
                        reference: "slovenia_site8_img3_reference",
                        description: "slovenia_site8_img3_description"
                    }
                ],
                url: "/",
                country: 'si',
                name: 'slovenia_site8_name',
                epoch: 'period-neolithic-name',
                description: 'slovenia_site8_description',
                keywords: 'slovenia_site8_keywords',
                gps: [46.102316, 14.592660]
            },
            {
                img: "../images/sites/slovenia/thumbs/slovenia_site9_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovenia/slovenia_site9_p1.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site9_p1.jpg",
                        reference: "slovenia_site9_img1_reference",
                        description: "slovenia_site9_img1_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site9_p2.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site9_p2.jpg",
                        reference: "slovenia_site9_img2_reference",
                        description: "slovenia_site9_img2_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site9_p3.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site9_p3.jpg",
                        reference: "slovenia_site9_img3_reference",
                        description: "slovenia_site9_img3_description"
                    }
                ],
                url: "/",
                country: 'si',
                name: 'slovenia_site9_name',
                epoch: 'period-neolithic-name',
                description: 'slovenia_site9_description',
                keywords: 'slovenia_site9_keywords',
                gps: [45.952469, 15.071675]
            },
            {
                img: "../images/sites/slovenia/thumbs/slovenia_site10_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovenia/slovenia_site10_p1.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site10_p1.jpg",
                        reference: "slovenia_site10_img1_reference",
                        description: "slovenia_site10_img1_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site10_p2.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site10_p2.jpg",
                        reference: "slovenia_site10_img2_reference",
                        description: "slovenia_site10_img2_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site10_p3.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site10_p3.jpg",
                        reference: "slovenia_site10_img3_reference",
                        description: "slovenia_site10_img3_description"
                    }
                ],
                url: "/",
                country: 'si',
                name: 'slovenia_site10_name',
                epoch: 'period-neolithic-name',
                description: 'slovenia_site10_description',
                keywords: 'slovenia_site10_keywords',
                gps: [45.674578, 13.957906]
            },
            {
                img: "../images/sites/slovenia/thumbs/slovenia_site11_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovenia/slovenia_site11_p1.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site11_p1.jpg",
                        reference: "slovenia_site11_img1_reference",
                        description: "slovenia_site11_img1_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site11_p2.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site11_p2.jpg",
                        reference: "slovenia_site11_img2_reference",
                        description: "slovenia_site11_img2_description"
                    }
                ],
                url: "/",
                country: 'si',
                name: 'slovenia_site11_name',
                epoch: 'period-copperAge-name',
                description: 'slovenia_site11_description',
                keywords: 'slovenia_site11_keywords',
                gps: [45.949534, 15.425724]
            },
            {
                img: "../images/sites/slovenia/thumbs/slovenia_site12_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovenia/slovenia_site12_p1.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site12_p1.jpg",
                        reference: "slovenia_site12_img1_reference",
                        description: "slovenia_site12_img1_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site12_p2.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site12_p2.jpg",
                        reference: "slovenia_site12_img2_reference",
                        description: "slovenia_site12_img2_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site12_p3.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site12_p3.jpg",
                        reference: "slovenia_site12_img3_reference",
                        description: "slovenia_site12_img3_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site12_p4.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site12_p4.jpg",
                        reference: "slovenia_site12_img4_reference",
                        description: "slovenia_site12_img4_description"
                    }
                ],
                url: "/",
                country: 'si',
                name: 'slovenia_site12_name',
                epoch: 'period-copperAge-name',
                description: 'slovenia_site12_description',
                keywords: 'slovenia_site12_keywords',
                gps: [45.979944, 14.523126]
            },
            {
                img: "../images/sites/slovenia/thumbs/slovenia_site13_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovenia/slovenia_site13_p1.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site13_p1.jpg",
                        reference: "slovenia_site13_img1_reference",
                        description: "slovenia_site13_img1_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site13_p2.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site13_p2.jpg",
                        reference: "slovenia_site13_img2_reference",
                        description: "slovenia_site13_img2_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site13_p3.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site13_p3.jpg",
                        reference: "slovenia_site13_img3_reference",
                        description: "slovenia_site13_img3_description"
                    }
                ],
                url: "/",
                country: 'si',
                name: 'slovenia_site13_name',
                epoch: 'period-copperAge-name',
                description: 'slovenia_site13_description',
                keywords: 'slovenia_site13_keywords',
                gps: [46.628458, 16.141492]
            },
            {
                img: "../images/sites/slovenia/thumbs/slovenia_site14_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovenia/slovenia_site14_p1.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site14_p1.jpg",
                        reference: "slovenia_site14_img1_reference",
                        description: "slovenia_site14_img1_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site14_p2.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site14_p2.jpg",
                        reference: "slovenia_site14_img2_reference",
                        description: "slovenia_site14_img2_description"
                    }
                ],
                url: "/",
                country: 'si',
                name: 'slovenia_site14_name',
                epoch: 'period-copperAge-name',
                description: 'slovenia_site14_description',
                keywords: 'slovenia_site14_keywords',
                gps: [45.632675, 15.220283]
            },
            {
                img: "../images/sites/slovenia/thumbs/slovenia_site15_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovenia/slovenia_site15_p1.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site15_p1.jpg",
                        reference: "slovenia_site15_img1_reference",
                        description: "slovenia_site15_img1_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site15_p2.jpeg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site15_p2.jpeg",
                        reference: "slovenia_site15_img2_reference",
                        description: "slovenia_site15_img2_description"
                    }
                ],
                url: "/",
                country: 'si',
                name: 'slovenia_site15_name',
                epoch: 'period-copperAge-name',
                description: 'slovenia_site15_description',
                keywords: 'slovenia_site15_keywords',
                gps: [46.537283, 15.61515]
            },
            {
                img: "../images/sites/slovenia/thumbs/slovenia_site16_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovenia/slovenia_site16_p1.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site16_p1.jpg",
                        reference: "slovenia_site16_img1_reference",
                        description: "slovenia_site16_img1_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site16_p2.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site16_p2.jpg",
                        reference: "slovenia_site16_img2_reference",
                        description: "slovenia_site16_img2_description"
                    }
                ],
                url: "/",
                country: 'si',
                name: 'slovenia_site16_name',
                epoch: 'period-bronzeAge-name',
                description: 'slovenia_site16_description',
                keywords: 'slovenia_site16_keywords',
                gps: [46.358093, 14.081012]
            },
            {
                img: "../images/sites/slovenia/thumbs/slovenia_site17_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovenia/slovenia_site17_p1.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site17_p1.jpg",
                        reference: "slovenia_site17_img1_reference",
                        description: "slovenia_site17_img1_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site17_p2.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site17_p2.jpg",
                        reference: "slovenia_site17_img2_reference",
                        description: "slovenia_site17_img2_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site17_p3.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site17_p3.jpg",
                        reference: "slovenia_site17_img3_reference",
                        description: "slovenia_site17_img3_description"
                    }
                ],
                url: "/",
                country: 'si',
                name: 'slovenia_site17_name',
                epoch: 'period-bronzeAge-name',
                description: 'slovenia_site17_description',
                keywords: 'slovenia_site17_keywords',
                gps: [46.376585, 15.407031]
            },
            {
                img: "../images/sites/slovenia/thumbs/slovenia_site18_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovenia/slovenia_site18_p1.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site18_p1.jpg",
                        reference: "slovenia_site18_img1_reference",
                        description: "slovenia_site18_img1_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site18_p2.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site18_p2.jpg",
                        reference: "slovenia_site18_img2_reference",
                        description: "slovenia_site18_img2_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site18_p3.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site18_p3.jpg",
                        reference: "slovenia_site18_img3_reference",
                        description: "slovenia_site18_img3_description"
                    }
                ],
                url: "/",
                country: 'si',
                name: 'slovenia_site18_name',
                epoch: 'period-bronzeAge-name',
                description: 'slovenia_site18_description',
                keywords: 'slovenia_site18_keywords',
                gps: [46.044389, 14.509485]
            },
            {
                img: "../images/sites/slovenia/thumbs/slovenia_site19_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovenia/slovenia_site19_p1.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site19_p1.jpg",
                        reference: "slovenia_site19_img1_reference",
                        description: "slovenia_site19_img1_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site19_p2.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site19_p2.jpg",
                        reference: "slovenia_site19_img2_reference",
                        description: "slovenia_site19_img2_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site19_p3.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site19_p3.jpg",
                        reference: "slovenia_site19_img3_reference",
                        description: "slovenia_site19_img3_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site19_p4.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site19_p4.jpg",
                        reference: "slovenia_site19_img4_reference",
                        description: "slovenia_site19_img4_description"
                    }
                ],
                url: "/",
                country: 'si',
                name: 'slovenia_site19_name',
                epoch: 'period-bronzeAge-name',
                description: 'slovenia_site19_description',
                keywords: 'slovenia_site19_keywords',
                gps: [45.659191, 13.982499]
            },
            {
                img: "../images/sites/slovenia/thumbs/slovenia_site20_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovenia/slovenia_site20_p1.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site20_p1.jpg",
                        reference: "slovenia_site20_img1_reference",
                        description: "slovenia_site20_img1_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site20_p2.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site20_p2.jpg",
                        reference: "slovenia_site20_img2_reference",
                        description: "slovenia_site20_img2_description"
                    }
                ],
                url: "/",
                country: 'si',
                name: 'slovenia_site20_name',
                epoch: 'period-bronzeAge-name',
                description: 'slovenia_site20_description',
                keywords: 'slovenia_site20_keywords',
                gps: [46.407224, 16.148750]
            },
            {
                img: "../images/sites/slovenia/thumbs/slovenia_site21_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovenia/slovenia_site21_p1.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site21_p1.jpg",
                        reference: "slovenia_site21_img1_reference",
                        description: "slovenia_site21_img1_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site21_p2.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site21_p2.jpg",
                        reference: "slovenia_site21_img2_reference",
                        description: "slovenia_site21_img2_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site21_p3.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site21_p3.jpg",
                        reference: "slovenia_site21_img3_reference",
                        description: "slovenia_site21_img3_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site21_p4.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site21_p4.jpg",
                        reference: "slovenia_site21_img4_reference",
                        description: "slovenia_site21_img4_description"
                    }
                    ,
                    {
                        src: "../images/sites/slovenia/slovenia_site21_p5.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site21_p5.jpg",
                        reference: "slovenia_site21_img5_reference",
                        description: "slovenia_site21_img5_description"
                    }
                    ,
                    {
                        src: "../images/sites/slovenia/slovenia_site21_p6.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site21_p6.jpg",
                        reference: "slovenia_site21_img6_reference",
                        description: "slovenia_site21_img6_description"
                    }
                    ,
                    {
                        src: "../images/sites/slovenia/slovenia_site21_p7.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site21_p7.jpg",
                        reference: "slovenia_site21_img7_reference",
                        description: "slovenia_site21_img7_description"
                    }
                    ,
                    {
                        src: "../images/sites/slovenia/slovenia_site21_p8.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site21_p8.jpg",
                        reference: "slovenia_site21_img8_reference",
                        description: "slovenia_site21_img8_description"
                    }
                ],
                url: "/",
                country: 'si',
                name: 'slovenia_site21_name',
                epoch: 'period-bronzeAge-name',
                description: 'slovenia_site21_description',
                keywords: 'slovenia_site21_keywords',
                gps: [45.950915, 14.758607]
            },
            {

                img: "../images/sites/slovenia/slovenia_site22_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovenia/slovenia_site22_p1.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site22_p1.jpg",
                        reference: "slovenia_site22_img1_reference",
                        description: "slovenia_site22_img1_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site22_p2.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site22_p2.jpg",
                        reference: "slovenia_site22_img2_reference",
                        description: "slovenia_site22_img2_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site22_p3.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site22_p3.jpg",
                        reference: "slovenia_site22_img3_reference",
                        description: "slovenia_site22_img3_description"
                    }
                ],
                url: "/",
                country: 'si',
                name: 'slovenia_site22_name',
                epoch: 'period-ironAge-name',
                description: 'slovenia_site22_description',
                keywords: 'slovenia_site22_keywords',
                gps: [45.757596, 15.045535]
            },
            {
                img: "../images/sites/slovenia/thumbs/slovenia_site23_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovenia/slovenia_site23_p1.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site23_p1.jpg",
                        reference: "slovenia_site23_img1_reference",
                        description: "slovenia_site23_img1_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site23_p2.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site23_p2.jpg",
                        reference: "slovenia_site23_img2_reference",
                        description: "slovenia_site23_img2_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site23_p3.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site23_p3.jpg",
                        reference: "slovenia_site23_img3_reference",
                        description: "slovenia_site23_img3_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site23_p4.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site23_p4.jpg",
                        reference: "slovenia_site23_img4_reference",
                        description: "slovenia_site23_img4_description"
                    }
                ],
                url: "/",
                country: 'si',
                name: 'slovenia_site23_name',
                epoch: 'period-ironAge-name',
                description: 'slovenia_site23_description',
                keywords: 'slovenia_site23_keywords',
                gps: [46.152565, 13.741764]
            },
            {
                img: "../images/sites/site-placeholder.png",
                images: [],
                url: "/",
                country: 'si',
                name: 'slovenia_site24_name',
                epoch: 'period-ironAge-name',
                description: 'slovenia_site24_description',
                keywords: 'slovenia_site24_keywords',
                gps: [46.523809, 15.619225]
            },
            {
                img: "../images/sites/slovenia/thumbs/slovenia_site25_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovenia/slovenia_site25_p1.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site25_p1.jpg",
                        reference: "slovenia_site25_img1_reference",
                        description: "slovenia_site25_img1_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site25_p2.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site25_p2.jpg",
                        reference: "slovenia_site25_img2_reference",
                        description: "slovenia_site25_img2_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site25_p3.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site25_p3.jpg",
                        reference: "slovenia_site25_img3_reference",
                        description: "slovenia_site25_img3_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site25_p4.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site25_p4.jpg",
                        reference: "slovenia_site25_img4_reference",
                        description: "slovenia_site25_img4_description"
                    }
                ],
                url: "/",
                country: 'si',
                name: 'slovenia_site25_name',
                epoch: 'period-ironAge-name',
                description: 'slovenia_site25_description',
                keywords: 'slovenia_site25_keywords',
                gps: [45.949356, 14.821563]
            },
            {
                img: "../images/sites/slovenia/thumbs/slovenia_site26_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovenia/slovenia_site26_p1.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site26_p1.jpg",
                        reference: "slovenia_site26_img1_reference",
                        description: "slovenia_site26_img1_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site26_p2.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site26_p2.jpg",
                        reference: "slovenia_site26_img2_reference",
                        description: "slovenia_site26_img2_description"
                    }
                ],
                url: "/",
                country: 'si',
                name: 'slovenia_site26_name',
                epoch: 'period-ironAge-name',
                description: 'slovenia_site26_description',
                keywords: 'slovenia_site26_keywords',
                gps: [46.126251, 14.850426]
            },
            {
                img: "../images/sites/slovenia/thumbs/slovenia_site27_v1.webp",
                images: [
                    {
                        url: "CT4ZmAjk1Ug",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site27_v1.webp",
                        reference: "slovenia_site27_video1_reference",
                        description: "slovenia_site27_video1_description",
                        provider: "youtube"
                    },
                    {
                        url: "_uzAoc74dXw",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site27_v2.webp",
                        reference: "slovenia_site27_video2_reference",
                        description: "slovenia_site27_video2_description",
                        provider: "youtube"
                    },
                    {
                        url: "qSURsXa8xrk",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site27_v3.webp",
                        reference: "slovenia_site27_video3_reference",
                        description: "slovenia_site27_video3_description",
                        provider: "youtube"
                    },
                    {
                        url: "mfhs-F4jZ_U",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site27_v4.webp",
                        reference: "slovenia_site27_video4_reference",
                        description: "slovenia_site27_video4_description",
                        provider: "youtube"
                    },

                    {
                        url: "CgIlLjXvN-4",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site27_v5.webp",
                        reference: "slovenia_site27_video5_reference",
                        description: "slovenia_site27_video5_description",
                        provider: "youtube"
                    },

                ],
                url: "/",
                country: 'si',
                name: 'slovenia_site27_name',
                epoch: 'period-ironAge-name',
                description: 'slovenia_site27_description',
                keywords: 'slovenia_site27_keywords',
                gps: [45.715152, 14.464078]
            },
            {
                img: "../images/sites/slovenia/slovenia_site28_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovenia/slovenia_site28_p1.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site28_p1.jpg",
                        reference: "slovenia_site28_img1_reference",
                        description: "slovenia_site28_img1_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site28_p2.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site28_p2.jpg",
                        reference: "slovenia_site28_img2_reference",
                        description: "slovenia_site28_img2_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site28_p3.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site28_p3.jpg",
                        reference: "slovenia_site28_img3_reference",
                        description: "slovenia_site28_img3_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site28_p4.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site28_p4.jpg",
                        reference: "slovenia_site28_img4_reference",
                        description: "slovenia_site28_img4_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site28_p5.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site28_p5.jpg",
                        reference: "slovenia_site28_img5_reference",
                        description: "slovenia_site28_img5_description"
                    }
                ],
                url: "/",
                country: 'si',
                name: 'slovenia_site28_name',
                epoch: 'period-romanPeriod-name',
                description: 'slovenia_site28_description',
                keywords: 'slovenia_site28_keywords',
                gps: [46.228880, 15.260475]
            },
            {
                img: "../images/sites/slovenia/slovenia_site29_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovenia/slovenia_site29_p1.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site29_p1.jpg",
                        reference: "slovenia_site29_img1_reference",
                        description: "slovenia_site29_img1_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site29_p2.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site29_p2.jpg",
                        reference: "slovenia_site29_img2_reference",
                        description: "slovenia_site29_img2_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site29_p3.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site29_p3.jpg",
                        reference: "slovenia_site29_img3_reference",
                        description: "slovenia_site29_img3_description"
                    }
                ],
                url: "/",
                country: 'si',
                name: 'slovenia_site29_name',
                epoch: 'period-romanPeriod-name',
                description: 'slovenia_site29_description',
                keywords: 'slovenia_site29_keywords',
                gps: [45.86358, 14.11155]
            },
            {
                img: "../images/sites/slovenia/thumbs/slovenia_site30_p1.jpg",
                images: [

                    {
                        src: "../images/sites/slovenia/slovenia_site30_p1.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site30_p1.jpg",
                        reference: "slovenia_site30_img1_reference",
                        description: "slovenia_site30_img1_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site30_p2.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site30_p2.jpg",
                        reference: "slovenia_site30_img2_reference",
                        description: "slovenia_site30_img2_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site30_p3.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site30_p3.jpg",
                        reference: "slovenia_site30_img3_reference",
                        description: "slovenia_site30_img3_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site30_p4.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site30_p4.jpg",
                        reference: "slovenia_site30_img4_reference",
                        description: "slovenia_site30_img4_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site30_p5.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site30_p5.jpg",
                        reference: "slovenia_site30_img5_reference",
                        description: "slovenia_site30_img5_description"
                    }
                ],
                url: "/",
                country: 'si',
                name: 'slovenia_site30_name',
                epoch: 'period-romanPeriod-name',
                description: 'slovenia_site30_description',
                keywords: 'slovenia_site30_keywords',
                gps: [46.047336, 14.50119845]
            },
            {
                img: "../images/sites/slovenia/slovenia_site31_p1.jpg",
                images: [

                    {
                        src: "../images/sites/slovenia/slovenia_site31_p1.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site31_p1.jpg",
                        reference: "slovenia_site31_img1_reference",
                        description: "slovenia_site31_img1_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site31_p2.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site31_p2.jpg",
                        reference: "slovenia_site31_img2_reference",
                        description: "slovenia_site31_img2_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site31_p3.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site31_p3.jpg",
                        reference: "slovenia_site31_img3_reference",
                        description: "slovenia_site31_img3_description"
                    }
                ],
                url: "/",
                country: 'si',
                name: 'slovenia_site31_name',
                epoch: 'period-romanPeriod-name',
                description: 'slovenia_site31_description',
                keywords: 'slovenia_site31_keywords',
                gps: [46.423990, 15.865823]
            },
            {
                img: "../images/sites/slovenia/thumbs/slovenia_site32_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovenia/slovenia_site32_p1.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site32_p1.jpg",
                        reference: "slovenia_site32_img1_reference",
                        description: "slovenia_site32_img1_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site32_p2.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site32_p2.jpg",
                        reference: "slovenia_site32_img2_reference",
                        description: "slovenia_site32_img2_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site32_p3.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site32_p3.jpg",
                        reference: "slovenia_site32_img3_reference",
                        description: "slovenia_site32_img3_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site32_p4.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site32_p4.jpg",
                        reference: "slovenia_site32_img4_reference",
                        description: "slovenia_site32_img4_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site32_p5.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site32_p5.jpg",
                        reference: "slovenia_site32_img5_reference",
                        description: "slovenia_site32_img5_description"
                    }
                ],
                url: "/",
                country: 'si',
                name: 'slovenia_site32_name',
                epoch: 'period-romanPeriod-name',
                description: 'slovenia_site32_description',
                keywords: 'slovenia_site32_keywords',
                gps: [45.969815, 14.299193]
            },
            {
                img: "../images/sites/slovenia/thumbs/slovenia_site33_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovenia/slovenia_site33_p1.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site33_p1.jpg",
                        reference: "slovenia_site33_img1_reference",
                        description: "slovenia_site33_img1_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site33_p2.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site33_p2.jpg",
                        reference: "slovenia_site33_img2_reference",
                        description: "slovenia_site33_img2_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site33_p3.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site33_p3.jpg",
                        reference: "slovenia_site33_img3_reference",
                        description: "slovenia_site33_img3_description"
                    }
                ],
                url: "/",
                country: 'si',
                name: 'slovenia_site33_name',
                epoch: 'period-lateAntiquity-name',
                description: 'slovenia_site33_description',
                keywords: 'slovenia_site33_keywords',
                gps: [46.03952, 15.35133]
            },
            {
                img: "../images/sites/slovenia/slovenia_site34_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovenia/slovenia_site34_p1.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site34_p1.jpg",
                        reference: "slovenia_site34_img1_reference",
                        description: "slovenia_site34_img1_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site34_p2.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site34_p2.jpg",
                        reference: "slovenia_site34_img2_reference",
                        description: "slovenia_site34_img2_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site34_p3.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site34_p3.jpg",
                        reference: "slovenia_site34_img3_reference",
                        description: "slovenia_site34_img3_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site34_p4.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site34_p4.jpg",
                        reference: "slovenia_site34_img4_reference",
                        description: "slovenia_site34_img4_description"
                    }
                ],
                url: "/",
                country: 'si',
                name: 'slovenia_site34_name',
                epoch: 'period-lateAntiquity-name',
                description: 'slovenia_site34_description',
                keywords: 'slovenia_site34_keywords',
                gps: [46.2223124, 15.3976769]
            },
            {
                img: "../images/sites/slovenia/slovenia_site35_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovenia/slovenia_site35_p1.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site35_p1.jpg",
                        reference: "slovenia_site35_img1_reference",
                        description: "slovenia_site35_img1_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site35_p2.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site35_p2.jpg",
                        reference: "slovenia_site35_img2_reference",
                        description: "slovenia_site35_img2_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site35_p3.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site35_p3.jpg",
                        reference: "slovenia_site35_img3_reference",
                        description: "slovenia_site35_img3_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site35_p4.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site35_p4.jpg",
                        reference: "slovenia_site35_img4_reference",
                        description: "slovenia_site35_img4_description"
                    }
                ],
                url: "/",
                country: 'si',
                name: 'slovenia_site35_name',
                epoch: 'period-lateAntiquity-name',
                description: 'slovenia_site35_description',
                keywords: 'slovenia_site35_keywords',
                gps: [46.247768, 13.588887]
            },
            {
                img: "../images/sites/slovenia/thumbs/slovenia_site36_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovenia/slovenia_site36_p1.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site36_p1.jpg",
                        reference: "slovenia_site36_img1_reference",
                        description: "slovenia_site36_img1_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site36_p2.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site36_p2.jpg",
                        reference: "slovenia_site36_img2_reference",
                        description: "slovenia_site36_img2_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site36_p3.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site36_p3.jpg",
                        reference: "slovenia_site36_img3_reference",
                        description: "slovenia_site36_img3_description"
                    }
                ],
                url: "/",
                country: 'si',
                name: 'slovenia_site36_name',
                epoch: 'period-lateAntiquity-name',
                description: 'slovenia_site36_description',
                keywords: 'slovenia_site36_keywords',
                gps: [46.240319, 14.352436]
            },
            {
                img: "../images/sites/slovenia/thumbs/slovenia_site37_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovenia/slovenia_site37_p1.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site37_p1.jpg",
                        reference: "slovenia_site37_img1_reference",
                        description: "slovenia_site37_img1_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site37_p2.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site37_p2.jpg",
                        reference: "slovenia_site37_img2_reference",
                        description: "slovenia_site37_img2_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site37_p3.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site37_p3.jpg",
                        reference: "slovenia_site37_img3_reference",
                        description: "slovenia_site37_img3_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site37_p4.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site37_p4.jpg",
                        reference: "slovenia_site37_img3_reference",
                        description: "slovenia_site37_img3_description"
                    }
                ],
                url: "/",
                country: 'si',
                name: 'slovenia_site37_name',
                epoch: 'period-lateAntiquity-name',
                description: 'slovenia_site37_description',
                keywords: 'slovenia_site37_keywords',
                gps: [45.783115, 15.339640]
            },
            {
                img: "../images/sites/slovenia/thumbs/slovenia_site38_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovenia/slovenia_site38_p1.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site38_p1.jpg",
                        reference: "slovenia_site38_img1_reference",
                        description: "slovenia_site38_img1_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site38_p2.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site38_p2.jpg",
                        reference: "slovenia_site38_img2_reference",
                        description: "slovenia_site38_img2_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site38_p3.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site38_p3.jpg",
                        reference: "slovenia_site38_img3_reference",
                        description: "slovenia_site38_img3_description"
                    }
                ],
                url: "/",
                country: 'si',
                name: 'slovenia_site38_name',
                epoch: 'period-middleAge-name',
                description: 'slovenia_site38_description',
                keywords: 'slovenia_site38_keywords',
                gps: [46.330349, 14.399160]
            },
            {
                img: "../images/sites/slovenia/slovenia_site39_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovenia/slovenia_site39_p1.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site39_p1.jpg",
                        reference: "slovenia_site39_img1_reference",
                        description: "slovenia_site39_img1_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site39_p2.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site39_p2.jpg",
                        reference: "slovenia_site39_img2_reference",
                        description: "slovenia_site39_img2_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site39_p3.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site39_p3.jpg",
                        reference: "slovenia_site39_img3_reference",
                        description: "slovenia_site39_img3_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site39_p4.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site39_p4.jpg",
                        reference: "slovenia_site39_img4_reference",
                        description: "slovenia_site39_img4_description"
                    }
                ],
                url: "/",
                country: 'si',
                name: 'slovenia_site39_name',
                epoch: 'period-middleAge-name',
                description: 'slovenia_site39_description',
                keywords: 'slovenia_site39_keywords',
                gps: [46.362363, 14.090087]
            },
            {
                img: "../images/sites/slovenia/thumbs/slovenia_site40_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovenia/slovenia_site40_p1.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site40_p1.jpg",
                        reference: "slovenia_site40_img1_reference",
                        description: "slovenia_site40_img1_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site40_p2.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site40_p2.jpg",
                        reference: "slovenia_site40_img2_reference",
                        description: "slovenia_site40_img2_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site40_p3.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site40_p3.jpg",
                        reference: "slovenia_site40_img3_reference",
                        description: "slovenia_site40_img3_description"
                    }
                ],
                url: "/",
                country: 'si',
                name: 'slovenia_site40_name',
                epoch: 'period-middleAge-name',
                description: 'slovenia_site40_description',
                keywords: 'slovenia_site40_keywords',
                gps: [46.22835, 15.26370]
            },
            {
                img: "../images/sites/slovenia/thumbs/slovenia_site41_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovenia/slovenia_site41_p1.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site41_p1.jpg",
                        reference: "slovenia_site41_img1_reference",
                        description: "slovenia_site41_img1_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site41_p2.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site41_p2.jpg",
                        reference: "slovenia_site41_img2_reference",
                        description: "slovenia_site41_img2_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site41_p3.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site41_p3.jpg",
                        reference: "slovenia_site41_img3_reference",
                        description: "slovenia_site41_img3_description"
                    }
                ],
                url: "/",
                country: 'si',
                name: 'slovenia_site41_name',
                epoch: 'period-middleAge-name',
                description: 'slovenia_site41_description',
                keywords: 'slovenia_site41_keywords',
                gps: [45.874532, 15.331379]
            },
            {
                img: "../images/sites/slovenia/slovenia_site42_p1.jpg",
                images: [
                    {
                        src: "../images/sites/slovenia/slovenia_site42_p1.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site42_p1.jpg",
                        reference: "slovenia_site42_img1_reference",
                        description: "slovenia_site42_img1_description"
                    },
                    {
                        src: "../images/sites/slovenia/slovenia_site42_p2.jpg",
                        thumb: "../images/sites/slovenia/thumbs/slovenia_site42_p2.jpg",
                        reference: "slovenia_site42_img2_reference",
                        description: "slovenia_site42_img2_description"
                    }
                ],
                url: "/",
                country: 'si',
                name: 'slovenia_site42_name',
                epoch: 'period-middleAge-name',
                description: 'slovenia_site42_description',
                keywords: 'slovenia_site42_keywords',
                gps: [46.5, 15.648056]
            },


        ]
    },

    {
        id: 6,
        name: 'countries-austria-name',
        shortcut: 'at',
        flag: flag_at,
        url: 'https://constructarcade.com/game/archery-training/',
        content: 'austria_description',
        image: "../images/countries/austria.jpg",
        models: [

            {
                img: "../images/sites/austria/thumbs/austria_site1_p1.jpg",
                images: [
                    {
                        src: "../images/sites/austria/austria_site1_p1.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site1_p1.jpg",
                        reference: "austria_site1_img1_reference",
                        description: "austria_site1_img1_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site1_p2.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site1_p2.jpg",
                        reference: "austria_site1_img2_reference",
                        description: "austria_site1_img2_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site1_p3.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site1_p3.jpg",
                        reference: "austria_site1_img3_reference",
                        description: "austria_site1_img3_description"
                    }
                ],
                url: "/",
                country: 'at',
                name: 'austria_site1_name',
                epoch: 'period-paleolithic-name',
                description: 'austria_site1_description',
                keywords: 'austria_site1_keywords',
                gps: [47.228889, 15.3475]
            },
            {
                img: "../images/sites/austria/thumbs/austria_site2_p1.jpg",
                images: [
                    {
                        src: "../images/sites/austria/austria_site2_p1.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site2_p1.jpg",
                        reference: "austria_site2_img1_reference",
                        description: "austria_site2_img1_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site2_p2.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site2_p2.jpg",
                        reference: "austria_site2_img2_reference",
                        description: "austria_site2_img2_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site2_p3.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site2_p3.jpg",
                        reference: "austria_site2_img3_reference",
                        description: "austria_site2_img3_description",
                    }
                ],
                url: "/",
                country: 'at',
                name: 'austria_site2_name',
                epoch: 'period-paleolithic-name',
                description: 'austria_site2_description',
                keywords: 'austria_site2_keywords',
                gps: [48.322222, 15.404167]
            },
            {
                img: "../images/sites/austria/thumbs/austria_site3_p1.jpg",
                images: [
                    {
                        src: "../images/sites/austria/austria_site3_p1.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site3_p1.jpg",
                        reference: "austria_site3_img1_reference",
                        description: "austria_site3_img1_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site3_p2.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site3_p2.jpg",
                        reference: "austria_site3_img2_reference",
                        description: "austria_site3_img2_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site3_p3.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site3_p3.jpg",
                        reference: "austria_site3_img3_reference",
                        description: "austria_site3_img3_description"
                    }
                ],
                url: "/",
                country: 'at',
                name: 'austria_site3_name',
                epoch: 'period-paleolithic-name',
                description: 'austria_site3_description',
                keywords: 'austria_site3_keywords',
                gps: [48.41501, 15.59956]
            },
            {
                img: "../images/sites/austria/thumbs/austria_site4_p1.jpg",
                images: [
                    {
                        src: "../images/sites/austria/austria_site4_p1.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site4_p1.jpg",
                        reference: "austria_site4_img1_reference",
                        description: "austria_site4_img1_description"
                    }
                ],
                url: "/",
                country: 'at',
                name: 'austria_site4_name',
                epoch: 'period-neolithic-name',
                description: 'austria_site4_description',
                keywords: 'austria_site4_keywords',
                gps: [48.149237, 16.244979]
            },
            {
                img: "../images/sites/austria/thumbs/austria_site5_p1.jpg",
                images: [
                    {
                        src: "../images/sites/austria/austria_site5_p1.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site5_p1.jpg",
                        reference: "austria_site5_img1_reference",
                        description: "austria_site5_img1_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site5_p2.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site5_p2.jpg",
                        reference: "austria_site5_img2_reference",
                        description: "austria_site5_img2_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site5_p3.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site5_p3.jpg",
                        reference: "austria_site5_img3_reference",
                        description: "austria_site5_img3_description"
                    }
                ],
                url: "/",
                country: 'at',
                name: 'austria_site5_name',
                epoch: 'period-neolithic-name',
                description: 'austria_site5_description',
                keywords: 'austria_site5_keywords',
                gps: [47.136325, 15.286557]
            },
            {
                img: "../images/sites/austria/thumbs/austria_site6_p1.jpg",
                images: [
                    {
                        src: "../images/sites/austria/austria_site6_p1.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site6_p1.jpg",
                        reference: "austria_site6_img1_reference",
                        description: "austria_site6_img1_description"
                    }
                ],
                url: "/",
                country: 'at',
                name: 'austria_site6_name',
                epoch: 'period-neolithic-name',
                description: 'austria_site6_description',
                keywords: 'austria_site6_keywords',
                gps: [48.500206, 15.944850]
            },
            {
                img: "../images/sites/austria/thumbs/austria_site7_p1.jpg",
                images: [
                    {
                        src: "../images/sites/austria/austria_site7_p1.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site7_p1.jpg",
                        reference: "austria_site7_img1_reference",
                        description: "austria_site7_img1_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site7_p2.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site7_p2.jpg",
                        reference: "austria_site7_img2_reference",
                        description: "austria_site7_img2_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site7_p3.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site7_p3.jpg",
                        reference: "austria_site7_img3_reference",
                        description: "austria_site7_img3_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site7_p4.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site7_p4.jpg",
                        reference: "austria_site7_img4_reference",
                        description: "austria_site7_img4_description"
                    }
                ],
                url: "/",
                country: 'at',
                name: 'austria_site7_name',
                epoch: 'period-copperAge-name',
                description: 'austria_site7_description',
                keywords: 'austria_site7_keywords',
                gps: [46.586881, 14.159502]
            },
            {
                img: "../images/sites/austria/thumbs/austria_site8_p1.jpg",
                images: [
                    {
                        src: "../images/sites/austria/austria_site8_p1.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site8_p1.jpg",
                        reference: "austria_site8_img1_reference",
                        description: "austria_site8_img1_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site8_p2.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site8_p2.jpg",
                        reference: "austria_site8_img2_reference",
                        description: "austria_site8_img2_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site8_p3.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site8_p3.jpg",
                        reference: "austria_site8_img3_reference",
                        description: "austria_site8_img3_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site8_p4.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site8_p4.jpg",
                        reference: "austria_site8_img4_reference",
                        description: "austria_site8_img4_description"
                    }
                ],
                url: "/",
                country: 'at',
                name: 'austria_site8_name',
                epoch: 'period-copperAge-name',
                description: 'austria_site8_description',
                keywords: 'austria_site8_keywords',
                gps: [47.805959, 13.447360]
            },
            {
                img: "../images/sites/austria/thumbs/austria_site9_p1.jpg",
                images: [
                    {
                        src: "../images/sites/austria/austria_site9_p1.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site9_p1.jpg",
                        reference: "austria_site9_img1_reference",
                        description: "austria_site9_img1_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site9_p2.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site9_p2.jpg",
                        reference: "austria_site9_img2_reference",
                        description: "austria_site9_img2_description"
                    }
                ],
                url: "/",
                country: 'at',
                name: 'austria_site9_name',
                epoch: 'period-copperAge-name',
                description: 'austria_site9_description',
                keywords: 'austria_site9_keywords',
                gps: [46.884486, 15.511945]
            },
            {
                img: "../images/sites/austria/thumbs/austria_site10_p1.jpg",
                images: [
                    {
                        src: "../images/sites/austria/austria_site10_p1.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site10_p1.jpg",
                        reference: "austria_site10_img1_reference",
                        description: "austria_site10_img1_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site10_p2.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site10_p2.jpg",
                        reference: "austria_site10_img2_reference",
                        description: "austria_site10_img2_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site10_p3.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site10_p3.jpg",
                        reference: "austria_site10_img3_reference",
                        description: "austria_site10_img3_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site10_p4.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site10_p4.jpg",
                        reference: "austria_site10_img4_reference",
                        description: "austria_site10_img4_description"
                    }
                ],
                url: "/",
                country: 'at',
                name: 'austria_site10_name',
                epoch: 'period-bronzeAge-name',
                description: 'austria_site10_description',
                keywords: 'austria_site10_keywords',
                gps: [48.347587, 15.707151]
            },
            {
                img: "../images/sites/austria/thumbs/austria_site11_p1.jpg",
                images: [
                    {
                        src: "../images/sites/austria/austria_site11_p1.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site11_p1.jpg",
                        reference: "austria_site11_img1_reference",
                        description: "austria_site11_img1_description"
                    },
                ],
                url: "/",
                country: 'at',
                name: 'austria_site11_name',
                epoch: 'period-bronzeAge-name',
                description: 'austria_site11_description',
                keywords: 'austria_site11_keywords',
                gps: [47.222028, 11.417169]
            },
            {
                img: "../images/sites/austria/thumbs/austria_site12_p1.jpg",
                images: [
                    {
                        src: "../images/sites/austria/austria_site12_p1.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site12_p1.jpg",
                        reference: "austria_site12_img1_reference",
                        description: "austria_site12_img1_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site12_p2.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site12_p2.jpg",
                        reference: "austria_site12_img2_reference",
                        description: "austria_site12_img12_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site12_p3.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site12_p3.jpg",
                        reference: "austria_site12_img3_reference",
                        description: "austria_site12_img3_description"
                    }
                ],
                url: "/",
                country: 'at',
                name: 'austria_site12_name',
                epoch: 'period-bronzeAge-name',
                description: 'austria_site12_description',
                keywords: 'austria_site12_keywords',
                gps: [47.575026, 13.822150]
            },
            {
                img: "../images/sites/austria/thumbs/austria_site13_p1.jpg",
                images: [
                    {
                        src: "../images/sites/austria/austria_site13_p1.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site13_p1.jpg",
                        reference: "austria_site13_img1_reference",
                        description: "austria_site13_img1_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site13_p2.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site13_p2.jpg",
                        reference: "austria_site13_img2_reference",
                        description: "austria_site13_img2_description"
                    }
                ],
                url: "/",
                country: 'at',
                name: 'austria_site13_name',
                epoch: 'period-ironAge-name',
                description: 'austria_site13_description',
                keywords: 'austria_site13_keywords',
                gps: [47.179092, 14.654766]
            },
            {
                img: "../images/sites/austria/thumbs/austria_site14_p1.jpg",
                images: [
                    {
                        src: "../images/sites/austria/austria_site14_p1.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site14_p1.jpg",
                        reference: "austria_site14_img1_reference",
                        description: "austria_site14_img1_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site14_p2.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site14_p2.jpg",
                        reference: "austria_site14_img2_reference",
                        description: "austria_site14_img2_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site14_p3.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site14_p3.jpg",
                        reference: "austria_site14_img3_reference",
                        description: "austria_site14_img3_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site14_p4.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site14_p4.jpg",
                        reference: "austria_site14_img4_reference",
                        description: "austria_site14_img4_description"
                    }
                ],
                url: "/",
                country: 'at',
                name: 'austria_site14_name',
                epoch: 'period-ironAge-name',
                description: 'austria_site14_description',
                keywords: 'austria_site14_keywords',
                gps: [47.562222, 13.649722]
            },
            {
                img: "../images/sites/austria/thumbs/austria_site15_p1.jpg",
                images: [
                    {
                        src: "../images/sites/austria/austria_site15_p1.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site15_p1.jpg",
                        reference: "austria_site15_img1_reference",
                        description: "austria_site15_img1_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site15_p2.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site15_p2.jpg",
                        reference: "austria_site15_img2_reference",
                        description: "austria_site15_img2_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site15_p3.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site15_p3.jpg",
                        reference: "austria_site15_img3_reference",
                        description: "austria_site15_img3_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site15_p4.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site15_p4.jpg",
                        reference: "austria_site15_img4_reference",
                        description: "austria_site15_img4_description"
                    }
                ],
                url: "/",
                country: 'at',
                name: 'austria_site15_name',
                epoch: 'period-ironAge-name',
                description: 'austria_site15_description',
                keywords: 'austria_site15_keywords',
                gps: [47.665556, 13.089722]
            },
            {
                img: "../images/sites/austria/thumbs/austria_site16_p1.jpg",
                images: [
                    {
                        url: "uI7WAsTgRo0",
                        thumb: "../images/sites/austria/thumbs/austria_site16_v1.webp",
                        reference: "austria_site16_video1_reference",
                        description: "austria_site16_video1_description",
                        provider: "youtube"
                    },
                    {
                        src: "../images/sites/austria/austria_site16_p1.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site16_p1.jpg",
                        reference: "austria_site16_img1_reference",
                        description: "austria_site16_img1_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site16_p2.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site16_p2.jpg",
                        reference: "austria_site16_img2_reference",
                        description: "austria_site16_img2_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site16_p3.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site16_p3.jpg",
                        reference: "austria_site16_img3_reference",
                        description: "austria_site16_img3_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site16_p4.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site16_p4.jpg",
                        reference: "austria_site16_img4_reference",
                        description: "austria_site16_img4_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site16_p5.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site16_p5.jpg",
                        reference: "austria_site16_img5_reference",
                        description: "austria_site16_img5_description"
                    }
                ],
                url: "/",
                country: 'at',
                name: 'austria_site16_name',
                epoch: 'period-ironAge-name',
                description: 'austria_site16_description',
                keywords: 'austria_site16_keywords',
                gps: [46.73562680810334, 15.441129725752482]
            },
            {
                img: "../images/sites/austria/thumbs/austria_site17_p1.jpg",
                images: [
                    {
                        src: "../images/sites/austria/austria_site17_p1.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site17_p1.jpg",
                        reference: "austria_site17_img1_reference",
                        description: "austria_site17_img1_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site17_p2.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site17_p2.jpg",
                        reference: "austria_site17_img2_reference",
                        description: "austria_site17_img2_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site17_p3.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site17_p3.jpg",
                        reference: "austria_site17_img3_reference",
                        description: "austria_site17_img3_description"
                    }
                ],
                url: "/",
                country: 'at',
                name: 'austria_site17_name',
                epoch: 'period-romanPeriod-name',
                description: 'austria_site17_description',
                keywords: 'austria_site17_keywords',
                gps: [46.827222, 12.823056]
            },
            {
                img: "../images/sites/austria/thumbs/austria_site18_p1.jpg",
                images: [
                    {
                        src: "../images/sites/austria/austria_site18_p1.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site18_p1.jpg",
                        reference: "austria_site18_img1_reference",
                        description: "austria_site18_img1_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site18_p2.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site18_p2.jpg",
                        reference: "austria_site18_img2_reference",
                        description: "austria_site18_img2_description"
                    }
                ],
                url: "/",
                country: 'at',
                name: 'austria_site18_name',
                epoch: 'period-romanPeriod-name',
                description: 'austria_site18_description',
                keywords: 'austria_site18_keywords',
                gps: [48.116261, 16.858290]
            },
            {
                img: "../images/sites/austria/thumbs/austria_site19_p1.jpg",
                images: [
                    {
                        src: "../images/sites/austria/austria_site19_p1.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site19_p1.jpg",
                        reference: "austria_site19_img1_reference",
                        description: "austria_site19_img1_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site19_p2.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site19_p2.jpg",
                        reference: "austria_site19_img2_reference",
                        description: "austria_site19_img2_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site19_p3.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site19_p3.jpg",
                        reference: "austria_site19_img3_reference",
                        description: "austria_site19_img3_description"
                    }
                ],
                url: "/",
                country: 'at',
                name: 'austria_site19_name',
                epoch: 'period-romanPeriod-name',
                description: 'austria_site19_description',
                keywords: 'austria_site19_keywords',
                gps: [46.728056, 14.429167]
            },
            {
                img: "../images/sites/austria/thumbs/austria_site20_p1.jpg",
                images: [
                    {
                        url: "3mlcG8PGhg4",
                        thumb: "../images/sites/austria/thumbs/austria_site20_v1.webp",
                        reference: "austria_site20_video1_reference",
                        description: "austria_site20_video1_description",
                        provider: "youtube"
                    },
                    {
                        src: "../images/sites/austria/austria_site20_p1.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site20_p1.jpg",
                        reference: "austria_site20_img1_reference",
                        description: "austria_site20_img1_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site20_p2.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site20_p2.jpg",
                        reference: "austria_site20_img2_reference",
                        description: "austria_site20_img2_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site20_p3.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site20_p3.jpg",
                        reference: "austria_site20_img3_reference",
                        description: "austria_site20_img3_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site20_p4.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site20_p4.jpg",
                        reference: "austria_site20_img4_reference",
                        description: "austria_site20_img4_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site20_p5.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site20_p5.jpg",
                        reference: "austria_site20_img5_reference",
                        description: "austria_site20_img5_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site20_p6.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site20_p6.jpg",
                        reference: "austria_site20_img16_reference",
                        description: "austria_site20_img6_description"
                    }
                ],
                url: "/",
                country: 'at',
                name: 'austria_site20_name',
                epoch: 'period-romanPeriod-name',
                description: 'austria_site20_description',
                keywords: 'austria_site20_keywords',
                gps: [46.76899575514022, 15.568955083913332]
            },
            {
                img: "../images/sites/austria/thumbs/austria_site21_p1.jpg",
                images: [
                    {
                        src: "../images/sites/austria/austria_site21_p1.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site21_p1.jpg",
                        reference: "austria_site21_img1_reference",
                        description: "austria_site21_img1_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site21_p2.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site21_p2.jpg",
                        reference: "austria_site21_img2_reference",
                        description: "austria_site21_img2_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site21_p3.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site21_p3.jpg",
                        reference: "austria_site21_img3_reference",
                        description: "austria_site21_img3_description"
                    }
                ],
                url: "/",
                country: 'at',
                name: 'austria_site21_name',
                epoch: 'period-lateAntiquity-name',
                description: 'austria_site21_description',
                keywords: 'austria_site21_keywords',
                gps: [46.823308, 13.443747]
            },
            {
                img: "../images/sites/austria/thumbs/austria_site22_p1.jpg",
                images: [
                    {
                        src: "../images/sites/austria/austria_site22_p1.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site22_p1.jpg",
                        reference: "austria_site22_img1_reference",
                        description: "austria_site22_img1_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site22_p2.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site22_p2.jpg",
                        reference: "austria_site22_img2_reference",
                        description: "austria_site22_img2_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site22_p3.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site22_p3.jpg",
                        reference: "austria_site22_img3_reference",
                        description: "austria_site22_img3_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site22_p4.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site22_p4.jpg",
                        reference: "austria_site22_img4_reference",
                        description: "austria_site22_img4_description"
                    }
                ],
                url: "/",
                country: 'at',
                name: 'austria_site22_name',
                epoch: 'period-lateAntiquity-name',
                description: 'austria_site22_description',
                keywords: 'austria_site22_keywords',
                gps: [46.771149, 15.522005]
            },
            {
                img: "../images/sites/austria/thumbs/austria_site23_p1.jpg",
                images: [
                    {
                        src: "../images/sites/austria/austria_site23_p1.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site23_p1.jpg",
                        reference: "austria_site23_img1_reference",
                        description: "austria_site23_img1_description"
                    }
                ],
                url: "/",
                country: 'at',
                name: 'austria_site23_name',
                epoch: 'period-lateAntiquity-name',
                description: 'austria_site23_description',
                keywords: 'austria_site23_keywords',
                gps: [46.795527, 12.836309]
            },
            {
                img: "../images/sites/austria/thumbs/austria_site24_p1.jpg",
                images: [
                    {
                        src: "../images/sites/austria/austria_site24_p1.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site24_p1.jpg",
                        reference: "austria_site24_img1_reference",
                        description: "austria_site24_img1_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site24_p2.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site24_p2.jpg",
                        reference: "austria_site24_img2_reference",
                        description: "austria_site24_img2_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site24_p3.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site24_p3.jpg",
                        reference: "austria_site24_img3_reference",
                        description: "austria_site24_img3_description"
                    }
                ],
                url: "/",
                country: 'at',
                name: 'austria_site24_name',
                epoch: 'period-middleAge-name',
                description: 'austria_site24_description',
                keywords: 'austria_site24_keywords',
                gps: [47.004722, 15.932500]
            },
            {
                img: "../images/sites/austria/thumbs/austria_site25_p1.jpg",
                images: [
                    {
                        src: "../images/sites/austria/austria_site25_p1.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site25_p1.jpg",
                        reference: "austria_site25_img1_reference",
                        description: "austria_site25_img1_description"
                    }
                ],
                url: "/",
                country: 'at',
                name: 'austria_site25_name',
                epoch: 'period-middleAge-name',
                description: 'austria_site25_description',
                keywords: 'austria_site25_keywords',
                gps: [47.137778, 14.348333]
            },
            {
                img: "../images/sites/austria/thumbs/austria_site26_p1.jpg",
                images: [
                    {
                        src: "../images/sites/austria/austria_site26_p1.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site26_p1.jpg",
                        reference: "austria_site26_img1_reference",
                        description: "austria_site26_img1_description"
                    },
                    {
                        src: "../images/sites/austria/austria_site26_p2.jpg",
                        thumb: "../images/sites/austria/thumbs/austria_site26_p2.jpg",
                        reference: "austria_site26_img2_reference",
                        description: "austria_site26_img2_description"
                    }
                ],
                url: "/",
                country: 'at',
                name: 'austria_site26_name',
                epoch: 'period-middleAge-name',
                description: 'austria_site26_description',
                keywords: 'austria_site26_keywords',
                gps: [47.956564, 14.714964]
            }
        ]
    },

    {
        id: 7,
        name: 'countries-bulgaria-name',
        shortcut: 'bg',
        flag: flag_bg,
        url: 'https://constructarcade.com/game/archery-training/',
        content: 'bulgaria_description',
        image: "../images/countries/bulgaria.jpg",
        models: [
            {
                img: "../images/sites/bulgaria/thumbs/bulgaria_site1_p1.jpg",
                images: [
                    {
                        src: "../images/sites/bulgaria/bulgaria_site1_p1.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site1_p1.jpg",
                        reference: "bulgaria_site1_img1_reference",
                        description: "bulgaria_site1_img1_description"
                    },
                    {
                        src: "../images/sites/bulgaria/bulgaria_site1_p2.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site1_p2.jpg",
                        reference: "bulgaria_site1_img2_reference",
                        description: "bulgaria_site1_img2_description"
                    }
                ],
                url: "/",
                country: 'bg',
                name: 'bulgaria_site1_name',
                epoch: 'period-paleolithic-name',
                description: 'bulgaria_site1_description',
                keywords: 'bulgaria_site1_keywords',
                gps: [42.94843044017254, 25.430558659168483]
            },
            {
                img: "../images/sites/bulgaria/thumbs/bulgaria_site2_p1.jpg",
                images: [
                    {
                        src: "../images/sites/bulgaria/bulgaria_site2_p1.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site2_p1.jpg",
                        reference: "bulgaria_site2_img1_reference",
                        description: "bulgaria_site2_img1_description"
                    },
                    {
                        src: "../images/sites/bulgaria/bulgaria_site2_p2.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site2_p2.jpg",
                        reference: "bulgaria_site2_img2_reference",
                        description: "bulgaria_site2_img2_description"
                    }
                ],
                url: "/",
                country: 'bg',
                name: 'bulgaria_site2_name',
                epoch: 'period-paleolithic-name',
                description: 'bulgaria_site2_description',
                keywords: 'bulgaria_site2_keywords',
                gps: [43.65186602311391, 22.702300667936335]
            },
            {
                img: "../images/sites/bulgaria/thumbs/bulgaria_site3_p1.jpg",
                images: [
                    {
                        src: "../images/sites/bulgaria/bulgaria_site3_p1.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site3_p1.jpg",
                        reference: "bulgaria_site3_img1_reference",
                        description: "bulgaria_site3_img1_description"
                    }
                ],
                url: "/",
                country: 'bg',
                name: 'bulgaria_site3_name',
                epoch: 'period-paleolithic-name',
                description: 'bulgaria_site3_description',
                keywords: 'bulgaria_site3_keywords',
                gps: [43.6329143199678, 24.843576725082766]
            },
            {
                img: "../images/sites/bulgaria/thumbs/bulgaria_site4_p1.jpg",
                images: [
                    {
                        src: "../images/sites/bulgaria/bulgaria_site4_p1.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site4_p1.jpg",
                        reference: "bulgaria_site4_img1_reference",
                        description: "bulgaria_site4_img1_description"
                    },
                    {
                        src: "../images/sites/bulgaria/bulgaria_site4_p2.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site4_p2.jpg",
                        reference: "bulgaria_site4_img2_reference",
                        description: "bulgaria_site4_img2_description"
                    }
                ],
                url: "/",
                country: 'bg',
                name: 'bulgaria_site4_name',
                epoch: 'period-neolithic-name',
                description: 'bulgaria_site4_description',
                keywords: 'bulgaria_site4_keywords',
                gps: [42.424729646240934, 25.610709419027636]
            },
            {
                img: "../images/sites/bulgaria/thumbs/bulgaria_site5_p1.jpg",
                images: [
                    {
                        src: "../images/sites/bulgaria/bulgaria_site5_p1.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site5_p1.jpg",
                        reference: "bulgaria_site5_img1_reference",
                        description: "bulgaria_site5_img1_description"
                    }
                ],
                url: "/",
                country: 'bg',
                name: 'bulgaria_site5_name',
                epoch: 'period-neolithic-name',
                description: 'bulgaria_site5_description',
                keywords: 'bulgaria_site5_keywords',
                gps: [43.233538695007745, 24.885466446702978]
            },
            {
                img: "../images/sites/bulgaria/thumbs/bulgaria_site6_p1.jpg",
                images: [
                    {
                        src: "../images/sites/bulgaria/bulgaria_site6_p1.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site6_p1.jpg",
                        reference: "bulgaria_site6_img1_reference",
                        description: "bulgaria_site6_img1_description"
                    }
                ],
                url: "/",
                country: 'bg',
                name: 'bulgaria_site6_name',
                epoch: 'period-neolithic-name',
                description: 'bulgaria_site6_description',
                keywords: 'bulgaria_site6_keywords',
                gps: [42.51359954512047, 25.909746753830802]
            },
            {
                img: "../images/sites/bulgaria/thumbs/bulgaria_site7_p1.jpg",
                images: [
                    {
                        src: "../images/sites/bulgaria/bulgaria_site7_p1.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site7_p1.jpg",
                        reference: "bulgaria_site7_img1_reference",
                        description: "bulgaria_site7_img1_description"
                    }
                ],
                url: "/",
                country: 'bg',
                name: 'bulgaria_site7_name',
                epoch: 'period-copperAge-name',
                description: 'bulgaria_site7_description',
                keywords: 'bulgaria_site7_keywords',
                gps: [43.12837884136359, 27.472451883324684]
            },
            {
                img: "../images/sites/bulgaria/thumbs/bulgaria_site8_p1.jpg",
                images: [
                    {
                        src: "../images/sites/bulgaria/bulgaria_site8_p1.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site8_p1.jpg",
                        reference: "bulgaria_site8_img1_reference",
                        description: "bulgaria_site8_img1_description"
                    },
                    {
                        src: "../images/sites/bulgaria/bulgaria_site8_p2.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site8_p2.jpg",
                        reference: "bulgaria_site8_img2_reference",
                        description: "bulgaria_site8_img2_description"
                    }
                ],
                url: "/",
                country: 'bg',
                name: 'bulgaria_site8_name',
                epoch: 'period-copperAge-name',
                description: 'bulgaria_site8_description',
                keywords: 'bulgaria_site8_keywords',
                gps: [43.21436464532642, 27.871234153718998]
            },
            {
                img: "../images/sites/bulgaria/thumbs/bulgaria_site9_p1.jpg",
                images: [
                    {
                        src: "../images/sites/bulgaria/bulgaria_site9_p1.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site9_p1.jpg",
                        reference: "bulgaria_site9_img1_reference",
                        description: "bulgaria_site9_img1_description"
                    },
                    {
                        src: "../images/sites/bulgaria/bulgaria_site9_p2.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site9_p2.jpg",
                        reference: "bulgaria_site9_img2_reference",
                        description: "bulgaria_site9_img2_description"
                    }
                ],
                url: "/",
                country: 'bg',
                name: 'bulgaria_site9_name',
                epoch: 'period-copperAge-name',
                description: 'bulgaria_site9_description',
                keywords: 'bulgaria_site9_keywords',
                gps: [43.6692045045198, 28.532208481677422]
            },
            {
                img: "../images/sites/bulgaria/thumbs/bulgaria_site10_p1.jpg",
                images: [
                    {
                        src: "../images/sites/bulgaria/bulgaria_site10_p1.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site10_p1.jpg",
                        reference: "bulgaria_site10_img1_reference",
                        description: "bulgaria_site10_img1_description"
                    },
                    {
                        src: "../images/sites/bulgaria/bulgaria_site10_p2.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site10_p2.jpg",
                        reference: "bulgaria_site10_img2_reference",
                        description: "bulgaria_site10_img2_description"
                    }
                ],
                url: "/",
                country: 'bg',
                name: 'bulgaria_site10_name',
                epoch: 'period-bronzeAge-name',
                description: 'bulgaria_site10_description',
                keywords: 'bulgaria_site10_keywords',
                gps: [43.72806661701017, 22.582914278562203]
            },
            {
                img: "../images/sites/bulgaria/thumbs/bulgaria_site11_p1.jpg",
                images: [
                    {
                        src: "../images/sites/bulgaria/bulgaria_site11_p1.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site11_p1.jpg",
                        reference: "bulgaria_site11_img1_reference",
                        description: "bulgaria_site11_img1_description"
                    },
                    {
                        src: "../images/sites/bulgaria/bulgaria_site11_p2.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site11_p2.jpg",
                        reference: "bulgaria_site11_img2_reference",
                        description: "bulgaria_site11_img2_description"
                    }
                ],
                url: "/",
                country: 'bg',
                name: 'bulgaria_site11_name',
                epoch: 'period-bronzeAge-name',
                description: 'bulgaria_site11_description',
                keywords: 'bulgaria_site11_keywords',
                gps: [42.232398789035805, 24.26262105958928]
            },
            {
                img: "../images/sites/bulgaria/thumbs/bulgaria_site12_p1.jpg",
                images: [
                    {
                        src: "../images/sites/bulgaria/bulgaria_site12_p1.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site12_p1.jpg",
                        reference: "bulgaria_site12_img1_reference",
                        description: "bulgaria_site12_img1_description"
                    },
                    {
                        src: "../images/sites/bulgaria/bulgaria_site12_p2.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site12_p2.jpg",
                        reference: "bulgaria_site12_img2_reference",
                        description: "bulgaria_site12_img2_description"
                    }
                ],
                url: "/",
                country: 'bg',
                name: 'bulgaria_site12_name',
                epoch: 'period-ironAge-name',
                description: 'bulgaria_site12_description',
                keywords: 'bulgaria_site12_keywords',
                gps: [42.62587830707109, 25.399157242460003]
            },
            {
                img: "../images/sites/bulgaria/thumbs/bulgaria_site13_p1.jpg",
                images: [
                    {
                        src: "../images/sites/bulgaria/bulgaria_site13_p1.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site13_p1.jpg",
                        reference: "bulgaria_site13_img1_reference",
                        description: "bulgaria_site13_img1_description"
                    },
                    {
                        src: "../images/sites/bulgaria/bulgaria_site13_p2.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site13_p2.jpg",
                        reference: "bulgaria_site13_img2_reference",
                        description: "bulgaria_site13_img2_description"
                    }
                ],
                url: "/",
                country: 'bg',
                name: 'bulgaria_site13_name',
                epoch: 'period-ironAge-name',
                description: 'bulgaria_site13_description',
                keywords: 'bulgaria_site13_keywords',
                gps: [43.74106751475278, 26.760130394368044]
            },
            {
                img: "../images/sites/bulgaria/thumbs/bulgaria_site14_p1.jpg",
                images: [
                    {
                        src: "../images/sites/bulgaria/bulgaria_site14_p1.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site14_p1.jpg",
                        reference: "bulgaria_site14_img1_reference",
                        description: "bulgaria_site14_img1_description"
                    },
                    {
                        src: "../images/sites/bulgaria/bulgaria_site14_p2.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site14_p2.jpg",
                        reference: "bulgaria_site14_img2_reference",
                        description: "bulgaria_site14_img2_description"
                    }
                ],
                url: "/",
                country: 'bg',
                name: 'bulgaria_site14_name',
                epoch: 'period-ironAge-name',
                description: 'bulgaria_site14_description',
                keywords: 'bulgaria_site14_keywords',
                gps: [41.981714965795376, 25.73672338982804]
            },
            {
                img: "../images/sites/bulgaria/thumbs/bulgaria_site15_p1.jpg",
                images: [
                    {
                        src: "../images/sites/bulgaria/bulgaria_site15_p1.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site15_p1.jpg",
                        reference: "bulgaria_site15_img1_reference",
                        description: "bulgaria_site15_img1_description"
                    },
                    {
                        src: "../images/sites/bulgaria/bulgaria_site15_p2.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site15_p2.jpg",
                        reference: "bulgaria_site15_img2_reference",
                        description: "bulgaria_site15_img2_description"
                    }
                ],
                url: "/",
                country: 'bg',
                name: 'bulgaria_site15_name',
                epoch: 'period-romanPeriod-name',
                description: 'bulgaria_site15_description',
                keywords: 'bulgaria_site15_keywords',
                gps: [43.8466659287287, 25.945150523568245]
            },
            {
                img: "../images/sites/bulgaria/thumbs/bulgaria_site16_p1.jpg",
                images: [
                    {
                        src: "../images/sites/bulgaria/bulgaria_site16_p1.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site16_p1.jpg",
                        reference: "bulgaria_site16_img1_reference",
                        description: "bulgaria_site16_img1_description"
                    }
                ],
                url: "/",
                country: 'bg',
                name: 'bulgaria_site16_name',
                epoch: 'period-romanPeriod-name',
                description: 'bulgaria_site16_description',
                keywords: 'bulgaria_site16_keywords',
                gps: [42.14707149802875, 24.75116105859637]
            },
            {
                img: "../images/sites/bulgaria/thumbs/bulgaria_site17_p1.jpg",
                images: [
                    {
                        src: "../images/sites/bulgaria/bulgaria_site17_p1.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site17_p1.jpg",
                        reference: "bulgaria_site17_img1_reference",
                        description: "bulgaria_site17_img1_description"
                    },
                    {
                        src: "../images/sites/bulgaria/bulgaria_site17_p2.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site17_p2.jpg",
                        reference: "bulgaria_site17_img2_reference",
                        description: "bulgaria_site17_img2_description"
                    }
                ],
                url: "/",
                country: 'bg',
                name: 'bulgaria_site17_name',
                epoch: 'period-romanPeriod-name',
                description: 'bulgaria_site17_description',
                keywords: 'bulgaria_site17_keywords',
                gps: [42.697731316185866, 23.32265792941672]
            },
            {
                img: "../images/sites/bulgaria/thumbs/bulgaria_site18_p1.jpg",
                images: [
                    {
                        src: "../images/sites/bulgaria/bulgaria_site18_p1.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site18_p1.jpg",
                        reference: "bulgaria_site18_img1_reference",
                        description: "bulgaria_site18_img1_description"
                    },
                    {
                        src: "../images/sites/bulgaria/bulgaria_site18_p2.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site18_p2.jpg",
                        reference: "bulgaria_site18_img2_reference",
                        description: "bulgaria_site18_img2_description"
                    }
                ],
                url: "/",
                country: 'bg',
                name: 'bulgaria_site18_name',
                epoch: 'period-romanPeriod-name',
                description: 'bulgaria_site18_description',
                keywords: 'bulgaria_site18_keywords',
                gps: [42.49876648180155, 24.7059009810677]
            },
            {
                img: "../images/sites/bulgaria/thumbs/bulgaria_site19_p1.jpg",
                images: [
                    {
                        src: "../images/sites/bulgaria/bulgaria_site19_p1.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site19_p1.jpg",
                        reference: "bulgaria_site19_img1_reference",
                        description: "bulgaria_site19_img1_description"
                    },
                    {
                        src: "../images/sites/bulgaria/bulgaria_site19_p2jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site19_p2.jpg",
                        reference: "bulgaria_site19_img2_reference",
                        description: "bulgaria_site19_img2_description"
                    }
                ],
                url: "/",
                country: 'bg',
                name: 'bulgaria_site19_name',
                epoch: 'period-lateAntiquity-name',
                description: 'bulgaria_site19_description',
                keywords: 'bulgaria_site19_keywords',
                gps: [41.71537628306118, 25.464237130819647]
            },
            {
                img: "../images/sites/bulgaria/thumbs/bulgaria_site20_p1.jpg",
                images: [
                    {
                        src: "../images/sites/bulgaria/bulgaria_site20_p1.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site20_p1.jpg",
                        reference: "bulgaria_site20_img1_reference",
                        description: "bulgaria_site20_img1_description"
                    },
                    {
                        src: "../images/sites/bulgaria/bulgaria_site20_p2.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site20_p2.jpg",
                        reference: "bulgaria_site20_img2_reference",
                        description: "bulgaria_site20_img2_description"
                    }
                ],
                url: "/",
                country: 'bg',
                name: 'bulgaria_site20_name',
                epoch: 'period-lateAntiquity-name',
                description: 'bulgaria_site20_description',
                keywords: 'bulgaria_site20_keywords',
                gps: [43.21827669073425, 25.612869328337165]
            },
            {
                img: "../images/sites/bulgaria/thumbs/bulgaria_site21_p1.jpg",
                images: [
                    {
                        src: "../images/sites/bulgaria/bulgaria_site21_p1.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site21_p1.jpg",
                        reference: "bulgaria_site21_img1_reference",
                        description: "bulgaria_site21_img1_description"
                    },
                    {
                        src: "../images/sites/bulgaria/bulgaria_site21_p2.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site21_p2.jpg",
                        reference: "bulgaria_site21_img2_reference",
                        description: "bulgaria_site21_img2_description"
                    }
                ],
                url: "/",
                country: 'bg',
                name: 'bulgaria_site21_name',
                epoch: 'period-lateAntiquity-name',
                description: 'bulgaria_site21_description',
                keywords: 'bulgaria_site21_keywords',
                gps: [42.658732927544975, 27.730671105453183]
            },
            {
                img: "../images/sites/bulgaria/thumbs/bulgaria_site22_p1.jpg",
                images: [
                    {
                        src: "../images/sites/bulgaria/bulgaria_site22_p1.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site22_p1.jpg",
                        reference: "bulgaria_site22_img1_reference",
                        description: "bulgaria_site22_img1_description"
                    }
                ],
                url: "/",
                country: 'bg',
                name: 'bulgaria_site22_name',
                epoch: 'period-middleAge-name',
                description: 'bulgaria_site22_description',
                keywords: 'bulgaria_site22_keywords',
                gps: [43.277388186237644, 27.118924158374856]
            },
            {
                img: "../images/sites/bulgaria/thumbs/bulgaria_site23_p1.jpg",
                images: [
                    {
                        src: "../images/sites/bulgaria/bulgaria_site23_p1.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site23_p1.jpg",
                        reference: "bulgaria_site23_img1_reference",
                        description: "bulgaria_site23_img1_description"
                    },
                    {
                        src: "../images/sites/bulgaria/bulgaria_site23_p2.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site23_p2.jpg",
                        reference: "bulgaria_site23_img2_reference",
                        description: "bulgaria_site23_img2_description"
                    }
                ],
                url: "/",
                country: 'bg',
                name: 'bulgaria_site23_name',
                epoch: 'period-middleAge-name',
                description: 'bulgaria_site23_description',
                keywords: 'bulgaria_site23_keywords',
                gps: [42.64465119349702, 23.266176438766788]
            },
            {
                img: "../images/sites/bulgaria/thumbs/bulgaria_site24_p1.jpg",
                images: [
                    {
                        src: "../images/sites/bulgaria/bulgaria_site24_p1.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site24_p1.jpg",
                        reference: "bulgaria_site24_img1_reference",
                        description: "bulgaria_site24_img1_description"
                    },
                    {
                        src: "../images/sites/bulgaria/bulgaria_site24_p2.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site24_p2.jpg",
                        reference: "bulgaria_site24_img2_reference",
                        description: "bulgaria_site24_img2_description"
                    }
                ],
                url: "/",
                country: 'bg',
                name: 'bulgaria_site24_name',
                epoch: 'period-middleAge-name',
                description: 'bulgaria_site24_description',
                keywords: 'bulgaria_site24_keywords',
                gps: [43.082311676408175, 25.64888180089016]
            },
            {
                img: "../images/sites/bulgaria/bulgaria_site25_p1.jpg",
                images: [
                    {
                        src: "../images/sites/bulgaria/bulgaria_site25_p1.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site25_p1.jpg",
                        reference: "bulgaria_site25_img1_reference",
                        description: "bulgaria_site25_img1_description"
                    },
                    {
                        src: "../images/sites/bulgaria/bulgaria_site25_p2.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site25_p2.jpg",
                        reference: "bulgaria_site25_img2_reference",
                        description: "bulgaria_site25_img2_description"
                    },
                    {
                        src: "../images/sites/bulgaria/bulgaria_site25_p3.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site25_p3.jpg",
                        reference: "bulgaria_site25_img3_reference",
                        description: "bulgaria_site25_img3_description"
                    }
                ],
                url: "/",
                country: 'bg',
                name: 'bulgaria_site25_name',
                epoch: 'period-middleAge-name',
                description: 'bulgaria_site25_description',
                keywords: 'bulgaria_site25_keywords',
                gps: [43.623052, 26.021683]
            },
            {
                img: "../images/sites/bulgaria/bulgaria_site26_p1.jpg",
                images: [
                    {
                        src: "../images/sites/bulgaria/bulgaria_site26_p1.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site26_p1.jpg",
                        reference: "bulgaria_site26_img1_reference",
                        description: "bulgaria_site26_img1_description"
                    },
                    {
                        src: "../images/sites/bulgaria/bulgaria_site26_p2.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site26_p2.jpg",
                        reference: "bulgaria_site26_img2_reference",
                        description: "bulgaria_site26_img2_description"
                    },
                    {
                        src: "../images/sites/bulgaria/bulgaria_site26_p3.jpg",
                        thumb: "../images/sites/bulgaria/thumbs/bulgaria_site26_p3.jpg",
                        reference: "bulgaria_site26_img3_reference",
                        description: "bulgaria_site26_img3_description"
                    }
                ],
                url: "/",
                country: 'bg',
                name: 'bulgaria_site26_name',
                epoch: 'period-middleAge-name',
                description: 'bulgaria_site26_description',
                keywords: 'bulgaria_site26_keywords',
                gps: [43.694872, 25.987060]
            },
        ]
    },
    
    {
        id: 8,
        name: 'countries-germany-name',
        shortcut: 'de',
        flag: flag_de,
        url: 'https://constructarcade.com/game/archery-training/',
        content: 'germany_description',
        image: "../images/countries/germany.jpg",
        models: [
            {
                img: "../images/sites/site-placeholder.png",
                images: [],
                url: "/",
                country: 'de',
                name: 'germany_site1_name',
                epoch: 'period-paleolithic-name',
                description: 'germany_site1_description',
                keywords: 'germany_site1_keywords',
                gps: [48.558611, 10.193889]
            },
            {
                img: "../images/sites/site-placeholder.png",
                images: [],
                url: "/",
                country: 'de',
                name: 'germany_site2_name',
                epoch: 'period-paleolithic-name',
                description: 'germany_site2_description',
                keywords: 'germany_site2_keywords',
                gps: [48.378889, 9.754444]
            },
            {
                img: "../images/sites/germany/thumbs/germany_site3_p1.jpg",
                images: [
                    {
                        src: "../images/sites/germany/germany_site3_p1.jpg",
                        thumb: "../images/sites/germany/thumbs/germany_site3_p1.jpg",
                        reference: "germany_site3_img1_reference",
                        description: "germany_site3_img1_description"
                    },
                    {
                        src: "../images/sites/germany/germany_site3_p2.jpg",
                        thumb: "../images/sites/germany/thumbs/germany_site3_p2.jpg",
                        reference: "germany_site3_img2_description",
                        description: "germany_site3_img2_name"
                    },
                    {
                        src: "../images/sites/germany/germany_site3_p3.jpg",
                        thumb: "../images/sites/germany/thumbs/germany_site3_p3.jpg",
                        reference: "germany_site3_img3_description",
                        description: "germany_site3_img3_name"
                    }
                ],
                url: "/",
                country: 'de',
                name: 'germany_site3_name',
                epoch: 'period-paleolithic-name',
                description: 'germany_site3_description',
                keywords: 'germany_site3_keywords',
                gps: [48.549167, 10.172500]
            },
            {
                img: "../images/sites/site-placeholder.png",
                images: [],
                url: "/",
                country: 'de',
                name: 'germany_site4_name',
                epoch: 'period-paleolithic-name',
                description: 'germany_site4_description',
                keywords: 'germany_site4_keywords',
                gps: [48.176785, 9.211934]
            },
            {
                img: "../images/sites/site-placeholder.png",
                images: [],
                url: "/",
                country: 'de',
                name: 'germany_site5_name',
                epoch: 'period-paleolithic-name',
                description: 'germany_site5_description',
                keywords: 'germany_site5_keywords',
                gps: [48.398056, 9.771389]
            },
            {
                img: "../images/sites/site-placeholder.png",
                images: [],
                url: "/",
                country: 'de',
                name: 'germany_site6_name',
                epoch: 'period-neolithic-name',
                description: 'germany_site6_description',
                keywords: 'germany_site6_keywords',
                gps: [48.358440, 9.869170]
            },
            {
                img: "../images/sites/germany/thumbs/germany_site7_p1.jpg",
                images: [
                    {
                        src: "../images/sites/germany/germany_site7_p1.jpg",
                        thumb: "../images/sites/germany/thumbs/germany_site7_p1.jpg",
                        reference: "germany_site7_img1_reference",
                        description: "germany_site7_img1_description"
                    }
                ],
                url: "/",
                country: 'de',
                name: 'germany_site7_name',
                epoch: 'period-neolithic-name',
                description: 'germany_site7_description',
                keywords: 'germany_site7_keywords',
                gps: [47.998906, 9.688797]
            },
            {
                img: "../images/sites/site-placeholder.png",
                images: [],
                url: "/",
                country: 'de',
                name: 'germany_site8_name',
                epoch: 'period-neolithic-name',
                description: 'germany_site8_description',
                keywords: 'germany_site8_keywords',
                gps: [48.410887, 9.922465]
            },
            {
                img: "../images/sites/site-placeholder.png",
                images: [],
                url: "/",
                country: 'de',
                name: 'germany_site9_name',
                epoch: 'period-copperAge-name',
                description: 'germany_site9_description',
                keywords: 'germany_site9_keywords',
                gps: [47.888768, 9.568501]
            },
            {
                img: "../images/sites/site-placeholder.png",
                images: [],
                url: "/",
                country: 'de',
                name: 'germany_site10_name',
                epoch: 'period-copperAge-name',
                description: 'germany_site10_description',
                keywords: 'germany_site10_keywords',
                gps: [47.695204, 9.005169]
            },
            {
                img: "../images/sites/germany/thumbs/germany_site11_p1.jpg",
                images: [
                    {
                        src: "../images/sites/germany/germany_site11_p1.jpg",
                        thumb: "../images/sites/germany/thumbs/germany_site11_p1.jpg",
                        reference: "germany_site11_img1_reference",
                        description: "germany_site11_img1_description"
                    }
                ],
                url: "/",
                country: 'de',
                name: 'germany_site11_name',
                epoch: 'period-bronzeAge-name',
                description: 'germany_site11_description',
                keywords: 'germany_site11_keywords',
                gps: [47.725943, 9.227520]
            },
            {
                img: "../images/sites/site-placeholder.png",
                images: [],
                url: "/",
                country: 'de',
                name: 'germany_site12_name',
                epoch: 'period-bronzeAge-name',
                description: 'germany_site12_description',
                keywords: 'germany_site12_keywords',
                gps: [48.053336, 9.641486]
            },
            {
                img: "../images/sites/site-placeholder.png",
                images: [],
                url: "/",
                country: 'de',
                name: 'germany_site13_name',
                epoch: 'period-bronzeAge-name',
                description: 'germany_site13_description',
                keywords: 'germany_site13_keywords',
                gps: [48.341944, 9.121944]
            },
            {
                img: "../images/sites/site-placeholder.png",
                images: [],
                url: "/",
                country: 'de',
                name: 'germany_site14_name',
                epoch: 'period-bronzeAge-name',
                description: 'germany_site14_description',
                keywords: 'germany_site14_keywords',
                gps: [48.058253, 9.633597]
            },
            {
                img: "/examples/de_magdalenenberg_1/cover.jpg",
                images: [],
                models_3d: [
                    {
                        model_3d: '/examples/de_magdalenenberg_1/index.html',
                        model_3d_thumb: '/examples/de_magdalenenberg_1/cover.jpg',
                    },
                ],
                url: "/",
                country: 'de',
                name: 'germany_site15_name',
                epoch: 'period-ironAge-name',
                description: 'germany_site15_description',
                keywords: 'germany_site15_keywords',
                gps: [48.044300, 8.443700]
            },
            {
                img: "../images/sites/site-placeholder.png",
                images: [],
                url: "/",
                country: 'de',
                name: 'germany_site16_name',
                epoch: 'period-ironAge-name',
                description: 'germany_site16_description',
                keywords: 'germany_site16_keywords',
                gps: [48.123043, 8.842321]
            },
            {
                img: "../images/sites/germany/thumbs/germany_site17_p1.jpg",
                images: [
                    {
                        src: "../images/sites/germany/germany_site17_p1.jpg",
                        thumb: "../images/sites/germany/thumbs/germany_site17_p1.jpg",
                        reference: "germany_site17_img1_reference",
                        description: "germany_site17_img1_description"
                    },
                    {
                        src: "../images/sites/germany/germany_site17_p2.jpg",
                        thumb: "../images/sites/germany/thumbs/germany_site17_p2.jpg",
                        reference: "germany_site17_img2_reference",
                        description: "germany_site17_img2_description"
                    },
                    {
                        src: "../images/sites/germany/germany_site17_p3.jpg",
                        thumb: "../images/sites/germany/thumbs/germany_site17_p3.jpg",
                        reference: "germany_site17_img3_reference",
                        description: "germany_site17_img3_description"
                    }
                ],
                url: "/",
                country: 'de',
                name: 'germany_site17_name',
                epoch: 'period-ironAge-name',
                description: 'germany_site17_description',
                keywords: 'germany_site17_keywords',
                gps: [48.095537, 9.412004]
            },
            {
                img: "../images/sites/germany/thumbs/germany_site18_p1.jpg",
                images: [
                    {
                        src: "../images/sites/germany/germany_site18_p1.jpg",
                        thumb: "../images/sites/germany/thumbs/germany_site18_p1.jpg",
                        reference: "germany_site18_img1_reference",
                        description: "germany_site18_img1_description"
                    }
                ],
                url: "/",
                country: 'de',
                name: 'germany_site18_name',
                epoch: 'period-ironAge-name',
                description: 'germany_site18_description',
                keywords: 'germany_site18_keywords',
                gps: [48.870432, 10.357019]
            },
            {
                img: "../images/sites/germany/thumbs/germany_site19_p1.jpg",
                images: [
                    {
                        src: "../images/sites/germany/germany_site19_p1.jpg",
                        thumb: "../images/sites/germany/thumbs/germany_site19_p1.jpg",
                        reference: "germany_site19_img1_reference",
                        description: "germany_site19_img1_description"
                    }
                ],
                url: "/",
                country: 'de',
                name: 'germany_site19_name',
                epoch: 'period-ironAge-name',
                description: 'germany_site19_description',
                keywords: 'germany_site19_keywords',
                gps: [48.517115, 9.764404]
            },
            {
                img: "../images/sites/site-placeholder.png",
                images: [],
                url: "/",
                country: 'de',
                name: 'germany_site20_name',
                epoch: 'period-ironAge-name',
                description: 'germany_site20_description',
                keywords: 'germany_site20_keywords',
                gps: [48.163281, 9.473291]
            },
            {
                img: "../images/sites/site-placeholder.png",
                images: [],
                url: "/",
                country: 'de',
                name: 'germany_site21_name',
                epoch: 'period-ironAge-name',
                description: 'germany_site21_description',
                keywords: 'germany_site21_keywords',
                gps: [48.531190, 9.434360]
            },
            {
                img: "../images/sites/site-placeholder.png",
                images: [],
                url: "/",
                country: 'de',
                name: 'germany_site22_name',
                epoch: 'period-romanPeriod-name',
                description: 'germany_site22_description',
                keywords: 'germany_site22_keywords',
                gps: [48.375993, 8.934862]
            },
            {
                img: "../images/sites/germany/thumbs/germany_site23_p1.jpg",
                images: [
                    {
                        src: "../images/sites/germany/germany_site23_p1.jpg",
                        thumb: "../images/sites/germany/thumbs/germany_site23_p1.jpg",
                        reference: "germany_site23_img1_reference",
                        description: "germany_site23_img1_description"
                    },
                    {
                        src: "../images/sites/germany/germany_site23_p2.jpg",
                        thumb: "../images/sites/germany/thumbs/germany_site23_p2.jpg",
                        reference: "germany_site23_img2_reference",
                        description: "germany_site23_img2_description"
                    },
                    {
                        src: "../images/sites/germany/germany_site23_p3.jpg",
                        thumb: "../images/sites/germany/thumbs/germany_site23_p3.jpg",
                        reference: "germany_site23_img3_reference",
                        description: "germany_site23_img3_description"
                    }
                ],
                url: "/",
                country: 'de',
                name: 'germany_site23_name',
                epoch: 'period-romanPeriod-name',
                description: 'germany_site23_description',
                keywords: 'germany_site23_keywords',
                gps: [48.169234, 8.626480]
            },
            {
                img: "../images/sites/site-placeholder.png",
                images: [],
                url: "/",
                country: 'de',
                name: 'germany_site24_name',
                epoch: 'period-romanPeriod-name',
                description: 'germany_site24_description',
                keywords: 'germany_site24_keywords',
                gps: [48.835599, 10.084704]
            },
            {
                img: "../images/sites/site-placeholder.png",
                images: [],
                url: "/",
                country: 'de',
                name: 'germany_site25_name',
                epoch: 'period-romanPeriod-name',
                description: 'germany_site25_description',
                keywords: 'germany_site25_keywords',
                gps: [47.919724, 8.479864]
            },
            {
                img: "../images/sites/site-placeholder.png",
                images: [],
                url: "/",
                country: 'de',
                name: 'germany_site26_name',
                epoch: 'period-lateAntiquity-name',
                description: 'germany_site26_description',
                keywords: 'germany_site26_keywords',
                gps: [48.959441, 10.129763]
            },
            {
                img: "../images/sites/site-placeholder.png",
                images: [],
                url: "/",
                country: 'de',
                name: 'germany_site27_name',
                epoch: 'period-lateAntiquity-name',
                description: 'germany_site27_description',
                keywords: 'germany_site27_keywords',
                gps: [48.071778, 8.636944]
            },
            {
                img: "../images/sites/site-placeholder.png",
                images: [],
                url: "/",
                country: 'de',
                name: 'germany_site28_name',
                epoch: 'period-lateAntiquity-name',
                description: 'germany_site28_description',
                keywords: 'germany_site28_keywords',
                gps: [48.071778, 8.636944]
            },
            {
                img: "../images/sites/site-placeholder.png",
                images: [],
                url: "/",
                country: 'de',
                name: 'germany_site29_name',
                epoch: 'period-lateAntiquity-name',
                description: 'germany_site29_description',
                keywords: 'germany_site29_keywords',
                gps: [48.871873, 10.238221]
            },
            {
                img: "../images/sites/site-placeholder.png",
                images: [],
                url: "/",
                country: 'de',
                name: 'germany_site30_name',
                epoch: 'period-middleAge-name',
                description: 'germany_site30_description',
                keywords: 'germany_site30_keywords',
                gps: [48.050473, 8.969122]
            },
            {
                img: "../images/sites/site-placeholder.png",
                images: [],
                url: "/",
                country: 'de',
                name: 'germany_site31_name',
                epoch: 'period-middleAge-name',
                description: 'germany_site31_description',
                keywords: 'germany_site31_keywords',
                gps: [48.033670, 9.103805]
            },
            {
                img: "../images/sites/site-placeholder.png",
                images: [],
                url: "/",
                country: 'de',
                name: 'germany_site32_name',
                epoch: 'period-middleAge-name',
                description: 'germany_site32_description',
                keywords: 'germany_site32_keywords',
                gps: [48.098206, 9.788540]
            },
            {
                img: "../images/sites/site-placeholder.png",
                images: [],
                url: "/",
                country: 'de',
                name: 'germany_site33_name',
                epoch: 'period-middleAge-name',
                description: 'germany_site33_description',
                keywords: 'germany_site33_keywords',
                gps: [48.398537, 9.992522]
            },
            {
                img: "../images/sites/site-placeholder.png",
                images: [],
                url: "/",
                country: 'de',
                name: 'germany_site34_name',
                epoch: 'period-middleAge-name',
                description: 'germany_site34_description',
                keywords: 'germany_site34_keywords',
                gps: [48.379281, 9.731918]
            },
        ]
    },

    {
        id: 9,
        name: 'countries-serbia-name',
        shortcut: 'rs',
        flag: flag_sr,
        url: 'https://constructarcade.com/game/archery-training/',
        content: 'serbia_description',
        image: "../images/countries/serbia.jpg",
        models: [
            {
                img: "../images/sites/serbia/thumbs/serbia_site1_p1.jpg",
                images: [
                    {
                        src: "../images/sites/serbia/serbia_site1_p1.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site1_p1.jpg",
                        reference: "serbia_site1_img1_reference",
                        description: "serbia_site1_img1_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site1_p2.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site1_p2.jpg",
                        reference: "serbia_site1_img2_reference",
                        description: "serbia_site1_img2_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site1_p3.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site1_p3.jpg",
                        reference: "serbia_site1_img3_reference",
                        description: "serbia_site1_img3_description"
                    }
                ],
                url: "/",
                country: 'rs',
                name: 'serbia_site1_name',
                epoch: 'period-paleolithic-name',
                description: 'serbia_site1_description',
                keywords: 'serbia_site1_keywords',
                gps: [43.33703, 22.08438]
            },
            {
                img: "../images/sites/serbia/thumbs/serbia_site2_p1.jpg",
                images: [
                    {
                        src: "../images/sites/serbia/serbia_site2_p1.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site2_p1.jpg",
                        reference: "serbia_site2_img1_reference",
                        description: "serbia_site2_img1_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site2_p2.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site2_p2.jpg",
                        reference: "serbia_site2_img2_reference",
                        description: "serbia_site2_img2_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site2_p3.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site2_p3.jpg",
                        reference: "serbia_site2_img3_reference",
                        description: "serbia_site2_img3_description"
                    }
                ],
                url: "/",
                country: 'rs',
                name: 'serbia_site2_name',
                epoch: 'period-paleolithic-name',
                description: 'serbia_site2_description',
                keywords: 'serbia_site2_keywords',
                gps: [44.30210, 20.58241]
            },
            {
                img: "../images/sites/serbia/thumbs/serbia_site3_p1.jpg",
                images: [
                    {
                        src: "../images/sites/serbia/serbia_site3_p1.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site3_p1.jpg",
                        reference: "serbia_site3_img1_reference",
                        description: "serbia_site3_img1_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site3_p2.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site3_p2.jpg",
                        reference: "serbia_site3_img2_reference",
                        description: "serbia_site3_img2_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site3_p3.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site3_p3.jpg",
                        reference: "serbia_site3_img3_reference",
                        description: "serbia_site3_img3_description"
                    }
                ],
                url: "/",
                country: 'rs',
                name: 'serbia_site3_name',
                epoch: 'period-paleolithic-name',
                description: 'serbia_site3_description',
                keywords: 'serbia_site3_keywords',
                gps: [44.19015, 20.07817]
            },
            {
                img: "../images/sites/serbia/thumbs/serbia_site4_p1.jpg",
                images: [
                    {
                        src: "../images/sites/serbia/serbia_site4_p1.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site4_p1.jpg",
                        reference: "serbia_site4_img1_reference",
                        description: "serbia_site4_img1_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site4_p2.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site4_p2.jpg",
                        reference: "serbia_site4_img2_reference",
                        description: "serbia_site4_img2_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site4_p3.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site4_p3.jpg",
                        reference: "serbia_site4_img3_reference",
                        description: "serbia_site4_img3_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site4_p4.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site4_p4.jpg",
                        reference: "serbia_site4_img4_reference",
                        description: "serbia_site4_img4_description"
                    }
                ],
                url: "/",
                country: 'rs',
                name: 'serbia_site4_name',
                epoch: 'period-neolithic-name',
                description: 'serbia_site4_description',
                keywords: 'serbia_site4_keywords',
                gps: [44.81681, 20.68815
                ]
            },
            {
                img: "../images/sites/serbia/thumbs/serbia_site5_p1.jpg",
                images: [
                    {
                        src: "../images/sites/serbia/serbia_site5_p1.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site5_p1.jpg",
                        reference: "serbia_site5_img1_reference",
                        description: "serbia_site5_img1_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site5_p2.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site5_p2.jpg",
                        reference: "serbia_site5_img2_reference",
                        description: "serbia_site5_img2_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site5_p3.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site5_p3.jpg",
                        reference: "serbia_site5_img3_reference",
                        description: "serbia_site5_img3_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site5_p4.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site5_p4.jpg",
                        reference: "serbia_site5_img4_reference",
                        description: "serbia_site5_img4_description"
                    }
                ],
                url: "/",
                country: 'rs',
                name: 'serbia_site5_name',
                epoch: 'period-neolithic-name',
                description: 'serbia_site5_description',
                keywords: 'serbia_site5_keywords',
                gps: [44.55405, 22.02799]
            },
            {
                img: "../images/sites/serbia/thumbs/serbia_site6_p1.jpg",
                images: [
                    {
                        src: "../images/sites/serbia/serbia_site6_p1.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site6_p1.jpg",
                        reference: "serbia_site6_img1_reference",
                        description: "serbia_site6_img1_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site6_p2.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site6_p2.jpg",
                        reference: "serbia_site6_img2_reference",
                        description: "serbia_site6_img2_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site6_p3.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site6_p3.jpg",
                        reference: "serbia_site6_img3_reference",
                        description: "serbia_site6_img3_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site6_p4.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site6_p4.jpg",
                        reference: "serbia_site6_img4_reference",
                        description: "serbia_site6_img4_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site6_p5.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site6_p5.jpg",
                        reference: "serbia_site6_img5_reference",
                        description: "serbia_site6_img5_description"
                    }
                ],
                url: "/",
                country: 'rs',
                name: 'serbia_site6_name',
                epoch: 'period-neolithic-name',
                description: 'serbia_site6_description',
                keywords: 'serbia_site6_keywords',
                gps: [44.76201, 20.62309]
            },
            {
                img: "../images/sites/serbia/thumbs/serbia_site7_p1.jpg",
                images: [
                    {
                        src: "../images/sites/serbia/serbia_site7_p1.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site7_p1.jpg",
                        reference: "serbia_site7_img1_reference",
                        description: "serbia_site7_img1_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site7_p2.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site7_p2.jpg",
                        reference: "serbia_site7_img2_reference",
                        description: "serbia_site7_img2_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site7_p3.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site7_p3.jpg",
                        reference: "serbia_site7_img3_reference",
                        description: "serbia_site7_img3_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site7_p4.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site7_p4.jpg",
                        reference: "serbia_site7_img4_reference",
                        description: "serbia_site7_img4_description"
                    }
                ],
                url: "/",
                country: 'rs',
                name: 'serbia_site7_name',
                epoch: 'period-neolithic-name',
                description: 'serbia_site7_description',
                keywords: 'serbia_site7_keywords',
                gps: [45.02220, 21.24884]
            },
            {
                img: "../images/sites/serbia/thumbs/serbia_site8_p1.jpg",
                images: [
                    {
                        src: "../images/sites/serbia/serbia_site8_p1.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site8_p1.jpg",
                        reference: "serbia_site8_img1_reference",
                        description: "serbia_site8_img1_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site8_p2.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site8_p2.jpg",
                        reference: "serbia_site8_img2_reference",
                        description: "serbia_site8_img2_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site8_p3.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site8_p3.jpg",
                        reference: "serbia_site8_img3_reference",
                        description: "serbia_site8_img3_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site8_p4.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site8_p4.jpg",
                        reference: "serbia_site8_img4_reference",
                        description: "serbia_site8_img4_description"
                    }
                ],
                url: "/",
                country: 'rs',
                name: 'serbia_site8_name',
                epoch: 'period-neolithic-name',
                description: 'serbia_site8_description',
                keywords: 'serbia_site8_keywords',
                gps: [43.78170, 21.43926]
            },
            {
                img: "../images/sites/serbia/thumbs/serbia_site9_p1.jpg",
                images: [
                    {
                        src: "../images/sites/serbia/serbia_site9_p1.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site9_p1.jpg",
                        reference: "serbia_site9_img1_reference",
                        description: "serbia_site9_img1_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site9_p2.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site9_p2.jpg",
                        reference: "serbia_site9_img2_reference",
                        description: "serbia_site9_img2_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site9_p3.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site9_p3.jpg",
                        reference: "serbia_site9_img3_reference",
                        description: "serbia_site9_img3_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site9_p4.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site9_p4.jpg",
                        reference: "serbia_site9_img4_reference",
                        description: "serbia_site9_img4_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site9_p5.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site9_p5.jpg",
                        reference: "serbia_site9_img5_reference",
                        description: "serbia_site9_img5_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site9_p6.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site9_p6.jpg",
                        reference: "serbia_site9_img6_reference",
                        description: "serbia_site9_img6_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site9_p7.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site9_p7.jpg",
                        reference: "serbia_site9_img7_reference",
                        description: "serbia_site9_img7_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site9_p8.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site9_p8.jpg",
                        reference: "serbia_site9_img8_reference",
                        description: "serbia_site9_img8_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site9_p9.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site9_p9.jpg",
                        reference: "serbia_site9_img9_reference",
                        description: "serbia_site9_img9_description"
                    }
                ],
                url: "/",
                country: 'rs',
                name: 'serbia_site9_name',
                epoch: 'period-neolithic-name',
                description: 'serbia_site9_description',
                keywords: 'serbia_site9_keywords',
                gps: [45.13454, 21.28036]
            },
            {
                img: "../images/sites/serbia/thumbs/serbia_site10_p1.jpg",
                images: [
                    {
                        src: "../images/sites/serbia/serbia_site10_p1.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site10_p1.jpg",
                        reference: "serbia_site10_img1_reference",
                        description: "serbia_site10_img1_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site10_p2.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site10_p2.jpg",
                        reference: "serbia_site10_img2_reference",
                        description: "serbia_site10_img2_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site10_p3.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site10_p3.jpg",
                        reference: "serbia_site10_img3_reference",
                        description: "serbia_site10_img3_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site10_p4.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site10_p4.jpg",
                        reference: "serbia_site10_img4_reference",
                        description: "serbia_site10_img4_description"
                    }
                ],
                url: "/",
                country: 'rs',
                name: 'serbia_site10_name',
                epoch: 'period-copperAge-name',
                description: 'serbia_site10_description',
                keywords: 'serbia_site10_keywords',
                gps: [44.33970, 22.08771]
            },
            {
                img: "../images/sites/serbia/thumbs/serbia_site11_p1.jpg",
                images: [
                    {
                        src: "../images/sites/serbia/serbia_site11_p1.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site11_p1.jpg",
                        reference: "serbia_site11_img1_reference",
                        description: "serbia_site11_img1_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site11_p2.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site11_p2.jpg",
                        reference: "serbia_site11_img2_reference",
                        description: "serbia_site11_img2_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site11_p3.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site11_p3.jpg",
                        reference: "serbia_site11_img3_reference",
                        description: "serbia_site11_img3_description"
                    }
                ],
                url: "/",
                country: 'rs',
                name: 'serbia_site11_name',
                epoch: 'period-copperAge-name',
                description: 'serbia_site11_description',
                keywords: 'serbia_site11_keywords',
                gps: [43.37933, 21.89912]
            },
            {
                img: "../images/sites/site-placeholder.png",
                images: [],
                url: "/",
                country: 'rs',
                name: 'serbia_site12_name',
                epoch: 'period-copperAge-name',
                description: 'serbia_site12_description',
                keywords: 'serbia_site12_keywords',
                gps: [45.98340, 20.24897]
            },
            {
                img: "../images/sites/serbia/thumbs/serbia_site13_p1.jpg",
                images: [
                    {
                        src: "../images/sites/serbia/serbia_site13_p1.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site13_p1.jpg",
                        reference: "serbia_site13_img1_reference",
                        description: "serbia_site13_img1_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site13_p2.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site13_p2.jpg",
                        reference: "serbia_site13_img2_reference",
                        description: "serbia_site13_img2_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site13_p3.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site13_p3.jpg",
                        reference: "serbia_site13_img3_reference",
                        description: "serbia_site13_img3_description"
                    }
                ],
                url: "/",
                country: 'rs',
                name: 'serbia_site13_name',
                epoch: 'period-bronzeAge-name',
                description: 'serbia_site13_description',
                keywords: 'serbia_site13_keywords',
                gps: [45.85491, 20.39229]
            },
            {
                img: "../images/sites/serbia/thumbs/serbia_site14_p1.jpg",
                images: [
                    {
                        src: "../images/sites/serbia/serbia_site14_p1.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site14_p1.jpg",
                        reference: "serbia_site14_img1_reference",
                        description: "serbia_site14_img1_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site14_p2.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site14_p2.jpg",
                        reference: "serbia_site14_img2_reference",
                        description: "serbia_site14_img2_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site14_p3.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site14_p3.jpg",
                        reference: "serbia_site14_img3_reference",
                        description: "serbia_site14_img3_description"
                    }
                ],
                url: "/",
                country: 'rs',
                name: 'serbia_site14_name',
                epoch: 'period-bronzeAge-name',
                description: 'serbia_site14_description',
                keywords: 'serbia_site14_keywords',
                gps: [45.28717, 20.22908]
            },
            {
                img: "../images/sites/serbia/thumbs/serbia_site15_p1.jpg",
                images: [
                    {
                        src: "../images/sites/serbia/serbia_site15_p1.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site15_p1.jpg",
                        reference: "serbia_site15_img1_reference",
                        description: "serbia_site15_img1_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site15_p2.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site15_p2.jpg",
                        reference: "serbia_site15_img2_reference",
                        description: "serbia_site15_img2_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site15_p3.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site15_p3.jpg",
                        reference: "serbia_site15_img3_reference",
                        description: "serbia_site15_img3_description"
                    }
                ],
                url: "/",
                country: 'rs',
                name: 'serbia_site15_name',
                epoch: 'period-bronzeAge-name',
                description: 'serbia_site15_description',
                keywords: 'serbia_site15_keywords',
                gps: [45.93265, 20.43106]
            },
            {
                img: "../images/sites/serbia/thumbs/serbia_site16_p1.jpg",
                images: [
                    {
                        url: "wt9ryfI5GaE",
                        thumb: "../images/sites/serbia/thumbs/serbia_site16_v1.webp",
                        reference: "serbia_site16_video1_reference",
                        description: "serbia_site16_video1_description",
                        provider: "youtube"
                    },
                    {
                        url: "epCJtJc17ds",
                        thumb: "../images/sites/serbia/thumbs/serbia_site16_v2.webp",
                        reference: "serbia_site16_video2_reference",
                        description: "serbia_site16_video2_description",
                        provider: "youtube"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site16_p1.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site16_p1.jpg",
                        reference: "serbia_site16_img1_reference",
                        description: "serbia_site16_img1_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site16_p2.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site16_p2.jpg",
                        reference: "serbia_site16_img2_reference",
                        description: "serbia_site16_img2_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site16_p3.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site16_p3.jpg",
                        reference: "serbia_site16_img3_reference",
                        description: "serbia_site16_img3_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site16_p4.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site16_p4.jpg",
                        reference: "serbia_site16_img4_reference",
                        description: "serbia_site16_img4_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site16_p5.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site16_p5.jpg",
                        reference: "serbia_site16_img5_reference",
                        description: "serbia_site16_img5_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site16_p6.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site16_p6.jpg",
                        reference: "serbia_site16_img6_reference",
                        description: "serbia_site16_img6_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site16_p7.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site16_p7.jpg",
                        reference: "serbia_site16_img7_reference",
                        description: "serbia_site16_img7_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site16_p8.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site16_p8.jpg",
                        reference: "serbia_site16_img8_reference",
                        description: "serbia_site16_img8_description"
                    }
                ],
                url: "/",
                country: 'rs',
                name: 'serbia_site16_name',
                epoch: 'period-bronzeAge-name',
                description: 'serbia_site16_description',
                keywords: 'serbia_site16_keywords',
                gps: [45.23194, 21.25340]
            },
            {
                img: "../images/sites/serbia/thumbs/serbia_site17_p1.jpg",
                images: [
                    {
                        src: "../images/sites/serbia/serbia_site17_p1.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site17_p1.jpg",
                        reference: "serbia_site17_img1_reference",
                        description: "serbia_site17_img1_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site17_p2.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site17_p2.jpg",
                        reference: "serbia_site17_img2_reference",
                        description: "serbia_site17_img2_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site17_p3.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site17_p3.jpg",
                        reference: "serbia_site17_img3_reference",
                        description: "serbia_site17_img3_description"
                    }
                ],
                url: "/",
                country: 'rs',
                name: 'serbia_site17_name',
                epoch: 'period-ironAge-name',
                description: 'serbia_site17_description',
                keywords: 'serbia_site17_keywords',
                gps: [44.95028, 21.26454]
            },
            {
                img: "../images/sites/serbia/thumbs/serbia_site18_p1.jpg",
                images: [
                    {
                        src: "../images/sites/serbia/serbia_site18_p1.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site18_p1.jpg",
                        reference: "serbia_site18_img1_reference",
                        description: "serbia_site18_img1_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site18_p2.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site18_p2.jpg",
                        reference: "serbia_site18_img2_reference",
                        description: "serbia_site18_img2_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site18_p3.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site18_p3.jpg",
                        reference: "serbia_site18_img3_reference",
                        description: "serbia_site18_img3_description"
                    }
                ],
                url: "/",
                country: 'rs',
                name: 'serbia_site18_name',
                epoch: 'period-ironAge-name',
                description: 'serbia_site18_description',
                keywords: 'serbia_site18_keywords',
                gps: [45.07456, 19.14882]
            },
            {
                img: "../images/sites/serbia/thumbs/serbia_site19_p1.jpg",
                images: [
                    {
                        src: "../images/sites/serbia/serbia_site19_p1.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site19_p1.jpg",
                        reference: "serbia_site19_img1_reference",
                        description: "serbia_site19_img1_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site19_p2.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site19_p2.jpg",
                        reference: "serbia_site19_img2_reference",
                        description: "serbia_site19_img2_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site19_p3.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site19_p3.jpg",
                        reference: "serbia_site19_img3_reference",
                        description: "serbia_site19_img3_description"
                    }
                ],
                url: "/",
                country: 'rs',
                name: 'serbia_site19_name',
                epoch: 'period-ironAge-name',
                description: 'serbia_site19_description',
                keywords: 'serbia_site19_keywords',
                gps: [42.44485, 21.85081]
            },
            {
                img: "../images/sites/serbia/thumbs/serbia_site20_p1.jpg",
                images: [
                    {
                        src: "../images/sites/serbia/serbia_site20_p1.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site20_p1.jpg",
                        reference: "serbia_site20_img1_reference",
                        description: "serbia_site20_img1_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site20_p2.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site20_p2.jpg",
                        reference: "serbia_site20_img2_reference",
                        description: "serbia_site20_img2_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site20_p3.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site20_p3.jpg",
                        reference: "serbia_site20_img3_reference",
                        description: "serbia_site20_img3_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site20_p4.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site20_p4.jpg",
                        reference: "serbia_site20_img4_reference",
                        description: "serbia_site20_img4_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site20_p5.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site20_p5.jpg",
                        reference: "serbia_site20_img5_reference",
                        description: "serbia_site20_img5_description"
                    }
                ],
                url: "/",
                country: 'rs',
                name: 'serbia_site20_name',
                epoch: 'period-romanPeriod-name',
                description: 'serbia_site20_description',
                keywords: 'serbia_site20_keywords',
                gps: [43.89922, 22.18652]
            },
            {
                img: "../images/sites/serbia/thumbs/serbia_site21_p1.jpg",
                images: [
                    {
                        src: "../images/sites/serbia/serbia_site21_p1.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site21_p1.jpg",
                        reference: "serbia_site21_img1_reference",
                        description: "serbia_site21_img1_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site21_p2.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site21_p2.jpg",
                        reference: "serbia_site21_img2_reference",
                        description: "serbia_site21_img2_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site21_p3.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site21_p3.jpg",
                        reference: "serbia_site21_img3_reference",
                        description: "serbia_site21_img3_description"
                    }
                ],
                url: "/",
                country: 'rs',
                name: 'serbia_site21_name',
                epoch: 'period-romanPeriod-name',
                description: 'serbia_site21_description',
                keywords: 'serbia_site21_keywords',
                gps: [44.26197, 22.29698]
            },
            {
                img: "../images/sites/serbia/thumbs/serbia_site22_p1.jpg",
                images: [
                    {
                        src: "../images/sites/serbia/serbia_site22_p1.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site22_p1.jpg",
                        reference: "serbia_site22_img1_reference",
                        description: "serbia_site22_img1_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site22_p2.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site22_p2.jpg",
                        reference: "serbia_site22_img2_reference",
                        description: "serbia_site22_img2_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site22_p3.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site22_p3.jpg",
                        reference: "serbia_site22_img3_reference",
                        description: "serbia_site22_img3_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site22_p4.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site22_p4.jpg",
                        reference: "serbia_site22_img4_reference",
                        description: "serbia_site22_img4_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site22_p5.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site22_p5.jpg",
                        reference: "serbia_site22_img5_reference",
                        description: "serbia_site22_img5_description"
                    }
                ],
                url: "/",
                country: 'rs',
                name: 'serbia_site22_name',
                epoch: 'period-romanPeriod-name',
                description: 'serbia_site22_description',
                keywords: 'serbia_site22_keywords',
                gps: [44.73530, 21.21854]
            },
            {
                img: "../images/sites/serbia/thumbs/serbia_site23_p1.jpg",
                images: [
                    {
                        src: "../images/sites/serbia/serbia_site23_p1.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site23_p1.jpg",
                        reference: "serbia_site23_img1_reference",
                        description: "serbia_site23_img1_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site23_p2.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site23_p2.jpg",
                        reference: "serbia_site23_img2_reference",
                        description: "serbia_site23_img2_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site23_p3.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site23_p3.jpg",
                        reference: "serbia_site23_img3_reference",
                        description: "serbia_site23_img3_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site23_p4.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site23_p4.jpg",
                        reference: "serbia_site23_img4_reference",
                        description: "serbia_site23_img4_description"
                    }
                ],
                url: "/",
                country: 'rs',
                name: 'serbia_site23_name',
                epoch: 'period-romanPeriod-name',
                description: 'serbia_site23_description',
                keywords: 'serbia_site23_keywords',
                gps: [44.96603, 19.61077]
            },
            {
                img: "../images/sites/serbia/thumbs/serbia_site24_p1.jpg",
                images: [
                    {
                        src: "../images/sites/serbia/serbia_site24_p1.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site24_p1.jpg",
                        reference: "serbia_site24_img1_reference",
                        description: "serbia_site24_img1_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site24_p2.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site24_p2.jpg",
                        reference: "serbia_site24_img2_reference",
                        description: "serbia_site24_img2_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site24_p3.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site24_p3.jpg",
                        reference: "serbia_site24_img3_reference",
                        description: "serbia_site24_img3_description"
                    }
                ],
                url: "/",
                country: 'rs',
                name: 'serbia_site24_name',
                epoch: 'period-romanPeriod-name',
                description: 'serbia_site24_description',
                keywords: 'serbia_site24_keywords',
                gps: [44.65237, 22.30479]
            },
            {
                img: "../images/sites/serbia/thumbs/serbia_site25_p1.jpg",
                images: [
                    {
                        src: "../images/sites/serbia/serbia_site25_p1.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site25_p1.jpg",
                        reference: "serbia_site25_img1_reference",
                        description: "serbia_site25_img1_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site25_p2.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site25_p2.jpg",
                        reference: "serbia_site25_img2_reference",
                        description: "serbia_site25_img2_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site25_p3.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site25_p3.jpg",
                        reference: "serbia_site25_img3_reference",
                        description: "serbia_site25_img3_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site25_p4.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site25_p4.jpg",
                        reference: "serbia_site25_img4_reference",
                        description: "serbia_site25_img4_description"
                    }
                ],
                url: "/",
                country: 'rs',
                name: 'serbia_site25_name',
                epoch: 'period-lateAntiquity-name',
                description: 'serbia_site25_description',
                keywords: 'serbia_site25_keywords',
                gps: [42.95346, 21.66914]
            },
            {
                img: "../images/sites/serbia/thumbs/serbia_site26_p1.jpg",
                images: [
                    {
                        src: "../images/sites/serbia/serbia_site26_p1.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site26_p1.jpg",
                        reference: "serbia_site26_img1_reference",
                        description: "serbia_site26_img1_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site26_p2.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site26_p2.jpg",
                        reference: "serbia_site26_img2_reference",
                        description: "serbia_site26_img2_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site26_p3.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site26_p3.jpg",
                        reference: "serbia_site26_img3_reference",
                        description: "serbia_site26_img3_description"
                    }
                ],
                url: "/",
                country: 'rs',
                name: 'serbia_site26_name',
                epoch: 'period-lateAntiquity-name',
                description: 'serbia_site26_description',
                keywords: 'serbia_site26_keywords',
                gps: [45.26083, 19.49696]
            },
            {
                img: "../images/sites/serbia/thumbs/serbia_site27_p1.jpg",
                images: [
                    {
                        src: "../images/sites/serbia/serbia_site27_p1.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site27_p1.jpg",
                        reference: "serbia_site27_img1_reference",
                        description: "serbia_site27_img1_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site27_p2.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site27_p2.jpg",
                        reference: "serbia_site27_img2_reference",
                        description: "serbia_site27_img2_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site27_p3.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site27_p3.jpg",
                        reference: "serbia_site27_img3_reference",
                        description: "serbia_site27_img3_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site27_p4.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site27_p4.jpg",
                        reference: "serbia_site27_img4_reference",
                        description: "serbia_site27_img4_description"
                    }
                ],
                url: "/",
                country: 'rs',
                name: 'serbia_site27_name',
                epoch: 'period-middleAge-name',
                description: 'serbia_site27_description',
                keywords: 'serbia_site27_keywords',
                gps: [45.39305, 19.22165]
            },
            {
                img: "../images/sites/serbia/thumbs/serbia_site28_p1.jpg",
                images: [
                    {
                        src: "../images/sites/serbia/serbia_site28_p1.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site28_p1.jpg",
                        reference: "serbia_site28_img1_reference",
                        description: "serbia_site28_img1_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site28_p2.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site28_p2.jpg",
                        reference: "serbia_site28_img2_reference",
                        description: "serbia_site28_img2_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site28_p3.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site28_p3.jpg",
                        reference: "serbia_site28_img3_reference",
                        description: "serbia_site28_img3_description"
                    }
                ],
                url: "/",
                country: 'rs',
                name: 'serbia_site28_name',
                epoch: 'period-middleAge-name',
                description: 'serbia_site28_description',
                keywords: 'serbia_site28_keywords',
                gps: [44.66142, 21.67851]
            },
            {
                img: "../images/sites/serbia/thumbs/serbia_site29_p1.jpg",
                images: [
                    {
                        src: "../images/sites/serbia/serbia_site29_p1.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site29_p1.jpg",
                        reference: "serbia_site29_img1_reference",
                        description: "serbia_site29_img1_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site29_p2.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site29_p2.jpg",
                        reference: "serbia_site29_img2_reference",
                        description: "serbia_site29_img2_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site29_p3.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site29_p3.jpg",
                        reference: "serbia_site29_img3_reference",
                        description: "serbia_site29_img3_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site29_p4.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site29_p4.jpg",
                        reference: "serbia_site29_img4_reference",
                        description: "serbia_site29_img4_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site29_p5.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site29_p5.jpg",
                        reference: "serbia_site29_img5_reference",
                        description: "serbia_site29_img5_description"
                    }
                ],
                url: "/",
                country: 'rs',
                name: 'serbia_site29_name',
                epoch: 'period-middleAge-name',
                description: 'serbia_site29_description',
                keywords: 'serbia_site29_keywords',
                gps: [44.66960, 20.92818]
            },
            {
                img: "../images/sites/serbia/thumbs/serbia_site30_p1.jpg",
                images: [
                    {
                        src: "../images/sites/serbia/serbia_site30_p1.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site30_p1.jpg",
                        reference: "serbia_site30_img1_reference",
                        description: "serbia_site30_img1_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site30_p2.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site30_p2.jpg",
                        reference: "serbia_site30_img2_reference",
                        description: "serbia_site30_img2_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site30_p3.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site30_p3.jpg",
                        reference: "serbia_site30_img3_reference",
                        description: "serbia_site30_img3_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site30_p4.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site30_p4.jpg",
                        reference: "serbia_site30_img4_reference",
                        description: "serbia_site30_img4_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site30_p5.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site30_p5.jpg",
                        reference: "serbia_site30_img5_reference",
                        description: "serbia_site30_img5_description"
                    }
                ],
                url: "/",
                country: 'rs',
                name: 'serbia_site30_name',
                epoch: 'period-middleAge-name',
                description: 'serbia_site30_description',
                keywords: 'serbia_site30_keywords',
                gps: [43.61370, 20.55216]
            },
            {
                img: "../images/sites/serbia/thumbs/serbia_site31_p1.jpg",
                images: [
                    {
                        src: "../images/sites/serbia/serbia_site31_p1.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site31_p1.jpg",
                        reference: "serbia_site31_img1_reference",
                        description: "serbia_site31_img1_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site31_p2.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site31_p2.jpg",
                        reference: "serbia_site31_img2_reference",
                        description: "serbia_site31_img2_description"
                    },
                    {
                        src: "../images/sites/serbia/serbia_site31_p3.jpg",
                        thumb: "../images/sites/serbia/thumbs/serbia_site31_p3.jpg",
                        reference: "serbia_site31_img3_reference",
                        description: "serbia_site31_img3_description"
                    }
                ],
                url: "/",
                country: 'rs',
                name: 'serbia_site31_name',
                epoch: 'period-middleAge-name',
                description: 'serbia_site31_description',
                keywords: 'serbia_site31_keywords',
                gps: [45.12278, 21.32547]
            },
        ]
    },
]

export const questions = [
    {
        text: 'quiz_question1_text',
        images: [
            {
                src: "../images/quiz/question_1.jpg",
                thumb: "../images/quiz/thumb/question_1.jpg",
                reference: "",
                description: ""
            }
        ],
        answers: [
            {id: 1, text: "quiz_question1_answer1"},
            {id: 2, text: "quiz_question1_answer2"},
            {id: 3, text: "quiz_question1_answer3", correct: true},
        ],
        resultMessage: "quiz_question1_fact"
    },

    {
        text: 'quiz_question2_text',
        images: [
            {
                src: "../images/quiz/question_2.jpg",
                thumb: "../images/quiz/thumb/question_2.jpg",
                reference: "",
                description: ""
            }
        ],
        answers: [
            {id: 1, text: "quiz_question2_answer1"},
            {id: 2, text: "quiz_question2_answer2", correct: true},
            {id: 3, text: "quiz_question2_answer3"},
        ],
        resultMessage: "quiz_question2_fact"
    },

    {
        text: 'quiz_question3_text',
        images: [
            {
                src: "../images/quiz/question_3.jpg",
                thumb: "../images/quiz/thumb/question_3.jpg",
                reference: "",
                description: ""
            }
        ],
        answers: [
            {id: 1, text: "quiz_question3_answer1"},
            {id: 2, text: "quiz_question3_answer2", correct: true},
            {id: 3, text: "quiz_question3_answer3"},
        ],
        resultMessage: "quiz_question3_fact"
    },

    {
        text: 'quiz_question4_text',
        images: [
            {
                src: "../images/quiz/question_4.jpg",
                thumb: "../images/quiz/thumb/question_4.jpg",
                reference: "",
                description: ""
            }
        ],
        answers: [
            {id: 1, text: "quiz_question4_answer1"},
            {id: 2, text: "quiz_question4_answer2"},
            {id: 3, text: "quiz_question4_answer3", correct: true},
        ],
        resultMessage: "quiz_question4_fact"
    },

    {
        text: 'quiz_question5_text',
        images: [
            {
                src: "../images/quiz/question_5.jpg",
                thumb: "../images/quiz/thumb/question_5.jpg",
                reference: "",
                description: ""
            }
        ],
        answers: [
            {id: 1, text: "quiz_question5_answer1", correct: true},
            {id: 2, text: "quiz_question5_answer2"},
            {id: 3, text: "quiz_question5_answer3"},
        ],
        resultMessage: "quiz_question5_fact"
    },

    {
        text: 'quiz_question6_text',
        images: [
            {
                src: "../images/quiz/question_6.jpg",
                thumb: "../images/quiz/thumb/question_6.jpg",
                reference: "",
                description: ""
            }
        ],
        answers: [
            {id: 1, text: "quiz_question6_answer1"},
            {id: 2, text: "quiz_question6_answer2"},
            {id: 3, text: "quiz_question6_answer3", correct: true},
        ],
        resultMessage: "quiz_question6_fact"
    },

    {
        text: 'quiz_question7_text',
        images: [
            {
                src: "../images/quiz/question_7.jpg",
                thumb: "../images/quiz/thumb/question_7.jpg",
                reference: "",
                description: ""
            }
        ],
        answers: [
            {id: 1, text: "quiz_question7_answer1"},
            {id: 2, text: "quiz_question7_answer2", correct: true},
            {id: 3, text: "quiz_question7_answer3"},
        ],
        resultMessage: "quiz_question7_fact"
    },

    {
        text: 'quiz_question8_text',
        images: [
            {
                src: "../images/quiz/question_8.jpg",
                thumb: "../images/quiz/thumb/question_8.jpg",
                reference: "",
                description: ""
            }
        ],
        answers: [
            {id: 1, text: "quiz_question8_answer1"},
            {id: 2, text: "quiz_question8_answer2"},
            {id: 3, text: "quiz_question8_answer3", correct: true},
        ],
        resultMessage: "quiz_question8_fact"
    },

    {
        text: 'quiz_question9_text',
        images: [
            {
                src: "../images/quiz/question_9.jpg",
                thumb: "../images/quiz/thumb/question_9.jpg",
                reference: "",
                description: ""
            }
        ],
        answers: [
            {id: 1, text: "quiz_question9_answer1"},
            {id: 2, text: "quiz_question9_answer2", correct: true},
            {id: 3, text: "quiz_question9_answer3"},
        ],
        resultMessage: "quiz_question9_fact"
    },

    {
        text: 'quiz_question10_text',
        images: [
            {
                src: "../images/quiz/question_10.jpg",
                thumb: "../images/quiz/thumb/question_10.jpg",
                reference: "",
                description: ""
            }
        ],
        answers: [
            {id: 1, text: "quiz_question10_answer1"},
            {id: 2, text: "quiz_question10_answer2"},
            {id: 3, text: "quiz_question10_answer3", correct: true},
        ],
        resultMessage: "quiz_question10_fact"
    },

    {
        text: 'quiz_question11_text',
        images: [
            {
                src: "../images/quiz/question_11.jpg",
                thumb: "../images/quiz/thumb/question_11.jpg",
                reference: "",
                description: ""
            }
        ],
        answers: [
            {id: 1, text: "quiz_question11_answer1", correct: true},
            {id: 2, text: "quiz_question11_answer2"},
            {id: 3, text: "quiz_question11_answer3"},
        ],
        resultMessage: "quiz_question11_fact"
    },

    {
        text: 'quiz_question12_text',
        images: [
            {
                src: "../images/quiz/question_12.jpg",
                thumb: "../images/quiz/thumb/question_12.jpg",
                reference: "",
                description: ""
            }
        ],
        answers: [
            {id: 1, text: "quiz_question12_answer1"},
            {id: 2, text: "quiz_question12_answer2", correct: true},
            {id: 3, text: "quiz_question12_answer3"},
        ],
        resultMessage: "quiz_question12_fact"
    },

    {
        text: 'quiz_question13_text',
        images: [
            {
                src: "../images/quiz/question_13.jpg",
                thumb: "../images/quiz/thumb/question_13.jpg",
                reference: "",
                description: ""
            }
        ],
        answers: [
            {id: 1, text: "quiz_question13_answer1"},
            {id: 2, text: "quiz_question13_answer2", correct: true},
            {id: 3, text: "quiz_question13_answer3"},
        ],
        resultMessage: "quiz_question13_fact"
    },

    {
        text: 'quiz_question14_text',
        images: [
            {
                src: "../images/quiz/question_14.jpg",
                thumb: "../images/quiz/thumb/question_14.jpg",
                reference: "",
                description: ""
            }
        ],
        answers: [
            {id: 1, text: "quiz_question14_answer1", correct: true},
            {id: 2, text: "quiz_question14_answer2"},
            {id: 3, text: "quiz_question14_answer3"},
        ],
        resultMessage: "quiz_question14_fact"
    },

    {
        text: 'quiz_question15_text',
        images: [
            {
                src: "../images/quiz/question_15.jpg",
                thumb: "../images/quiz/thumb/question_15.jpg",
                reference: "",
                description: ""
            }
        ],
        answers: [
            {id: 1, text: "quiz_question15_answer1"},
            {id: 2, text: "quiz_question15_answer2"},
            {id: 3, text: "quiz_question15_answer3", correct: true},
        ],
        resultMessage: "quiz_question15_fact"
    },

    {
        text: 'quiz_question16_text',
        images: [
            {
                src: "../images/quiz/question_16.jpg",
                thumb: "../images/quiz/thumb/question_16.jpg",
                reference: "",
                description: ""
            }
        ],
        answers: [
            {id: 1, text: "quiz_question16_answer1", correct: true},
            {id: 2, text: "quiz_question16_answer2"},
            {id: 3, text: "quiz_question16_answer3"},
        ],
        resultMessage: "quiz_question16_fact"
    },

    {
        text: 'quiz_question17_text',
        images: [
            {
                src: "../images/quiz/question_17.jpg",
                thumb: "../images/quiz/thumb/question_17.jpg",
                reference: "",
                description: ""
            }
        ],
        answers: [
            {id: 1, text: "quiz_question17_answer1"},
            {id: 2, text: "quiz_question17_answer2"},
            {id: 3, text: "quiz_question17_answer3", correct: true},
        ],
        resultMessage: "quiz_question17_fact"
    },

    {
        text: 'quiz_question18_text',
        images: [
            {
                src: "../images/quiz/question_18.jpg",
                thumb: "../images/quiz/thumb/question_18.jpg",
                reference: "",
                description: ""
            }
        ],
        answers: [
            {id: 1, text: "quiz_question18_answer1"},
            {id: 2, text: "quiz_question18_answer2", correct: true},
            {id: 3, text: "quiz_question18_answer3"},
        ],
        resultMessage: "quiz_question18_fact"
    },

    {
        text: 'quiz_question19_text',
        images: [
            {
                src: "../images/quiz/question_19.jpg",
                thumb: "../images/quiz/thumb/question_19.jpg",
                reference: "",
                description: ""
            }
        ],
        answers: [
            {id: 1, text: "quiz_question19_answer1", correct: true},
            {id: 2, text: "quiz_question19_answer2"},
            {id: 3, text: "quiz_question19_answer3"},
        ],
        resultMessage: "quiz_question19_fact"
    },

    {
        text: 'quiz_question20_text',
        images: [
            {
                src: "../images/quiz/question_20.jpg",
                thumb: "../images/quiz/thumb/question_20.jpg",
                reference: "",
                description: ""
            }
        ],
        answers: [
            {id: 1, text: "quiz_question20_answer1", correct: true},
            {id: 2, text: "quiz_question20_answer2"},
            {id: 3, text: "quiz_question20_answer3"},
        ],
        resultMessage: "quiz_question20_fact"
    },

    {
        text: 'quiz_question21_text',
        images: [
            {
                src: "../images/quiz/question_21.jpg",
                thumb: "../images/quiz/thumb/question_21.jpg",
                reference: "",
                description: ""
            }
        ],
        answers: [
            {id: 1, text: "quiz_question21_answer1", correct: true},
            {id: 2, text: "quiz_question21_answer2"},
            {id: 3, text: "quiz_question21_answer3"},
        ],
        resultMessage: "quiz_question21_fact"
    },

    {
        text: 'quiz_question22_text',
        images: [
            {
                src: "../images/quiz/question_22.jpg",
                thumb: "../images/quiz/thumb/question_22.jpg",
                reference: "",
                description: ""
            }
        ],
        answers: [
            {id: 1, text: "quiz_question22_answer1"},
            {id: 2, text: "quiz_question22_answer2", correct: true},
            {id: 3, text: "quiz_question22_answer3"},
        ],
        resultMessage: "quiz_question22_fact"
    },

    {
        text: 'quiz_question23_text',
        images: [
            {
                src: "../images/quiz/question_23.jpg",
                thumb: "../images/quiz/thumb/question_23.jpg",
                reference: "",
                description: ""
            }
        ],
        answers: [
            {id: 1, text: "quiz_question23_answer1", correct: true},
            {id: 2, text: "quiz_question23_answer2"},
            {id: 3, text: "quiz_question23_answer3"},
        ],
        resultMessage: "quiz_question23_fact"
    },

    {
        text: 'quiz_question24_text',
        images: [
            {
                src: "../images/quiz/question_24.jpg",
                thumb: "../images/quiz/thumb/question_24.jpg",
                reference: "",
                description: ""
            }
        ],
        answers: [
            {id: 1, text: "quiz_question24_answer1", correct: true},
            {id: 2, text: "quiz_question24_answer2"},
            {id: 3, text: "quiz_question24_answer3"},
        ],
        resultMessage: "quiz_question24_fact"
    },

    {
        text: 'quiz_question25_text',
        images: [
            {
                src: "../images/quiz/question_25.jpg",
                thumb: "../images/quiz/thumb/question_25.jpg",
                reference: "",
                description: ""
            }
        ],
        answers: [
            {id: 1, text: "quiz_question25_answer1", correct: true},
            {id: 2, text: "quiz_question25_answer2"},
            {id: 3, text: "quiz_question25_answer3"},
        ],
        resultMessage: "quiz_question25_fact"
    },

    {
        text: 'quiz_question26_text',
        images: [
            {
                src: "../images/quiz/question_26.jpg",
                thumb: "../images/quiz/thumb/question_26.jpg",
                reference: "",
                description: ""
            }
        ],
        answers: [
            {id: 1, text: "quiz_question26_answer1", correct: true},
            {id: 2, text: "quiz_question26_answer2"},
            {id: 3, text: "quiz_question26_answer3"},
        ],
        resultMessage: "quiz_question26_fact"
    },

    {
        text: 'quiz_question27_text',
        images: [
            {
                src: "../images/quiz/question_27.jpg",
                thumb: "../images/quiz/thumb/question_27.jpg",
                reference: "",
                description: ""
            }
        ],
        answers: [
            {id: 1, text: "quiz_question27_answer1", correct: true},
            {id: 2, text: "quiz_question27_answer2"},
            {id: 3, text: "quiz_question27_answer3"},
        ],
        resultMessage: "quiz_question27_fact"
    },

    {
        text: 'quiz_question28_text',
        images: [
            {
                src: "../images/quiz/question_28.jpg",
                thumb: "../images/quiz/thumb/question_28.jpg",
                reference: "",
                description: ""
            }
        ],
        answers: [
            {id: 1, text: "quiz_question28_answer1", correct: true},
            {id: 2, text: "quiz_question28_answer2"},
            {id: 3, text: "quiz_question28_answer3"},
        ],
        resultMessage: "quiz_question28_fact"
    },

    {
        text: 'quiz_question29_text',
        images: [
            {
                src: "../images/quiz/question_29.jpg",
                thumb: "../images/quiz/thumb/question_29.jpg",
                reference: "",
                description: ""
            }
        ],
        answers: [
            {id: 1, text: "quiz_question29_answer1"},
            {id: 2, text: "quiz_question29_answer2"},
            {id: 3, text: "quiz_question29_answer3", correct: true},
        ],
        resultMessage: "quiz_question29_fact"
    },

    {
        text: 'quiz_question30_text',
        images: [
            {
                src: "../images/quiz/question_30.jpg",
                thumb: "../images/quiz/thumb/question_30.jpg",
                reference: "",
                description: ""
            }
        ],
        answers: [
            {id: 1, text: "quiz_question30_answer1"},
            {id: 2, text: "quiz_question30_answer2", correct: true},
            {id: 3, text: "quiz_question30_answer3"},
        ],
        resultMessage: "quiz_question30_fact"
    },

    {
        text: 'quiz_question31_text',
        images: [
            {
                src: "../images/quiz/question_31.jpg",
                thumb: "../images/quiz/thumb/question_31.jpg",
                reference: "",
                description: ""
            }
        ],
        answers: [
            {id: 1, text: "quiz_question31_answer1"},
            {id: 2, text: "quiz_question31_answer2"},
            {id: 3, text: "quiz_question31_answer3", correct: true},
        ],
        resultMessage: "quiz_question31_fact"
    },

    {
        text: 'quiz_question32_text',
        images: [
            {
                src: "../images/quiz/question_32.jpg",
                thumb: "../images/quiz/thumb/question_32.jpg",
                reference: "",
                description: ""
            }
        ],
        answers: [
            {id: 1, text: "quiz_question32_answer1"},
            {id: 2, text: "quiz_question32_answer2", correct: true},
            {id: 3, text: "quiz_question32_answer3"},
        ],
        resultMessage: "quiz_question32_fact"
    },

    {
        text: 'quiz_question33_text',
        images: [
            {
                src: "../images/quiz/question_33.jpg",
                thumb: "../images/quiz/thumb/question_33.jpg",
                reference: "",
                description: ""
            }
        ],
        answers: [
            {id: 1, text: "quiz_question33_answer1"},
            {id: 2, text: "quiz_question33_answer2"},
            {id: 3, text: "quiz_question33_answer3", correct: true},
        ],
        resultMessage: "quiz_question33_fact"
    },

    /*{
        text: 'quiz_question34_text',
        images: [
            {
                src: "../images/quiz/question_34.jpg",
                thumb: "../images/quiz/thumb/question_34.jpg",
                reference: "",
                description: ""
            }
        ],
        answers: [
            {id: 1, text: "quiz_question34_answer1", correct: true},
            {id: 2, text: "quiz_question34_answer2"},
            {id: 3, text: "quiz_question34_answer3"},
        ],
        resultMessage: "quiz_question34_fact"
    },

    {
        text: 'quiz_question35_text',
        images: [
            {
                src: "../images/quiz/question_35.jpg",
                thumb: "../images/quiz/thumb/question_35.jpg",
                reference: "",
                description: ""
            }
        ],
        answers: [
            {id: 1, text: "quiz_question35_answer1"},
            {id: 2, text: "quiz_question35_answer2", correct: true},
            {id: 3, text: "quiz_question35_answer3"},
        ],
        resultMessage: "quiz_question35_fact"
    },

    {
        text: 'quiz_question36_text',
        images: [
            {
                src: "../images/quiz/question_36.jpg",
                thumb: "../images/quiz/thumb/question_36.jpg",
                reference: "",
                description: ""
            }
        ],
        answers: [
            {id: 1, text: "quiz_question36_answer1"},
            {id: 2, text: "quiz_question36_answer2"},
            {id: 3, text: "quiz_question36_answer3", correct: true},
        ],
        resultMessage: "quiz_question36_fact"
    },

    {
        text: 'quiz_question37_text',
        images: [
            {
                src: "../images/quiz/question_37.jpg",
                thumb: "../images/quiz/thumb/question_37.jpg",
                reference: "",
                description: ""
            }
        ],
        answers: [
            {id: 1, text: "quiz_question37_answer1"},
            {id: 2, text: "quiz_question37_answer2"},
            {id: 3, text: "quiz_question37_answer3", correct: true},
        ],
        resultMessage: "quiz_question37_fact"
    },

    {
        text: 'quiz_question38_text',
        images: [
            {
                src: "../images/quiz/question_38.jpg",
                thumb: "../images/quiz/thumb/question_38.jpg",
                reference: "",
                description: ""
            }
        ],
        answers: [
            {id: 1, text: "quiz_question38_answer1"},
            {id: 2, text: "quiz_question38_answer2", correct: true},
            {id: 3, text: "quiz_question38_answer3"},
        ],
        resultMessage: "quiz_question38_fact"
    },

    {
        text: 'quiz_question39_text',
        images: [
            {
                src: "../images/quiz/question_39.jpg",
                thumb: "../images/quiz/thumb/question_39.jpg",
                reference: "",
                description: ""
            }
        ],
        answers: [
            {id: 1, text: "quiz_question39_answer1"},
            {id: 2, text: "quiz_question39_answer2"},
            {id: 3, text: "quiz_question39_answer3", correct: true},
        ],
        resultMessage: "quiz_question39_fact"
    },

    {
        text: 'quiz_question40_text',
        images: [
            {
                src: "../images/quiz/question_40.jpg",
                thumb: "../images/quiz/thumb/question_40.jpg",
                reference: "",
                description: ""
            }
        ],
        answers: [
            {id: 1, text: "quiz_question40_answer1", correct: true},
            {id: 2, text: "quiz_question40_answer2"},
            {id: 3, text: "quiz_question40_answer3"},
        ],
        resultMessage: "quiz_question40_fact"
    },

    {
        text: 'quiz_question41_text',
        images: [
            {
                src: "../images/quiz/question_41.jpg",
                thumb: "../images/quiz/thumb/question_41.jpg",
                reference: "",
                description: ""
            }
        ],
        answers: [
            {id: 1, text: "quiz_question41_answer1"},
            {id: 2, text: "quiz_question41_answer2", correct: true},
            {id: 3, text: "quiz_question41_answer3"},
        ],
        resultMessage: "quiz_question41_fact"
    },

    {
        text: 'quiz_question42_text',
        images: [
            {
                src: "../images/quiz/question_42.jpg",
                thumb: "../images/quiz/thumb/question_42.jpg",
                reference: "",
                description: ""
            }
        ],
        answers: [
            {id: 1, text: "quiz_question42_answer1"},
            {id: 2, text: "quiz_question42_answer2", correct: true},
            {id: 3, text: "quiz_question42_answer3"},
        ],
        resultMessage: "quiz_question42_fact"
    },

    {
        text: 'quiz_question43_text',
        images: [
            {
                src: "../images/quiz/question_43.jpg",
                thumb: "../images/quiz/thumb/question_43.jpg",
                reference: "",
                description: ""
            }
        ],
        answers: [
            {id: 1, text: "quiz_question43_answer1"},
            {id: 2, text: "quiz_question43_answer2"},
            {id: 3, text: "quiz_question43_answer3", correct: true},
        ],
        resultMessage: "quiz_question43_fact"
    },*/

    /*{
		text: 'quiz_question44_text',
		images: [
		],
		answers: [
			{id: 1, text: "quiz_question44_answer1"},
			{id: 2, text: "quiz_question44_answer2"},
			{id: 3, text: "quiz_question44_answer3", correct: true},
		],
		resultMessage: "quiz_question44_fact"
	},

	{
		text: 'quiz_question45_text',
		images: [
		],
		answers: [
			{id: 1, text: "quiz_question45_answer1"},
			{id: 2, text: "quiz_question45_answer2"},
			{id: 3, text: "quiz_question45_answer3", correct: true},
		],
		resultMessage: "quiz_question45_fact"
	},

	{
		text: 'quiz_question46_text',
		images: [
		],
		answers: [
			{id: 1, text: "quiz_question46_answer1"},
			{id: 2, text: "quiz_question46_answer2"},
			{id: 3, text: "quiz_question46_answer3", correct: true},
		],
		resultMessage: "quiz_question46_fact"
	},

	{
		text: 'quiz_question47_text',
		images: [
		],
		answers: [
			{id: 1, text: "quiz_question47_answer1"},
			{id: 2, text: "quiz_question47_answer2"},
			{id: 3, text: "quiz_question47_answer3", correct: true},
		],
		resultMessage: "quiz_question47_fact"
	},

	{
		text: 'quiz_question48_text',
		images: [
		],
		answers: [
			{id: 1, text: "quiz_question48_answer1"},
			{id: 2, text: "quiz_question48_answer2"},
			{id: 3, text: "quiz_question48_answer3", correct: true},
		],
		resultMessage: "quiz_question48_fact"
	},

	{
		text: 'quiz_question49_text',
		images: [
		],
		answers: [
			{id: 1, text: "quiz_question49_answer1"},
			{id: 2, text: "quiz_question49_answer2"},
			{id: 3, text: "quiz_question49_answer3", correct: true},
		],
		resultMessage: "quiz_question49_fact"
	},

	{
		text: 'quiz_question50_text',
		images: [
		],
		answers: [
			{id: 1, text: "quiz_question50_answer1"},
			{id: 2, text: "quiz_question50_answer2"},
			{id: 3, text: "quiz_question50_answer3", correct: true},
		],
		resultMessage: "quiz_question50_fact"
	},

	{
		text: 'quiz_question51_text',
		images: [
		],
		answers: [
			{id: 1, text: "quiz_question51_answer1"},
			{id: 2, text: "quiz_question51_answer2"},
			{id: 3, text: "quiz_question51_answer3", correct: true},
		],
		resultMessage: "quiz_question51_fact"
	},

	{
		text: 'quiz_question52_text',
		images: [
		],
		answers: [
			{id: 1, text: "quiz_question52_answer1"},
			{id: 2, text: "quiz_question52_answer2"},
			{id: 3, text: "quiz_question52_answer3", correct: true},
		],
		resultMessage: "quiz_question52_fact"
	},

	{
		text: 'quiz_question53_text',
		images: [
		],
		answers: [
			{id: 1, text: "quiz_question53_answer1"},
			{id: 2, text: "quiz_question53_answer2"},
			{id: 3, text: "quiz_question53_answer3", correct: true},
		],
		resultMessage: "quiz_question53_fact"
	},

	{
		text: 'quiz_question54_text',
		images: [
		],
		answers: [
			{id: 1, text: "quiz_question54_answer1"},
			{id: 2, text: "quiz_question54_answer2"},
			{id: 3, text: "quiz_question54_answer3", correct: true},
		],
		resultMessage: "quiz_question54_fact"
	},

	{
		text: 'quiz_question55_text',
		images: [
		],
		answers: [
			{id: 1, text: "quiz_question55_answer1"},
			{id: 2, text: "quiz_question55_answer2"},
			{id: 3, text: "quiz_question55_answer3", correct: true},
		],
		resultMessage: "quiz_question55_fact"
	},

	{
		text: 'quiz_question56_text',
		images: [
		],
		answers: [
			{id: 1, text: "quiz_question56_answer1"},
			{id: 2, text: "quiz_question56_answer2"},
			{id: 3, text: "quiz_question56_answer3", correct: true},
		],
		resultMessage: "quiz_question56_fact"
	},

	{
		text: 'quiz_question57_text',
		images: [
		],
		answers: [
			{id: 1, text: "quiz_question57_answer1"},
			{id: 2, text: "quiz_question57_answer2"},
			{id: 3, text: "quiz_question57_answer3", correct: true},
		],
		resultMessage: "quiz_question57_fact"
	},

	{
		text: 'quiz_question58_text',
		images: [
		],
		answers: [
			{id: 1, text: "quiz_question58_answer1"},
			{id: 2, text: "quiz_question58_answer2"},
			{id: 3, text: "quiz_question58_answer3", correct: true},
		],
		resultMessage: "quiz_question58_fact"
	},

	{
		text: 'quiz_question59_text',
		images: [
		],
		answers: [
			{id: 1, text: "quiz_question59_answer1"},
			{id: 2, text: "quiz_question59_answer2"},
			{id: 3, text: "quiz_question59_answer3", correct: true},
		],
		resultMessage: "quiz_question59_fact"
	},

	{
		text: 'quiz_question60_text',
		images: [
		],
		answers: [
			{id: 1, text: "quiz_question60_answer1"},
			{id: 2, text: "quiz_question60_answer2"},
			{id: 3, text: "quiz_question60_answer3", correct: true},
		],
		resultMessage: "quiz_question60_fact"
	},

	{
		text: 'quiz_question61_text',
		images: [
		],
		answers: [
			{id: 1, text: "quiz_question61_answer1"},
			{id: 2, text: "quiz_question61_answer2"},
			{id: 3, text: "quiz_question61_answer3", correct: true},
		],
		resultMessage: "quiz_question61_fact"
	},*/


]

export const langs = {
    sk: {
        img: flag_sk,
        name: 'Slovenčina'
    },
    en: {
        img: flag_en,
        name: 'English'
    },
    hu: {
        img: flag_hu,
        name: 'Magyar'
    },
    hr: {
        img: flag_hr,
        name: 'Hrvatski'
    },
    cs: {
        img: flag_cs,
        name: 'Čeština'
    },
    de: {
        img: flag_de,
        name: 'Deutsche Sprache'
    },
    ro: {
        img: flag_ro,
        name: 'Română'
    },
    sl: {
        img: flag_sl,
        name: 'Slovenščina'
    },
    /*ua: {
        img: flag_sl,
        name: 'countries-serbia-lang'
    },*/
    bg: {
        img: flag_bg,
        name: 'български'
    },
    sr: {
        img: flag_sr,
        name: 'Српски'
    }
}
