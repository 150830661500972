import {MapContainer, TileLayer} from 'react-leaflet'
import useWindowDimensions from '../../hooks'
import MapFunc from './MapFunc';

const Map  = ({countries, selectModel, selectedIndex, setSelectedIndex, popupWidth, flyToBounds, endFlyToBounds, 
			markers, setMarkers, mapFlyPosition, setMapFlyPosition, mapOffset, minimizedZoom}) => {
	const { width } = useWindowDimensions();

	let zoom = 7;
	let center = [47, 19];

	if (width < 400) zoom = 4;
	else if (width < 900) zoom = 5;
	else if (width < 2000) zoom = 6;
		
	return (
		<MapContainer style={{height: "calc(100vh - 60px)", top: "60px"}} center={center} zoom={zoom} scrollWheelZoom={true}>
			<TileLayer
				attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
				// url="http://localhost:8080/map/{z}/{x}/{y}.png"
				url="http://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"
			/>
			<MapFunc countries={countries} selectModel={selectModel} selectedIndex={selectedIndex} 
				setSelectedIndex={setSelectedIndex} popupWidth={popupWidth} flyToBounds={flyToBounds} 
				endFlyToBounds={endFlyToBounds} markers={markers} setMarkers={setMarkers} mapFlyPosition={mapFlyPosition}
				setMapFlyPosition={setMapFlyPosition} mapOffset={mapOffset} minimized={minimizedZoom}/>
			<div className={'map-link-cover'}></div>
		</MapContainer>
	)
}

export default Map;