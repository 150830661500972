import styled from "styled-components";

export const CheckboxWrapper = styled.div`
  position: relative;
  width: 200px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    width: 100%;
  }

  label {
    display: block;
    padding-left: 35px;
    cursor: pointer;
  }

  input {
    height: 14px;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 14px;
  }

  input + label::before {
    color: #aa9963;
    border: 1px solid;
    border-radius: 3px;
    content: "";
    height: 15px;
    width: 15px;
    left: 0;
    position: absolute;
    top: 5px;
    background-color: white;
  }

  input + label::after {
    color: white;
    content: "";
    border: 2px solid;
    border-radius: 3px;
    border-left: 0;
    border-top: 0;
    height: 9px;
    left: 5px;
    opacity: 0;
    position: absolute;
    top: 7px;
    transform: rotate(35deg);
    width: 5px;
  }

  input:checked + label::after {
    opacity: 1;
  }

  input:checked + label::before {
    color: white;
    background-color: #aa9963;
  }
}`

export const RadioWrapper = styled.div`
  position: relative;
  width: 200px;
  padding-bottom: 10px;

  label {
    display: block;
    padding-left: 35px;
    cursor: pointer;
  }

  input {
    height: 14px;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 14px;
  }

  input + label::before {
    color: #aa9963;
    border: 1px solid;
    border-radius: 50%;
    content: "";
    height: 15px;
    width: 15px;
    left: 0;
    position: absolute;
    top: 5px;
    background-color: white;
  }

  input + label::after {
    border-radius: 50%;
    content: "";
    height: 11px;
    width: 11px;
    left: 2px;
    position: absolute;
    top: 7px;
    background-color: #aa9963;
    opacity: 0;
  }

  input:checked + label::after {
    opacity: 1;
  }

  input:checked + label::before {
    color: white;
  }

  
}`