import {
    ButtonWrapper
} from "../../Header/Header.styles";

const MainButton = ({label, size, textsize, top, left, handleClick, disabled = false,
                        show = true, tutorial = false, highlight = false}) => {
    if (show) {
        return (
            <ButtonWrapper className={highlight ? 'highlight-element' : ''} disabled={disabled} scale={size}
                           textsize={textsize} top={top} left={left} onClick={handleClick} tutorial={tutorial ? 1 : 0}>
                {label}
            </ButtonWrapper>
        )
    } else {
        return '';
    }
}

export default MainButton;