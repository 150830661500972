import styled from "styled-components";

export const CascadeButton = styled.button `
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  bottom: unset !important;
  display: flex;
  align-items: center;
  
  img {
    width: 20px;
    margin-right: 5px;
  }
  
  span {
    font-weight: bold;
    color: #807770;
    font-size: 11px;
    width: 50px;
    text-align: left;
    line-height: .9;
  }
`