import styled from 'styled-components';
import {Nav, Button} from 'react-bootstrap';
import btnBg from "../../images/buttons/button-blank.svg";
import btnOverBg from "../../images/buttons/button-blank_over.svg";
import navBg from "../../images/top/top-bg.svg";

export const NavBar = styled(Nav)`
  position: fixed;
  height: 70px;
  width: 100vw;
  left: 0;
  z-index: 2000;
  background-image: url(${navBg});
  background-size: cover;

  .btn-nav-container {
    position: fixed;
    height: 70px;
    width: 100vw;
    display: flex;
    justify-content: center;
    margin-top: 0;
    z-index: 2001;

    .btn {
      margin-right: 10px;
      transform: translateY(20px);
    }
  }
`

export const NavItems = styled.div`
  position: fixed;
  height: 70px;
  width: 100vw;
  left: 0;
  z-index: 2000;

  &.highlight-element {

    button {
      background-image: url(${btnOverBg}) !important;
    }
  }

  .btn-nav-container {
    position: fixed;
    height: 70px;
    width: 100vw;
    display: flex;
    justify-content: center;
    margin-top: 0;
    z-index: 2001;

    .btn {
      margin-right: 10px;
      transform: translateY(20px);
      cursor: ${props => props.tutorial ? 'default' : 'pointer'};

      @media (max-width: 1200px) {
        margin-right: 4px;
        transform: translateY(40px);
      }
    }
  }
`

export const ButtonWrapper = styled(Button)`
  background-image: url(${btnBg}) !important;
  width: 200px;
  height: 67px;
  line-height: 1.1;
  text-shadow: 0 0 5px #000000;
  background-size: cover !important;
  font-weight: 500;
  z-index: 2001;

  @media (max-width: 1200px) {
    width: 126px;
    height: 40px;
    font-size: 12px;
  }

  &:hover {
    background-image: url(${btnOverBg}) !important;
  }
`

export const BackgroundImage = styled.img`
  position: absolute;
  top: 0px;
  width: 100%;
  object-fit: cover !important;
  height: 60px;
  z-index: 2000;
`

export const LangBtn = styled(Button)`
  position: absolute;
  top: 18px;
  right: 14px;
  background-color: transparent;
  border: none;
  outline: none;
  z-index: 2001;
  cursor: ${props => props.tutorial ? 'default' : 'pointer'};
  padding: 0 !important;

  img {
    height: 28px;
    color: white;
    cursor: ${props => props.tutorial ? 'default' : 'pointer'};
  }

  @media (max-width: 900px) {
    top: 5px;

    img {
      height: 18px;
    }
  }
`

export const IntroBtn = styled(Button)`
  position: absolute;
  top: 14px;
  right: 100px;
  background-color: transparent;
  border: none;
  outline: none;
  z-index: 2001;
  cursor: pointer;
  display: flex;
  align-items: center;

  span {
    text-transform: uppercase;
    font-weight: 600;
    text-shadow: 0 0 5px rgba(0, 0, 0, .7);
  }

  img {
    margin-right: 3px;
    width: 22px;
    filter: drop-shadow(0 0 5px rgba(0, 0, 0, .7));
  }

  @media (max-width: 900px) {
    top: 2px;

    span {
      font-size: 14px;
    }

    img {
      width: 15px;
      margin-right: 5px;
    }
  }
`

export const Logo = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2001;
  cursor: pointer;

  @media (max-width: 768px) {
    top: 5px;
  }

  img {
    height: 42px;
    color: white;

    @media (max-width: 768px) {
      height: 30px;
    }
  }
`