import {ButtonsWrapper, LanguageMenuDiv} from "./LanguageMenu.styles";
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {langs} from '../../constants'
import MenuBackground from "../UI/MenuBackground";
import Checkbox from "../UI/Checkbox";
import MenuTitle from "../UI/MenuTitle";
import MainButton from "../UI/MainButton";

const LanguageMenu = ({show, updateLanguage, handleClose, curr_lang}) => {

	const {t, i18n} = useTranslation('common');
	const [lang, setLanguage] = useState(curr_lang);

	const changeLanguage = () => {
		i18n.changeLanguage(lang);
		updateLanguage(lang);
		handleClose();
	}

	if (show) {
		return (
			<LanguageMenuDiv>
				<MenuBackground type={1}/>
				<div className='select-container'>
					<MenuTitle title={t('main-change-language').toUpperCase()}/>
					<div className="options-container">
						<div className='options-container__item'>
							{Object.entries(langs).map(([key, value]) => {
								return <Checkbox key={'radio' + key} type='radio' id={key} label={t(value.name)}
												 name='country' handleChange={() => setLanguage(key)} checked={key === lang}/>
							})}
						</div>
					</div>
				</div>
				<ButtonsWrapper>
					<MainButton label={t('main-change').toUpperCase()} size={2} handleClick={changeLanguage}/>
					<a href='/#' onClick={handleClose}>« {t('main-back').toUpperCase()}</a>
				</ButtonsWrapper>
			</LanguageMenuDiv>
		)
	} else {
		return "";
	}
}

export default LanguageMenu; 